/**
 * Created by Hp on 21/09/2017.
 */

(function () {
    'use strict'
    var angular = require('angular');
    var PaymentFormCtrl = require('./controllers/PaymentFormCtrl');
    var PaymentListCtrl = require('./controllers/PaymentListCtrl');
    var generalPaymentService = require('./services/generalPaymentService');

    angular
        .module("medinet")

        .service('generalPaymentService', generalPaymentService)

        .controller('PaymentListCtrl', PaymentListCtrl)
        .controller('PaymentFormCtrl', PaymentFormCtrl);

})();