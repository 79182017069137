/**
 * Created by BETALOS on 21/09/2016. [edited by amine 17/10/2019]
 */

(function () {

    'use strict';

    const ROOM_DIALOG = require('../dialogs/room-dialog');

    class RoomCtrl {
        constructor(frontDeskService, $timeout, $mdDialog) {
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.frontDeskService = frontDeskService;

            this.type = this.type || "";
            this.ngModelController = this.ngModelController || null;
        }

        static get $inject() {
            return ["frontDeskService", "$timeout", "$mdDialog"];
        }

        $onInit() {
            this.subscriber = this.frontDeskService.roomSubject
                .subscribe(data => this.items = _.filter(data, {type: this.type}));

            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        $onDestroy() {
            if (this.subscriber) this.subscriber.unsubscribe();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.value = this.internalValue;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }

        add(ev) {
            this.dialog.show(_.assign({}, ROOM_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: {type: this.type}
                },
            })).then(data => {
                if (data.type === this.type) {
                    this.internalValue = data;
                    this.internalValueChanged();
                }
            });
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, $attrs) {
        const noFloat = $elem.is('[no-float]') ? "md-no-float" : "";
        const noBadge = $elem.is('[no-badge]') ? "ng-hide='true'" : "";
        const label = $attrs['type'] === "CR" ? "consultation_room" : "waiting_room";

        const isRequired = $elem.is("[required]") ? "required" : "";
        const isDisabled = $elem.is('[disabled]') ? "disabled" : "";
        const ngRequired = $elem.is("[ng-required]") ? "ng-required='vm.required'" : "";
        const ngDisabled = $elem.is("[ng-disabled]") ? 'ng-disabled="vm.disabled"' : "";

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]') && !$elem.is('.flex')) $elem.addClass("flex-nogrow");

        return `
            <md-input-container ${noFloat}>
                ${_.isEmpty(noFloat) ? `<label translate-once="${label}"></label>` : ''}
                <md-select placeholder="{{ '${label}' | translate }}" ng-model="vm.internalValue" ng-model-options="{ trackBy: '$value.id' }" 
                        ng-change="vm.internalValueChanged()" ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled}>
                    <md-option ng-repeat="item in vm.items track by item.id" ng-value="item">
                        <span ng-bind="::item.name" class="flex"></span>
                        <span class="mn-badge" ${noBadge}>
                            <span ng-bind="::(item.occupy + '/' + item.capacity)"></span>
                        </span>
                    </md-option>
                </md-select>
                <div class="mn-option-buttons flex-nogrow layout-row">
                    <md-button ${isDisabled} ng-if="!vm.disabled" class="md-icon-button" ${isDisabled} ng-click="vm.add($event)" aria-label="add" tabindex="-1">
                        <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                    </md-button>
                    <md-button ${isDisabled} ng-if="vm.value && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                        <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                    </md-button>
                </div>
            </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        bindings: {
            type: '@',
            disabled: "=?ngDisabled",
            required: "=?ngRequired"
        },
        controllerAs: "vm",
        controller: RoomCtrl,
        require: {ngModelController: "ngModel"}
    }

})();
