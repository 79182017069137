(function () {

    'use strict';

    const angular = require('angular');

    const dentalService = require("./services/dentalService");
    const dentalUtilsService = require("./services/dentalUtilsService");
    const PeriodontalService = require("./services/PeriodontalService");


    const mnPatientPanoramics = require('./components/patient-panoramics');

    const DentalContainerCtrl = require('./controllers/DentalContainerCtrl');
    const PeriodontalDetailCtrl = require('./controllers/PeriodontalDetailCtrl');
    const PeriodontalContainerCtrl = require('./controllers/PeriodontalContainerCtrl');

    const dentalChartComponent = require('./components/dental-chart');
    const periodontalChartComponent = require('./components/periodontal-chart');

    const teethProceduresComponent = require('./components/teeth-procedures');

    const furcationButton = require('./directives/furcation-button');


    angular
        .module("medinet")

        .service("dentalService", dentalService)
        .service("dentalUtilsService", dentalUtilsService)
        .service("PeriodontalService", PeriodontalService)

        .component("mnDentalChart", dentalChartComponent)

        .component("mnPatientPanoramics", mnPatientPanoramics)

        .component("mnTeethProcedures", teethProceduresComponent)
        .component("mnPeriodontalChart", periodontalChartComponent)

        .controller('DentalContainerCtrl', DentalContainerCtrl)
        .controller('PeriodontalDetailCtrl', PeriodontalDetailCtrl)
        .controller('PeriodontalContainerCtrl', PeriodontalContainerCtrl)

        .directive('furcationButton', furcationButton)


})();