(function () {

    'use strict';

    class ProcedureSearchCtrl {
        constructor(medicalCareService, configService, $q, $mdDialog, $translate) {
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.q = $q;
            this.dialog = $mdDialog;
            this.translate = $translate;

            this.cancel = () => $mdDialog.cancel();
            this.orderList = list => this.medicalCareService.orderList(list, "group");
            this.filterMenu = (key) => this.items = this.medicalCareService.filterMenu(key, this.procedureGroups);
        }

        static get $inject() {
            return ["medicalCareService", "configService", "$q", "$mdDialog", "$translate"];
        }

        $onInit() {
            const promises = [
                this.medicalCareService.getProcedureGroups(),
                this.medicalCareService.getProcedures(),
                this.configService.getByHttp('medical_care_config')
            ];

            this.q.all(promises)
                .then(data => {
                    this.config = data[2] || this.medicalCareService.DEFAULT_CONFIG;

                    this.procedureGroups = this.orderList(data[0], 'groups');
                    this.procedures = data[1];

                    this.procedureGroups = _.map(this.procedureGroups, group => {
                        return _.assign(group, {procedures: _.filter(this.procedures, ['group.id', group.id])});
                    });
                });
        }

        selectProcedures() {
            this.dialog.hide(
                _.chain(this.procedureGroups)
                    .map("procedures")
                    .flatten()
                    .filter(["active", true])
                    .value()
            );
        }

        chooseProcedure(item) {
            item.active = !item.active;
        }

        filteredItem(isSearching) {
            this.isSearching = isSearching;
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: ProcedureSearchCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("medical-care/views/medical-care-procedure-search-dialog.tpl.html"),
    };

})();