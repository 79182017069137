/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    const angular = require('angular');


    // moved es6
    const room = require("./components/room");
    const filter = require("./components/filter");
    const agenda = require("./components/agenda");
    const reason = require("./components/reason");
    const patientAlert = require("./components/patient-alert");
    const practiceMenu = require("./components/practice-menu");
    const changeResource = require("./components/change-resource");

    const AgendaCtrl = require("./controllers/AgendaCtrl");

    // still in es5
    const PracticeCtrl = require("./controllers/PracticeCtrl");
    const PlanningContainerCtrl = require("./controllers/PlanningContainerCtrl");
    const PlanningDetailsCtrl = require("./controllers/PlanningDetailsCtrl");

    const PracticeService = require("./services/PracticeService");
    const frontDeskService = require("./services/frontDeskService");
    const appointmentFormService = require("./services/appointmentFormService");
    const groupAppointmentService = require("./services/groupAppointmentService");

    angular
        .module("medinet")

        .component("mnRoom", room)
        .component("mnFilter", filter)
        .component("mnReason", reason)
        .component("mnAgenda", agenda)
        .component("mnPracticeMenu", practiceMenu)
        .component("mnChangeResource", changeResource)

        .component("mnPatientAlert", patientAlert)

        .controller('AgendaCtrl', AgendaCtrl)
        .controller('PracticeCtrl', PracticeCtrl)
        .controller('PlanningContainerCtrl', PlanningContainerCtrl)
        .controller('PlanningDetailsCtrl', PlanningDetailsCtrl)

        .service("practiceService", PracticeService)
        .service("frontDeskService", frontDeskService)
        .service("appointmentFormService", appointmentFormService)
        .service("groupAppointmentService", groupAppointmentService);

})();
