/**
 * Created by amine on 19/10/2020.
 */
(function () {

    'use strict';

    const getObjectId = require('shared/utils/get-object-id');
    const SCHEDULING_DIALOG = require('../dialogs/scheduling-dialog');
    const {BehaviorSubject} = require("rxjs");

    const DEFAULT_CONFIG = {
        show_nav_prices: true,
        use_procedures_as_sessions: true,
        protocols: {
            show_table_prices: false
        }
    }

    const DEFAULT_FIELD_CONFIG = {
        "description": null,
        "comment": null
    };

    class MedicalCareService {
        constructor(mnWebSocket, $q, $http, system, moment, patientService, $mdDialog) {
            this.ws = mnWebSocket;
            this.q = $q;
            this.http = $http;
            this.moment = moment;
            this.patientService = patientService;
            this.dialog = $mdDialog;

            this.baseUrl = '/api/mc/';
            this.dateFormat = system['date_format'].js;
            this.linkSubject = new BehaviorSubject(null);
            this.formSubject = new BehaviorSubject(null);
        }

        static get $inject() {
            return ["mnWebSocket", "$q", "$http", "system", "moment", "patientService", "$mdDialog"];
        }

        get DEFAULT_CONFIG() {
            return _.cloneDeep(DEFAULT_CONFIG);
        }

        get DEFAULT_FIELD_CONFIG() {
            return _.cloneDeep(DEFAULT_FIELD_CONFIG);
        }

        getProcedureGroups() {
            const deferred = this.q.defer();

            this.http.get(`${this.baseUrl}procedure-group/`)
                .then(response => deferred.resolve(response.data), deferred.reject)

            return deferred.promise;
        }

        saveProcedureGroup(model) {
            let deferred = this.q.defer();

            this.http.post(this.getUrl(model.id, "procedure-group"), model)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        deleteProcedureGroup(model) {
            const deferred = this.q.defer();

            this.http.delete(this.getUrl(model.id, "procedure-group"))
                .then(response => deferred.resolve(response.data), deferred.reject)

            return deferred.promise;
        }

        getProcedures() {
            const deferred = this.q.defer();

            this.http.get(`${this.baseUrl}procedure/`)
                .then(response => deferred.resolve(response.data), deferred.reject)

            return deferred.promise;
        }

        saveProcedure(model) {
            let deferred = this.q.defer();

            this.http.post(this.getUrl(model.id, "procedure"), model)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        deleteProcedure(model) {
            const deferred = this.q.defer();

            this.http.delete(this.getUrl(model.id, "procedure"))
                .then(response => deferred.resolve(response.data), deferred.reject)

            return deferred.promise;
        }

        getProtocols() {
            const deferred = this.q.defer();

            this.http.get(`${this.baseUrl}protocol/`)
                .then(response => deferred.resolve(response.data), deferred.reject)

            return deferred.promise;
        }

        deleteModel(model, type) {
            let deferred = this.q.defer();

            if (!type) deferred.reject(null);

            this.http.delete(this.getUrl(model.id, type))
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        saveMedicalCareModel(model, type) {
            return this.ws.call("medical_care.Global.save", {model, type});
        }

        partialSave(model, type) {
            const deferred = this.q.defer();

            if (!type) deferred.reject(null);

            this.http.put(this.getUrl(model.id, type), model)
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        orderList(list, order) {
            if (order.length > 0) {
                return _.chain(list)
                    .filter(item => item && item.id)
                    .map(item => {
                        return _.assign(item, {order: _.indexOf(order, item.id)})
                    })
                    .orderBy(["order", "id"], ["asc", "asc"])
                    .value();
            }

            return list;
        }

        getByPatient(patient, type, query) {
            const deferred = this.q.defer();

            if (!type) deferred.reject(null);

            const start = _.get(query, 'start');
            const end = _.get(query, 'end');

            const queryParams = `?patient=${patient}${!start ? "" : `&start=${start}`}${!end ? "" : `&end=${end}`}`
            const url = this.getUrl(null, `${type}-table`);

            this.http.get(`${url}${queryParams}`)
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        get(id, type) {
            const deferred = this.q.defer();

            if (!type) deferred.reject(null);

            this.http.get(this.getUrl(id, type))
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        saveSession(session) {
            let deferred = this.q.defer();

            this.http.post(this.getUrl(session.id, "session"), session)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        savePostponedSession(data) {
            let deferred = this.q.defer();

            this.http.post(this.getUrl(data.id, "postponed"), data)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        duplicateItem(item, justCures = false) {
            if (!item) return null;

            const cures = _.map(item.cures, cure => _.chain(cure)
                .omit(cure, ['id', 'owner'])
                .set('utid', getObjectId())
                .value()
            );

            if (justCures) {
                return cures
            } else {
                item.quotation = item.id;

                item = _.omit(item, ['id', 'title', 'type', 'owner']);
                item.creation_date = this.moment().format(this.dateFormat);
                item.cures = cures;

                return item;
            }
        }

        getCures(id) {
            let deferred = this.q.defer();

            this.http.get(this.getUrl(id, "plan", "cures"))
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        openSchedulingDialog(patient = null, item = null, readOnly = false, ev) {
            return this.q.all([
                this.patientService.getMinimalPatient(patient),
                item ? this.q.when(item) : this.ws.call("medical_care.Global.last_plan", {patient})
            ]).then(data => {
                this.dialog.show(_.assign({}, SCHEDULING_DIALOG, {
                    targetEvent: ev,
                    multiple: true,
                    locals: {
                        patient: data[0],
                        item: data[1]
                    }
                }));
            });
        }

        filterMenu(key, groups) {
            let items = []
            if (key.length === 0) {
                items = groups;
            } else {
                const text = _.chain(key).toLower().deburr().value();
                items = _.reduce(groups, (groups, group) => this._reduceProcedureGroups(groups, group, text), []);
            }

            return items
        }

        _reduceProcedureGroups(groups, group, text) {
            const label = _.chain(group.title).toLower().deburr().value();
            if (_.includes(label, text))
                groups.push(group);
            else {
                const procedures = _.reduce(group.procedures, (procedures, procedure) => this._reduceProcedures(procedures, procedure, text), []);

                if (procedures.length > 0) {
                    groups.push(_.chain(group).cloneDeep().assign({procedures}).value());
                }
            }

            return groups;
        }

        _reduceProcedures(procedures, procedure, text) {
            const label = _.chain(procedure.title).toLower().deburr().value();
            if (_.includes(label, text)) procedures.push(procedure);

            return procedures;
        }

        getUrl(modelId, view, prefix = "") {
            return `${this.baseUrl}${view}/${modelId ? `${modelId}/` : ''}${prefix ? `${prefix}/` : ''}`;
        }
    }

    module.exports = MedicalCareService;

})();
