(function () {
    'use strict';
    var mnOximetryConfigTable = {
        controller: mnOximetryConfigTableCtrl,
        controllerAs: "vm",
        bindings: {
            model: "=mnModel",
            handleAutoSave: "<mnAutoSave",
            attr: "@?"


        },
        template: require('oximetry/views/oximetry-config-table.tpl.html')
    };

    mnOximetryConfigTableCtrl.$inject = ["$scope", "$translate"];

    function mnOximetryConfigTableCtrl($scope, $translate) {
        var vm = this;
        const colorPickerParams = require('../../parameters/json/color-picker-config.json');

        vm.$onInit = init;
        vm.onPaginate = onPaginate;
        vm.addLine = addLine;
        vm.removeItem = removeItem;
        vm.handleChange = handleChange;
        vm.colors_keys = () => {
            return _.keys(vm.model);
        };
        function init() {
            vm.query = {
                limit: 2,
                page: 1,
                order: "-id"
            };
            vm.options = [1, 2, 4, 10];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };
            vm.picker_options = _.assign(colorPickerParams, {label: $translate['instant']('color')});
            if (vm.attr === 'chart_colors') {
                $scope.$watchGroup(["vm.model.spo","vm.model.hr","vm.model.recovery","vm.model.break","vm.model.watts"], handleChange);
            }
        }



        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            if (vm.model.length < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function handleChange() {
            vm.handleAutoSave();
        }

        function addLine(line) {
            if (vm.attr === 'exam_motifs') {
                line.motifs.push("");
            }
            else {
                line.bearing.push({
                    "watts": 10,
                    "duration": 1
                });
            }
        }


        function removeItem(line, item) {
            if (vm.attr === 'exam_motifs') {

                _.find(vm.model, {title: line.title}).motifs.pop(item);
            }
            else {
                _.remove(_.find(vm.model, line).bearing, item);
            }
            vm.handleAutoSave();
        }
    }

    module.exports = mnOximetryConfigTable;

})();