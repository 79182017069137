/**
 * Created by BETALOS on 27/02/2017.
 */
(function () {

    'use strict';

    class PatientAlertCtrl {
        constructor(notificationService, $mdMenu, $element) {
            this.menu = $mdMenu;
            this.service = notificationService;

            this.btnClass = $element.is('[icon-button]') ? 'md-icon-button' : '';
            this.currentPatient = this.currentPatient ? this.currentPatient : null;
        }

        static get $inject() {
            return ["notificationService", "$mdMenu", "$element"];
        }

        $onInit() {
            this.subject = this.service.getAlerts()
                .subscribe(data => this.success(data));
        }

        $onChanges() {
            this.success(
                this.service.getActiveAlerts()
            )
        }

        $onDestroy() {
            if (this.subject) this.subject.unsubscribe();
        }

        success(data) {
            this.alerts = _.chain(data)
                .filter({patient_id: this.currentPatient})
                .orderBy(this.getLevelOrder, 'desc')
                .value();
        }

        markAsSeen(alert) {
            this.service.markAlertAsSeen(alert.id)
                .then(() => this.removeAlert(alert))
        }

        removeAlert(alert) {
            const index = _.findIndex(this.alerts, {id: alert.id});
            this.alerts.splice(index, 1);

            if (this.alerts.length === 0) this.menu.hide();
        }

        getLevelOrder(alert) {
            switch (alert.level) {
                case 'MINIMUM':
                    return 0
                case 'MEDIUM':
                    return 1
                case 'HIGH':
                    return 2
            }
        }

    }

    module.exports = {
        controllerAs: "vm",
        bindings: {
            currentPatient: "<patient"
        },
        controller: PatientAlertCtrl,
        template: require('frontdesk/views/patient-alert.tpl.html')
    };

})();
