(function () {

    'use strict';


    class ChangeResourceCtrl {
        constructor(frontDeskService) {
            this.service = frontDeskService;
            this.items = ['physician_resource', 'agenda_resource'];
        }

        static get $inject() {
            return ['frontDeskService'];
        }

        $onInit() {
            this.currentResource = this.service.resourceSubject.getValue();
        }

        toggle(item) {
            localStorage.setItem(this.service.CALENDAR_RESOURCE_KEY, item);
            this.currentResource = item;
            this.service.resourceSubject.next(item);
        }

    }

    tpl.$inject = ['$element'];

    function tpl($element) {
        const classStr = $element.is('[dialog]') ? "md-icon-button" : "mn-header-icon";

        return [
            '<md-menu md-position-mode="target-right target" style="padding: 0 1px;">',
            '   <md-button aria-label="filter menu" ng-click="$mdMenu.open($event)" class="' + classStr + '">',
            '       <md-icon md-font-icon="mdi-account-network" md-font-set="mdi"></md-icon>',
            '       <md-tooltip md-direction="bottom">',
            '           <span translate-once="agenda_change_resource"></span>',
            '       </md-tooltip>',
            '   </md-button>',
            '   <md-menu-content width="3" class="practice-menu">',
            '       <md-menu-item ng-repeat="item in vm.items track by $index">',
            '           <md-button ng-click="vm.toggle(item)">',
            '               <md-icon md-font-icon="mdi-check" md-font-set="mdi" ng-show="vm.currentResource == item"></md-icon>',
            '               <span ng-bind="item | translate"></span>',
            '           </md-button>',
            '       </md-menu-item>',
            '   </md-menu-content>',
            '</md-menu>'
        ].join('');
    }

    module.exports = {
        template: tpl,
        controllerAs: "vm",
        controller: ChangeResourceCtrl,
    };

})()