/**
 * Created by amine on 27/10/2020.
 */
(function () {

    'use strict';


    class MedicalCareListCtrl {
        constructor(medicalCareService, patientService, configService, $q, $state, $transition$, $scope, $transitions) {
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.patientService = patientService;
            this.q = $q;
            this.state = $state;
            this.$transitions = $transitions;
            this.$parent = $scope.$parent.vm

            this.type = _.get($transition$.params('to'), "type");
            this.isPlan = this.type === 'plan';

            this.currentPatient = _.get($transition$.params('to'), "pId");
            this.config = {};
            this.items = [];

        }

        static get $inject() {
            return ["medicalCareService", "patientService", "configService", "$q", "$state", "$transition$", "$scope", "$transitions"];
        }

        $onInit() {
            this.medicalCareService.linkSubject.next(this.type === 'plan' ? 'plans_list' : 'quotations_list');
            this.medicalCareService.formSubject.next({});
            this.aclRule = (this.type === 'plan') ? {
                action: "edit-plan",
                resource: "medical-care",
                behavior: "disable"
            } : ""
            this.$parent.containerPromise = this.q.all([
                this.medicalCareService.getByPatient(this.currentPatient, this.type),
                this.configService.getByHttp('medical_care_config')
            ]).then(data => {
                this.config = data[1];

                this.items = data[0];

            });
        }

        $onDestroy() {
            this.$parent = null;
            delete this.$parent;
        }

        handleItem(item = null) {
            let opts = null;
            if (item) opts = {
                'id': item.id,
                'type': this.type,
            }
            else opts = {
                'id': null,
                'type': this.type,
            }
            this.state.go("app.visit.medical-care.form", opts);
        }

        useQuotation(item) {
            this.state.go("app.visit.medical-care.form", {
                type: 'plan',
                id: null,
                copy: item.id,
            });
        }
    }

    module.exports = MedicalCareListCtrl;

})();
