/**
 * Created by amine on 30/04/2018. //edited by amine
 */

(function () {
    'use strict';

    const {Subject, BehaviorSubject} = require("rxjs");
    const {getModel} = require("stand-alone/text-editor/utils/editor-functions");
    const MOVE_DIALOG = require('../dialogs/move-model-dialog/move-model-dialog');

    class ExamService {
        constructor($q, $http, $state, mnWebSocket, $mdDialog, editorManagerService, $translate) {
            this.q = $q;
            this.http = $http;
            this.state = $state;
            this.ws = mnWebSocket;
            this.dialog = $mdDialog;
            this.editorManagerService = editorManagerService;
            this.translate = $translate;

            this.selectedTemplate = new BehaviorSubject(false);
            this.formSubject = new BehaviorSubject(false);
            this.examModelSubject = new Subject();
            this.currentModelSubject = new Subject();
            this.sideNaveSubject = new Subject();
            this.contentSubject = new Subject();
        }

        static get $inject() {
            return ["$q", "$http", "$state", "mnWebSocket", "$mdDialog", "editorManagerService", "$translate"];
        }


        listState() {
            this.currentModelSubject.next({id: null, type: null});
            this.state.go("app.visit.exam.list-view", {}, {location: 'replace'});
        }

        viewState(exam, reload = false) {
            let promise = this.state.go('app.visit.exam.view', {examId: exam.id}, {inherit: true})
            if (reload) promise.then(() => this.state.reload());
        }

        duplicateState(exam, template) {
            this.#formState(exam, template, null, true, true);
        }

        newState(template, model) {
            this.#formState(null, template, model, false, true);
        }

        editState(exam, template, reload, supQuery = {}) {
            this.#formState(exam, template, null, false, reload, supQuery = {});
        }

        templateListState() {
            self.currentModelSubject.next({id: null, type: null});
            this.state.go('app.parameters.exam-setup', {}, {inherit: true});
        }

        globalListState() {
            this.state.go('app.reports.list', {}, {inherit: true, location: 'replace'});
        }

        formModelState(template, model, duplicate, reload) {
            let params = {
                template: null,
                modelId: null,
                copy: null
            }

            if (duplicate) params.copy = _.get(model, "id", null);
            else if (!_.isNil(model)) params.modelId = _.get(model, "id", null);
            else params.template = _.get(template, "id", template);

            let promise = this.state.go('app.parameters.exam-setup.form', params, {inherit: true});

            if (reload) promise.then(this.state.reload);
        }

        getExam(pk) {
            let deferred = this.q.defer();
            let url = `/api/exam-form/${pk}/`;

            this.http.get(url)
                .then(resp => {
                    deferred.resolve(resp.data);
                }, deferred.reject);

            return deferred.promise;
        }

        simpleSaving(exam, patientId) {
            let deferred = this.q.defer();
            const url = '/api/exam-form/';

            this.http.post(`${url}${_.has(exam, 'id') ? `${exam.id}/` : ''}?patient=${patientId}`, exam)
                .then(resp => {
                    deferred.resolve(resp.data);
                }, deferred.reject);

            return deferred.promise;
        }

        handleSaving(exam, patientId, visitId) {
            let deferred = this.q.defer();
            let url = '/api/exam-form/';

            let template = this.selectedTemplate.getValue();

            if (_.has(exam, 'id')) {
                this.http.put(`${url}${exam.id}/?patient=${patientId}`, exam).then(success, deferred.reject);
            } else {
                let procedures = template['procedure_id'] ? [template['procedure_id']] : [];

                this.ws.call('visit.VisitPayment.set_visit_procedures', {visit: visitId, procedures: procedures})
                    .then(data => {
                        if (_.size(data) === 1) _.assign(exam, {
                            procedure_uid: _.head(data)
                        });

                        this.http.post(`${url}?patient=${patientId}`, exam)
                            .then(resp => deferred.resolve(resp.data), deferred.reject);
                    });
            }

            return deferred.promise;
        }

        prePrint(save, editorName) {
            return () => {
                let deferred = this.q.defer();

                this.editorManagerService
                    .getTinymce(editorName)
                    .compileContent()
                    .then(() => {
                        save().then(() => {
                            deferred.resolve(false);
                        });
                    });

                return deferred.promise;
            }
        }

        getExamTemplates() {
            let deferred = this.q.defer();
            let url = "/api/exam-template-list/";

            this.http.get(url)
                .then(resp => deferred.resolve(resp.data), deferred.reject);


            return deferred.promise;
        }

        getExamTemplate(template) {
            let deferred = this.q.defer();
            let url = `/api/exam-template-list/${template.id ? template.id : template}/`;

            this.http.get(url)
                .then(resp => deferred.resolve(resp.data), deferred.reject);

            return deferred.promise;
        }

        saveExamTemplate(template) {
            let deferred = this.q.defer();
            let url = `/api/exam-template-list/${template.id ? `${template.id}/` : ''}`;

            this.http.post(url, template)
                .then(resp => deferred.resolve(resp.data), deferred.reject);


            return deferred.promise;
        }

        getExamModel(model) {
            let deferred = this.q.defer();
            let url = `/api/exam-editor-model/${model}/`;

            this.http.get(url)
                .then(resp => deferred.resolve(resp.data), deferred.reject);

            return deferred.promise;
        }

        getExamModels() {
            let deferred = this.q.defer();
            let url = "/api/exam-editor-model/"

            this.http.get(url)
                .then(resp => deferred.resolve(resp.data), deferred.reject);

            return deferred.promise;
        }

        getExamListTable(query) {
            return this.ws.call("exam.ExamList.list", query);
        }

        editExam(exam) {
            this.selectedTemplate.next(exam.template);
            this.editState(exam, exam.template);
        }

        duplicateExam(exam) {
            this.selectedTemplate.next(exam.template);
            this.duplicateState(exam, exam.template);
        }

        removeExam(exam, patient, ev) {
            let deferred = this.q.defer();
            let url = `/api/exam-form/${exam.id}/?patient=${patient}`;

            let data = {
                visit: exam.visit_id,
                procedures_uid: _.isNil(exam.procedure_uid) ? [] : [exam.procedure_uid]
            }

            let confirm = this.dialog.confirm()
                .title(this.translate.instant('exam_remove_confirm'))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.q.all([
                        this.http.delete(url),
                        this.ws.call('visit.VisitPayment.remove_visit_procedures', data)
                    ]).then(() => {
                        if (!_.eq(this.state.current['name'], "app.visit.exam.list-view")) this.listState();

                        deferred.resolve(true);
                    }, deferred.reject);
                }, deferred.reject);

            return deferred.promise;
        }

        editModelState(model) {
            this.state.go("app.visit.exam.model-form", {modelId: model.id, copy: null})
                .then(this.state.reload);
        }

        duplicateModelState(model) {
            this.state.go("app.visit.exam.model-form", {modelId: null, copy: model.id})
                .then(this.state.reload)
        }

        saveExamModel(model) {
            let deferred = this.q.defer();
            let url = `/api/exam-editor-model/${model.id ? model.id + '/' : ''}`;

            this.http.post(url, model)
                .then(resp => {
                    this.examModelSubject.next({"added": resp.data});
                    deferred.resolve(resp.data);
                }, deferred.reject);

            return deferred.promise;
        }

        addExamModel(exam, editorName, ev) {
            const deferred = this.q.defer();
            const confirm = this.dialog.mnPromptDialog()
                .title('exam_model_title')
                .event('exam.ExamModel.title_validation')
                .placeholder('title')
                .targetEvent(ev)

            this.dialog
                .show(confirm)
                .then(
                    title => {
                        const editor = this.editorManagerService.getTinymce(editorName);

                        let content = getModel(editor);

                        const model = {
                            content: content,
                            template: _.isNumber(exam.template) ? {id: exam.template} : _.pick(exam.template, ["id"]),
                            title: title
                        }

                        return this.saveExamModel(model).then(data => {
                            deferred.resolve(data);
                        }, deferred.reject);
                    }
                );

            return deferred.promise;
        }

        moveExamModel(model, ev) {
            this.dialog.show(_.assign({}, MOVE_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(model)
                }
            }))
        }

        deleteExamModel(model, ev) {
            let deferred = this.q.defer();
            let confirm = this.dialog.confirm()
                .title(this.translate.instant('exam_model_remove_confirm', _.pick(model, 'title')))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.http.delete(`/api/exam-editor-model/${model.id}/`)
                        .then(() => {
                            this.examModelSubject.next({"deleted": model.id});
                            deferred.resolve(true);
                        }, deferred.reject);
                });

            return deferred.promise;
        }

        getVisitExamList(visit) {
            return this.ws.call('exam.ExamList.full_list', {visit: _.isObject(visit) ? visit.id : visit})
        }

        goTo(exam, state) {
            let params = {
                nv: 1,
                pId: exam.patient_id,
                visitId: exam.visit_id
            }
            switch (state) {
                case "view":
                case "form":
                    params['examId'] = exam.id;
                    params['template'] = state === "form" ? exam.template.id : null;
                    state = 'app.visit.exam.' + state
                    break
                case "duplicate":
                    params['copy'] = exam.id;
                    params['template'] = exam.template.id;
                    state = 'app.visit.exam.form';
                    break;
                default:
                    state = 'app.visit.exam';
            }

            this.state.go(state, params, {inherit: true})
        }

        #formState(exam, template, model, duplicate, reload, supQuery = {}) {
            let params = {
                template: template.id,
                examId: null,
                model: null,
                copy: null
            };

            if (duplicate) params['copy'] = _.get(exam, "id", null);
            else if (!_.isNil(exam)) params['examId'] = _.get(exam, "id", null);
            else if (!_.isNil(model)) params['model'] = _.get(model, "id", null);
            let promise = this.state.go('app.visit.exam.form', _.assign(params, supQuery), {inherit: true});

            if (reload) promise.then(this.state.reload);
        }
    }

    module.exports = ExamService;

})();
