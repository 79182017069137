(function () {

    'use strict';

    class PeriodontalChartCtrl {
        constructor($scope, $element, $timeout, dentalService) {
            this.scope = $scope;
            this.element = $element;
            this.timeout = $timeout;

            this.model = {};
            this.config = {};
            this.ngModelController = this.ngModelController || null;

            this.perioConfig = dentalService.perioCharting.getValue();

            this.max = this.perioConfig.reverse ? 17 : 5;
            this.min = this.perioConfig.reverse ? -5 : -17;
            this.reverse = this.perioConfig.reverse ? -1 : 1;
        }

        static get $inject() {
            return ["$scope", "$element", "$timeout", "dentalService"];
        }

        $onInit() {
            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.model) {
                this.model = this.ngModelController.$viewValue;
            }
        }

        change() {
            this.scope.$applyAsync(() => {
                let model = _.cloneDeep(this.model);
                this.ngModelController.$setViewValue(model, 'change');
                this.ngModelController.$commitViewValue();
            });
        }

        calcTabIndex(toothIndex, index) {
            return parseInt(`${this.config.tabIndex + toothIndex}${index}`) + 1;
        }

        isMissing(tooth) {
            return _.get(this.model, `${tooth}.missing`, false);
        }

        hasImplant(tooth) {
            return _.get(this.model, `${tooth}.implant`, false);
        }

        isMobile(tooth, level) {
            return _.get(this.model, `${tooth}.mobility`, 0) + 1 > level;
        }

        hasFurcation(tooth, section, level) {
            return _.get(this.model, `${tooth}.furcations.${section}`) >= level;
        }

        isBleeding(tooth, section) {
            return _.get(this.model, `${tooth}.bleeding.${section}`, false);
        }

        hasPlaque(tooth, section) {
            return _.get(this.model, `${tooth}.plaque.${section}`, false);
        }

        gingivalPath(tooth) {
            let values = _.get(this.model, `${tooth}.gingival_margin`);
            return this.getPoints(tooth, values);
        }

        probingPath(tooth) {
            let values = _.get(this.model, `${tooth}.probing_depth`);
            let dependingValues = _.get(this.model, `${tooth}.gingival_margin`);

            return this.getPoints(tooth, values, dependingValues);
        }

        getPoints(tooth, values, dependingValues) {
            return this.getPointsTuple(tooth, values, dependingValues)
                .reduce((acc, point) => acc ? `${acc} L ${point}` : `M ${point}`, null)
                .value()
        }

        getPointsTuple(tooth, values, dependingValues) {
            return _.chain(values)
                .keys()
                .sortBy()
                .map(section => this.getPointTuple(tooth, section, values, dependingValues))
                .compact();
        }

        indicatorPoints(tooth) {
            let values = _.get(this.model, `${tooth}.probing_depth`);
            let dependingValues = _.get(this.model, `${tooth}.gingival_margin`);

            return _.concat(
                this.getPointsTuple(tooth, values, dependingValues).value(),
                this.getPointsTuple(tooth, dependingValues).reverse().value()
            ).join(' ');
        }


        // connections
        gingivalConnection(config) {
            return this.getConnectionPoints(config, "gingival_margin");
        }

        probingConnection(config) {
            return this.getConnectionPoints(config, "probing_depth", "gingival_margin");
        }

        indicatorConnectionPoints(config) {
            return _.concat(
                this.getConnectionPointsTuple(config, "gingival_margin").value(),
                this.getConnectionPointsTuple(config, "probing_depth", "gingival_margin").reverse().value()
            ).join(' ');
        }

        getConnectionPoints(config, key, dependingKey) {
            return this.getConnectionPointsTuple(config, key, dependingKey)
                .reduce((acc, point) => acc ? `${acc} L ${point}` : `M ${point}`, null)
                .value();
        }

        getConnectionPointsTuple(config, key, dependingKey) {
            return _.chain(config)
                .map((section, tooth) => {
                    if (_.get(this.model, `${tooth}.missing`)) return null;

                    let values = _.get(this.model, `${tooth}.${key}`);
                    let dependingValues = _.get(this.model, `${tooth}.${dependingKey}`, null);

                    return this.getPointTuple(tooth, section, values, dependingValues);
                })
                .compact()
                .thru(value => {
                    if (value.length < 2) return [];
                    else return value;
                });
        }

        // general util
        getPointTuple(tooth, key, values, dependingValues) {
            let value = parseInt(_.get(values, key, 0)) * (dependingValues ? 1 : this.reverse);
            let point = dependingValues ? (parseInt(_.get(dependingValues, key, 0)) * this.reverse) - value : value;

            let selector = `#${tooth} .section-${key}.point-${Math.min(5, Math.max(point, -17))}`;

            let node = $(selector, this.element);
            return node.length > 0 ? `${node.attr('cx') || 0},${node.attr('cy') || 0}` : null;
        }
    }

    module.exports = {
        bindings: {
            config: '<'
        },
        controllerAs: "vm",
        controller: PeriodontalChartCtrl,
        require: {ngModelController: "ngModel"},
        template: require('../views/periodontal-chart.tpl.html'), // or template
    };

})();
