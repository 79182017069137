(function () {
        'use strict';
        var mnOximetry = {
            controller: mnOximetryCtrl,
            controllerAs: "vm",
            bindings: {
                patient: "<mnPatient",
                readonly: "<mnReadOnly"
            },
            template: require('oximetry/views/oximetry.tpl.html')
        };

        mnOximetryCtrl.$inject = ["$interval", "oximetryService", "system", "$q", "$mdDialog","configService" ];

        function mnOximetryCtrl($interval, oximetryService, system, $q, $mdDialog,configService ) {
            var vm = this;

            var fullDateTimeFormat = system['full_datetime_format'].js;

            var interval = $interval(animation, 500);

            var oxiDevices = require("../json/oxi-services.json");
            vm.$onInit = init;
            vm.$onDestroy = onDestroy;

            vm.previous = previous;
            vm.next = next;
            vm.chooseExam = chooseExam;
            vm.newExam = newExam;
            vm.importExam = importExam;

            vm.preSave = preSave;
            vm.saveExam = saveExam;
            vm.deleteExam = deleteExam;

            vm.connectDevice = connectDevice;
            vm.disconnectDevice = disconnectDevice;


            function init() {
                vm.showClass = false;
                vm.bluetoothDevice = null;
                vm.defaultParams = [];
                vm.exams = [];
                vm.currentExam = null;
                vm.promise = $q.all([configService.get('oximetry_config') , oximetryService.getExams(vm.patient)]).then(success);

                function success(data) {
                    vm.defaultParams = data[0];
                    vm.exams = data[1];
                    vm.currentExam = _.last(vm.exams);
                }
            }

            function animation() {
                vm.showClass = !vm.showClass;
            }

            function onDestroy() {
                $interval.cancel(interval);
            }

            function previous() {

            }

            function next() {
            }

            function chooseExam() {
                vm.is_newExam = false;
            }

            function newExam(default_data) {
                vm.is_newExam = true;
                if (!_.isNil(default_data)) {
                    vm.currentExam = {is_imported: false};
                    vm.currentExam = _.get(default_data, 'exam_data');
                    vm.currentExam.exam_date = moment(_.get(default_data, 'exam_data.exam_date')).format(fullDateTimeFormat);
                    vm.currentExam.start_datetime = moment(_.get(default_data, 'exam_data.start_datetime')).format(fullDateTimeFormat);
                    vm.currentExam.stop_datetime = moment(_.get(default_data, 'exam_data.stop_datetime')).format(fullDateTimeFormat);
                    vm.currentExam.end_datetime = moment(_.get(default_data, 'exam_data.end_datetime')).format(fullDateTimeFormat);
                    vm.currentExam.measurements = _.chain(default_data).get('exam_data.measurements').map((item) => {
                        item.date = moment(_.get(item, 'date')).format(fullDateTimeFormat);
                        // item.fill='#fff';
                        return item;
                    }).value();
                    vm.currentExam.recovery = _.chain(default_data).get('exam_data.recovery').map((item) => {
                        item.date = moment(_.get(item, 'date')).format(fullDateTimeFormat);
                        // item.fill='#fff';
                        return item;
                    }).value();

                    vm.currentExam.breaks = _.chain(default_data).get('exam_data.breaks').map((item) => {
                        item.end_time = moment(_.get(item, 'end_time')).format(fullDateTimeFormat);
                        item.start_time = moment(_.get(item, 'start_time')).format(fullDateTimeFormat);
                        // item.fill='#fff';
                        return item;
                    }).value();


                    vm.currentExam.motif = _.chain(vm.defaultParams['exam_motifs']).find({title: vm.currentExam.type}).get('motifs').find((e) => {
                        return _.deburr(e) === _.deburr(vm.currentExam.motif)
                    }).value() || 'doesn\'t exist';
                    vm.currentExam.is_imported = true;
                }
                else {
                    vm.currentExam = {
                        exam_date: moment().format(fullDateTimeFormat),
                        type: "six-min",
                        measurements: [],
                        recovery: [],
                        breaks: [],
                        end_reasons: _.first(vm.defaultParams['end_reasons']),
                        before_exam: {
                            blood_pressure: 0,
                            hr: 0,
                            spo: 0
                        },
                        after_exam: {
                            blood_pressure: 0,
                            hr: 0,
                            spo: 0
                        }
                    }
                }
                vm.currentExam.patient = {id: vm.patient}
            }

            function importExam() {
                $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                    locals: {
                        modalIcon: "mdi-folder-download",
                        modalTitle: "imported_exams_list",
                        modalModel: "oximetry.OximetryMobileExam",
                        hideSelectable: true,
                        search: {is_deleted: {$ne: true}},
                    }
                })).then(selectExamCallBack);
            }

            function selectExamCallBack(data) {
                vm.newExam(data)
            }

            function preSave() {
                return oximetryService.saveExam(vm.currentExam).then(saveSuccess);
            }

            function saveExam() {
                oximetryService.saveExam(vm.currentExam).then(saveSuccess);

            }

            function saveSuccess(data) {
                vm.currentExam = data;
                if (!_.find(vm.exams, {id: vm.currentExam.id})) {
                    vm.exams.push(vm.currentExam);
                }

            }

            function deleteExam(ev) {


            }

            function scan() {
                console.log('tetettetetet')
                return navigator.bluetooth.requestDevice({
                    acceptAllDevices: true, optionalServices: [
                        "14839ac4-7d7e-415c-9a42-167340cf2339",
                        "46a970e0-0d5f-11e2-8b5e-0002a5d5c51b",
                        "00001805-0000-1000-8000-00805f9b34fb"
                    ]
                }).then(device => {
                    vm.bluetoothServer = device;
                    vm.bluetoothServer.addEventListener('gattserverdisconnected', afterDisconnect);
                    return vm.bluetoothServer;
                }).catch(err => console.log('bluetooth scan failed :', err));
            }

            function connect() {
                return vm.bluetoothServer.gatt.connect().then(device => {
                    vm.bluetoothDevice = device;
                    return vm.bluetoothDevice;
                }).catch(err => console.log('connection failed :', err));
            }

            function afterDisconnect() {
                vm.bluetoothServer.removeEventListener('gattserverdisconnected', console.log('bluetooth device is disconnected'))
            }

            function connectDevice() {
                if (!vm.bluetoothServer || !vm.bluetoothServer.gatt.connected) {
                    scan()
                        .then(server => {
                            return connect()
                        })
                        .then(device =>  handleAfterDeviceConnect());
                }
            }
            function handleAfterDeviceConnect() {
                console.log(_.get(vm.bluetoothServer,'name'))
                if (_.get(vm.bluetoothServer,'name')=="O2Ring 7599"){
                    vm.oxiServices = oxiDevices["O2Ring_7599"];
                }
                else{
                    vm.oxiServices = oxiDevices["nonin_3230"];
                    updateDeviceTime();
                }
            }

            function disconnectDevice() {
                if (!vm.bluetoothServer) {
                    return;
                }
                console.log('Disconnecting from Bluetooth Device....');
                if (vm.bluetoothServer.gatt.connected) {
                    vm.bluetoothServer.gatt.disconnect();
                } else {
                    console.log('Bluetooth Device is already disconnected');
                }
            }

            function updateDeviceTime() {
                vm.bluetoothDevice.getPrimaryService(vm.oxiServices['currentTimeService']['code'])
                    .then(service => service.getCharacteristic(vm.oxiServices['currentTimeService']['current_time']))
                    .then(c => {
                        return c.writeValue(new Uint8Array(getDateTimeArray()))
                    })
                    .catch(err => console.log('error when synchronising device time:', err));
            }

            function getDateTimeArray() {
                return _.concat([96, 78, 77, 73, 18, 68, 84, 77, 61], _.chain(moment().format("YYMMDDHHmmss")).split('').map((e) => {
                    return e.charCodeAt()
                }).value(), [13, 10]);

            }


        }

        module.exports = mnOximetry;

    }

)();

