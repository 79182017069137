/**
 * Created by BETALOS on 24/09/2016. [edited by amine 16/10/2019]
 */

(function () {

    'use strict';

    const {Subject} = require("rxjs");
    const REASON_DIALOG = require('../dialogs/reason-dialog');

    class ReasonCtrl {
        constructor(frontDeskService, $timeout, $mdDialog, $element) {
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.$element = $element;
            this.frontDeskService = frontDeskService;

            this.eventSubject = new Subject();
            this.ngModelController = this.ngModelController || null;
            this.internalValue = null;
            this.items = [];
            this.initItems = [];
        }

        static get $inject() {
            return ["frontDeskService", "$timeout", "$mdDialog"];
        }

        $onInit() {
            this.subscriber = this.frontDeskService.reasonSubject
                .subscribe(data => this.initItems = _.sortBy(data, 'description'));

            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        $onDestroy() {
            if (this.subscriber) this.subscriber.unsubscribe();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.value = this.internalValue;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        setItems(items) {
            const item = _.find(this.initItems, ['id', _.get(this.internalValue, "id", null)]);
            this.items = _.chain(items).pushOrUpdate(item).compact().uniqBy('id').sortBy("description").value();
        }

        mdSelectEvent(event) {
            this.eventSubject.next(event);
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }

        add(ev) {
            const dialog = _.assign({}, REASON_DIALOG, {
                locals: {},
                targetEvent: ev,
            });

            this.dialog.show(dialog).then(data => {
                this.initItems = _.chain(this.initItems).concat(data).sortBy("description").value();

                this.internalValue = data;
                this.internalValueChanged();
            });
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($element, $attrs) {
        const multiple = $element.is('[multiple]') ? 'multiple' : "";
        const noBadge = $element.is('[no-badge]') ? 'class="no-badge"' : "";
        const label = $attrs['label'] ? $attrs['label'] : "reason";

        const isRequired = $element.is("[required]") ? "required" : "";
        const isDisabled = $element.is('[disabled]') ? "disabled" : "";
        const ngRequired = $element.is("[ng-required]") ? "ng-required='vm.required'" : "";
        const ngDisabled = $element.is("[ng-disabled]") ? 'ng-disabled="vm.disabled"' : "";

        if ($element.is("[mn-disabled]") || $element.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $element
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$element.is('[flex]') && !$element.is('.flex')) $element.addClass("flex-nogrow");

        return `
            <md-input-container>
               <label translate-once="${label}"></label>
               <md-select md-on-open="vm.mdSelectEvent('open')" md-on-close="vm.mdSelectEvent('close')" ng-model="vm.internalValue" ng-model-options="{ trackBy: '$value.id' }" 
                        ng-change="vm.internalValueChanged()" ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled} ${noBadge} ${multiple}>
                   <mn-select-search-header subject="vm.eventSubject" search-by="description" items="vm.setItems($event)" init-items="vm.initItems">
                   </mn-select-search-header>
                   <md-option ng-repeat="item in vm.items track by item.id" ng-value="item">
                       <span ng-bind="::item.description" class="flex"></span>
                       ${$element.is('[no-badge]') ? "" : '<span class="mn-badge" ng-bind="::item.appointment_duration + \'min\'"></span>'}
                   </md-option>
               </md-select>
               <div class="mn-option-buttons flex-nogrow layout-row">
                   <md-button ${isDisabled} ng-if="!vm.disabled" class="md-icon-button" ng-click="vm.add($event)" aria-label="add" tabindex="-1">
                       <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                   </md-button>
                   <md-button ${isDisabled} ng-if="vm.value && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                       <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                   </md-button>
               </div>
            </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        bindings: {
            disabled: "=?ngDisabled",
            required: "=?ngRequired"
        },
        controllerAs: "vm",
        controller: ReasonCtrl,
        require: {ngModelController: "ngModel"}
    };

})();
