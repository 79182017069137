/**
 * Created by Amine on 27/07/2016.
 */
(function () {
    'use strict';

    const angular = require('angular');

    const mnExamLinks = require("./components/exam-links/exam-links");

    const ExamContainerCtrl = require("./controllers/exam-container/ExamContainerCtrl");
    const ExamListCtrl = require("./controllers/exam-list/ExamListCtrl");
    const ExamViewCtrl = require("./controllers/exam-view/ExamViewCtrl");
    const ExamFormCtrl = require("./controllers/exam-form/ExamFormCtrl");
    const ExamModelFormCtrl = require("./controllers/exam-model-form/ExamModelFormCtrl");
    const ReportsMainCtrl = require("./controllers/reports-main/ReportsMainCtrl");

    const examService = require("./services/examService");

    angular
        .module("medinet")

        .component("mnExamLinks", mnExamLinks)

        .controller('ExamContainerCtrl', ExamContainerCtrl)
        .controller('ExamListCtrl', ExamListCtrl)
        .controller('ExamViewCtrl', ExamViewCtrl)
        .controller('ExamFormCtrl', ExamFormCtrl)
        .controller('ExamModelFormCtrl', ExamModelFormCtrl)
        .controller('ReportsMainCtrl', ReportsMainCtrl)

        .service("examService", examService);

})();