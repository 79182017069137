(function () {

    'use strict';

    const COLOR_PICKER_PARAMS = require('parameters/json/color-picker-config.json');

    class ClinicLocationDialogCtrl {
        constructor($mdDialog, $translate, hospitalizationService) {
            this.dialog = $mdDialog;
            this.hospitalizationService = hospitalizationService;

            this.options = _.assign(
                {}, COLOR_PICKER_PARAMS, {label: $translate.instant('reason_dialog_color')}
            );
        }

        static get $inject() {
            return ["$mdDialog", "$translate", 'hospitalizationService'];
        }

        submit() {
            this.promise = this.hospitalizationService.handleLocation(this.type, this.item)
                .then(data => this.dialog.hide(data));
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ClinicLocationDialogCtrl,
        template: require("./clinic-location.dialog.tpl.html"),
    };

})();
