(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");
    const {statesHeaders} = require("../../utils/states-headers");

    class DueDatesReportCtrl {
        constructor(genericStatesService, system, $translate, $scope, $state, $stateParams, moment) {
            this.genericStatesService = genericStatesService;
            this.system = system;
            this.translate = $translate;
            this.scope = $scope;
            this.$state = $state;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;
            this.maxDate = null;
            this.isChart = false;

            this.gridType = "flat";

            this.currentState = genericStatesService.getCurrentState("due_dates_report");

            this.query = {
                start: $stateParams.start || this.moment().startOf('month').format(this.dateFormat),
                end: $stateParams.end || this.moment().endOf('month').format(this.dateFormat),
                date_field: $stateParams.date_field || 'due_date',
            };

            this.headers = statesHeaders($translate, ['encasement'], {dueDateCase: true}).encasement;
            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {},
                onlyData: false
            });
        }

        static get $inject() {
            return ["genericStatesService", "system", "$translate", "$scope", "$state", "$stateParams", "moment"];
        }

        $onInit() {
            this.getData();
        }


        getSlice() {
            return {
                "expands": {
                    "expandAll": true,
                    "rows": [
                        {"tuple": []}
                    ]
                },
                "drills": {
                    "drillAll": false
                },
                "rows": this.getRows(),
                "columns": this.getColumns(),
                "measures": this.getMeasures()
            };
        }


        getRows() {
            if (this.gridType === "flat") {
                return [
                    {"uniqueName": "label"},
                    {"uniqueName": "payment_mode"},
                    {"uniqueName": "payee_fullname"},
                    {"uniqueName": "physician_fullname"},
                    {"uniqueName": "total_amount"},
                    {"uniqueName": "due_date"},
                ];
            } else {
                return [
                    {"uniqueName": "physician_fullname"},
                    {"uniqueName": "payment_mode"},
                    {"uniqueName": "payee_fullname"},
                    {"uniqueName": "label"},
                    {"uniqueName": "due_date"}
                ];
            }

        }

        getColumns() {
            return [
                {
                    "uniqueName": "Measures"
                }
            ];
        }


        getMeasures() {
            if (this.gridType === "flat") {
                return []
            } else {
                return [
                    {
                        "uniqueName": "total_amount",
                        "individual": false,
                        "format": "currency",
                        "aggregation": "sum",
                    },
                    {
                        "uniqueName": "consumed_amount",
                        "individual": false,
                        "format": "currency",
                        "aggregation": "sum",
                    },
                    {
                        "uniqueName": "remaining_amount",
                        "individual": false,
                        "format": "currency",
                        "aggregation": "sum",
                    }
                ];
            }
        }

        getData(onlyData = false) {
            this.promise = this.genericStatesService.getActivities(_.assign({}, this.query, {
                cycle: "periodic",
                data_source: "encasement_activity"
            })).then(data => {
                data = this.genericStatesService.activitiesDataModification("encasement", data);
                if (onlyData) {
                    this.$dataSource.next({
                        onlyData: true,
                        gridType: this.gridType,
                        data
                    });
                } else {
                    this.$dataSource.next({
                        slice: this.getSlice(),
                        data,
                        gridType: this.gridType,
                        headers: this.headers
                    });
                }
            });
        }


        handleFileName() {
            const start = this.moment(this.query.start, this.dateFormat).format(this.genericStatesService.exportDateFormat);
            const end = this.moment(this.query.end, this.dateFormat).format(this.genericStatesService.exportDateFormat);

            return `${this.translate.instant('states_delivery_term_file_name', {start, end})}`;
        }

        handleTypeChange() {
            this.scope.$applyAsync(() => this.getData(false));
        }

        queryChanged(onlyData = true) {
            const start = this.moment(this.query.start, this.dateFormat);
            const end = this.moment(this.query.end, this.dateFormat);

            if (start.isAfter(end)) this.query.end = start;

            this.$state.transitionTo(
                this.$state.current.name,
                this.query,
                {notify: false}
            ).then(() => this.getData(onlyData));
        }

        onComplete(instance) {

        }

        customizeCells(cellBuilder, cellData) {
        }
    }

    module.exports = DueDatesReportCtrl;
})();