/**
 * Created by Hp on 21/09/2017.
 */
(function () {
    'use strict';
    module.exports = PaymentFormCtrl;

    PaymentFormCtrl.$inject = ["$transition$", "$mdDialog", "$translate", "generalPaymentService", "system", "dragulaService", "$state", "$scope", "$stateParams", "billingService", "$q", "configService"];

    function PaymentFormCtrl($transition$, $mdDialog, $translate, generalPaymentService, system, dragulaService, $state, $scope, $stateParams, billingService, $q, configService) {
        let vm = this;
        let currentPaymentId = $transition$.params('to')['paymentId'];
        let dateFormat = system['date_format'].js;
        let currentPaymentLines = $transition$.params('to')['paymentLines'];
        let currentBeneficiary = $transition$.params('to')['beneficiary'];
        let currentBeneficiaryType = $transition$.params('to')['beneficiaryType'];
        vm.addState = $transition$.params('to')["addState"];
        vm.listState = $transition$.params('to')["listState"];
        vm.mnModel = $transition$.params('to')["mnModel"];
        vm.viewName = $transition$.params('to')["viewName"];
        vm.paidDoc = $transition$.params('to')["paidDoc"];


        vm.$onInit = init;
        vm.selectInvoices = selectInvoices;
        vm.patientCallback = patientCallback;
        vm.submit = submit;
        vm.cancel = cancel;
        vm.removePatient = removePatient;
        vm.onPaginate = onPaginate;
        vm.removePayment = removePayment;
        vm.prePrint = prePrint;
        vm.removeLine = removeLine;
        vm.beneficiaryChange = beneficiaryChange;
        vm.payerChange = payerChange;
        vm.organizationCallback = organizationCallback;
        vm.getLineMax = getLineMax;

        vm.assignModel = assignModel;
        vm.headInvalidate = headInvalidate;
        vm.dissociateLines = dissociateLines;
        vm.splitAmountLines = splitAmountLines;
        vm.encasementAmountClick = encasementAmountClick;
        vm.inputChange = inputChange;
        vm.inputClick = inputClick;
        vm.getCurrentRemainingAmount = getCurrentRemainingAmount;
        vm.duplicate = duplicate;
        vm.getLineRemainingAmount = getLineRemainingAmount;
        vm.validateHeader = validateHeader;
        vm.selectDocs = selectDocs;

        $scope.$watch('vm.payment.is_valid', readOnlyPayment);
        let bag = "line-bag";

        function init() {
            vm.query = {
                limit: 5,
                page: 1,
                order: "-id"
            };
            vm.options = [1, 5, 10, 20, 100];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };
            vm.payment = {};
            vm.payment_details = [];
            vm.paymentLines = _.cloneDeep(currentPaymentLines);
            vm.paymentDetails = [];
            vm.removed_lines = [];
            vm.amount_label = _.isNil(currentPaymentId) ? "payment_received_amount" : "payment_outstanding_amount";
            vm.readOnly = false;
            vm.is_new = !_.isNil(currentPaymentId) ? false : true;
            vm.calculateConfig = {value: 100, type: 'percent'};
            let promises = [];

            if (!_.isNil(currentPaymentId)) {
                promises.push(generalPaymentService.getPayment(currentPaymentId, vm.viewName))
            } else {
                vm.payment = {
                    date: moment().format(dateFormat),
                    consumed_amount: 0.0,
                    received_amount: _.get(vm.paidDoc, '_received_amount', 0.0),
                    due_date: moment().format(dateFormat),
                    lines: [],
                    payment_mode: {ref: ''},
                    beneficiary_type: currentBeneficiaryType ? currentBeneficiaryType : 'P',
                    payer_type: currentBeneficiaryType ? currentBeneficiaryType : 'P'
                };

                if (currentBeneficiary && currentBeneficiaryType == 'P') patientCallback(currentBeneficiary);
                // else vm.payment.beneficiary = currentBeneficiary;
                getData();
            }
            vm.promise = $q.all(promises).then(success);
            initDrag();

            function success(data) {
                if (data[0]) {
                    vm.payment = data[0];
                }
                loadPayment();
                reloadData();

            }
        }

        function loadPayment() {
            vm.title = $translate.instant('payment_title', {
                number: vm.payment.number,
                ia: $translate.instant('encasement_initial_amount'),
                amount: _.isUndefined(vm.payment['received_amount']) ? "" : vm.payment['received_amount'].toFixed(system['currency_float'])
            });
        }

        function getData() {
            if (vm.paymentLines.length < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function initDrag() {
            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
            let drake = dragulaService.find($scope, bag).drake;
            drake.on("dragend", _.mnDelay(moveLine, 400));
        }

        function moveLine() {
        }

        function selectInvoices(ev) {
            let search = {
                is_deleted: {$ne: true},
                is_valid: {$ne: false},
            };
            search = vm.mnModel === 'PurchasePayment' ? _.assign(search, {provider: vm.payment.beneficiary.id}) : _.assign(search, {
                beneficiary_type: vm.payment.beneficiary_type === 'P' ? 'PATIENT' : 'ORGANIZATION',
                properties: {
                    is_paid: false,
                    beneficiary_name: vm.payment.beneficiary['full_name'] || vm.payment.beneficiary.name
                }
            });
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-receipt",
                    modalTitle: "invoice_list",
                    modalModel: vm.paidDoc.modalModel,
                    search: search,
                }
            })).then(function (data) {
                _.forEach(data, function (invoice) {
                    refreshLines(invoice);
                });
            });
        }

        function refreshLines(docToPay, paidDoc = null) {
            if (!docToPay['is_paid']) {
                if (_.isNil(_.find(vm.paymentLines, function (item) {
                    if (item.paid_doc.number === docToPay.number && item.paid_doc.id === docToPay.id &&
                        item.paid_doc._module === _.get(paidDoc, '_module', vm.paidDoc._module) &&
                        item.paid_doc._model === _.get(paidDoc, '_model', vm.paidDoc._model)) return item;
                }))) {
                    vm.paymentLines.push({
                        total_amount: _.get(paidDoc, '_model', vm.paidDoc._model) === 'ContractInstance' ? docToPay.amount_to_pay : docToPay.taxed_amount,
                        paid_doc: _.assign(docToPay, {
                            _module: _.get(paidDoc, '_module', vm.paidDoc._module),
                            _model: _.get(paidDoc, '_model', vm.paidDoc._model)
                        }),
                        encasement_amount: 0.0,
                        remaining_amount: docToPay.remaining_amount,
                        details_date: _.get(paidDoc, '_model', vm.paidDoc._model) === 'ContractInstance' ? docToPay.start_at : null
                    });
                }
            }
        }

        function readOnlyPayment() {
            vm.readOnly = vm.payment ? vm.payment.is_valid : false;
        }

        function getLineMax(line, idx) {
            let total = _.sumBy(vm.paymentLines, function (o) {
                if (_.indexOf(vm.paymentLines, o) != idx) return o['encasement_amount'];
            });

            return !_.isNil(total) ? vm.payment.received_amount - vm.payment.consumed_amount - total : line.remaining_amount;

        }

        function patientCallback(data) {
            if (_.isNil(data)) data = {};
            vm.payment.beneficiary = _.assign(data, {
                _module: "patient.models",
                _model: "Patient"
            });
            beneficiaryChange();
            reloadData();
        }

        function removePatient() {
            vm.payment.beneficiary = null;
        }

        function assignModel(key) {
            let _cls = {
                organization: {
                    _module: "shared.insurance.models",
                    _model: "Organization"
                },
                unregistered: {
                    _module: "payment.models",
                    _model: "UnregisteredPayer"
                },
                provider: {
                    _module: "stock.models",
                    _model: "Provider"
                }
            };
            return _.get(_cls, key)
        }

        function payerChange() {
            switch (vm.payment.payer_type) {
                case "P":
                    vm.payment.payer = _.assign({}, vm.payment.beneficiary);
                    break;
                case "T":
                    vm.payment.payer = _.assign({}, vm.payment.beneficiary);
                    break;
                case "O":
                    vm.payment.payer = _.assign(assignModel("unregistered"), {});
                    break;
                case "provider":
                    vm.payment.payer = _.assign({}, vm.payment.beneficiary);
                    break;
            }
        }

        function beneficiaryChange() {
            switch (vm.payment.beneficiary_type) {
                case "T":
                    vm.payment.beneficiary = _.assign(vm.payment.beneficiary, assignModel("organization"));
                    vm.payment.payer_type = "T";
                    payerChange();
                    break;
                case "P":
                    vm.payment.payer_type = "P";
                    payerChange();
                    break;
                case "provider":
                    vm.payment.beneficiary = _.assign(vm.payment.beneficiary, {});
                    reloadData();
                    vm.payment.payer_type = "provider";
                    payerChange();
                    break;
            }
        }

        function reloadData() {
            vm.paymentDetails = [];
            vm.paymentLines = [];
            if (vm.payment.beneficiary) {
                if (vm.paidDoc._model === "ContractInstance") {
                    vm.promise = generalPaymentService.getUnpaidContracts({
                        "_module": "billing.models",
                        "_model": "ContractInstance",
                        _parent: vm.paidDoc._parent,
                        beneficiary_id: vm.payment.beneficiary.id,
                        beneficiary_type: getBeneficiaryType()
                    }).then(refreshByContract)
                } else {
                    if (vm.paidDoc._model) {
                        vm.promise = generalPaymentService.getUnpaidInvoices({
                            _module: vm.paidDoc._module,
                            _model: vm.paidDoc._model,
                            beneficiary_id: vm.payment.beneficiary.id,
                            beneficiary_type: getBeneficiaryType()
                        }).then(refreshByInvoice)
                    }
                }
            }
        }

        function getBeneficiaryType() {
            let b_type = null;

            switch (vm.payment.beneficiary_type) {
                case "T":
                    b_type = 'ORGANIZATION';
                    break;
                case "P":
                    b_type = 'PATIENT';
                    break;
                case "provider":
                    b_type = 'Provider';
                    break;
            }
            return b_type;
        }

        function refreshByInvoice(data) {
            _.forEach(data, function (invoice) {
                refreshLines(invoice);
            });
        }

        function refreshByContract(data) {
            _.forEach(data, function (invoice) {
                refreshLines(invoice, {"_module": "billing.models", "_model": "ContractInstance"});
            });
        }

        function organizationCallback() {
            beneficiaryChange();
            reloadData()
        }

        function removeLine(item) {
            _.remove(vm.paymentLines, item);
            if (_.has(vm.payment, 'id')) generalPaymentService.updatePaymentLines(vm.payment.id, vm.paymentLines, vm.viewName).then(_.noop);
        }

        function prePrint() {
            return generalPaymentService.savePayment(vm.payment, vm.viewName, vm.mnModel);

        }

        function removePayment(payment) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_warning'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                if (!vm.prevent_remove) {
                    vm.prevent_remove = true;
                    // let contracts_lines = _.filter(vm.payment.lines, (l) => {
                    //     return l.paid_doc._model == 'ContractInstance'
                    // });
                    // if (!_.isEmpty(contracts_lines)) {
                    //     if (_.every(contracts_lines, (line) => {
                    //         return line.details_date == line.paid_doc.prev_payment_date
                    //     })) {
                    //         vm.promise = billingService.handleNextPaymentPeriod(_.map(contracts_lines, 'paid_doc.id'), true).then((data) => {
                    //             if (data) {
                    //                 vm.promise = generalPaymentService.deletePayment(payment.id, vm.viewName).then(success);
                    //             }
                    //         })
                    //     }
                    //     else {
                    //         $mdDialog.show(
                    //             $mdDialog.alert()
                    //                 .parent($(document.body))
                    //                 .clickOutsideToClose(true)
                    //                 .title('')
                    //                 .textContent($translate.instant('payment_impossible_deleting'))
                    //                 .ariaLabel('payment_impossible_deleting')
                    //                 .ok('Ok')
                    //         );
                    //     }
                    //
                    // }
                    // else {
                    vm.promise = generalPaymentService.deletePayment(payment.id, vm.viewName).then(success);

                    // }


                    // vm.promise=$q.all(promises).then(success)

                }
            });

            function success(data) {
                goBack();
            }
        }

        function goBack() {
            window.history.back();
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                if (!_.isEmpty(vm.paymentDetails)) {
                    _.forEach(vm.paymentDetails, function (line) {
                        let lIdx = _.findIndex(vm.payment.lines, function (item) {
                            if (item.paid_doc.number === line.paid_doc.number &&
                                item.paid_doc.id === line.paid_doc.id &&
                                item.paid_doc._module === line.paid_doc._module &&
                                item.paid_doc._model === line.paid_doc._model)
                                return item;
                        })
                        if (lIdx < 0) {
                            vm.payment.lines.push(line);
                        } else {
                            vm.payment.lines[lIdx].encasement_amount += line.encasement_amount;
                        }
                    })
                }
                vm.promise = generalPaymentService.savePayment(vm.payment, vm.viewName, vm.mnModel).then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) {
                    goBack();
                } else {
                    saveSuccess(data);
                }
            }
        }

        function error(err) {
            vm.prevent_save = false;
        }

        function saveSuccess(data) {
            vm.payment = data;
            if (_.isNil(currentPaymentId)) $state.go(vm.addState, {
                paymentId: data.id,
                "mnModel": vm.mnModel,
                "viewName": vm.viewName,
                "addState": vm.addState,
                "listState": vm.listState
            }, {location: 'replace'});

            reloadData();

            vm.prevent_invalidate = false;
            vm.prevent_validate = false;
        }

        function cancel() {
            if (vm.removed_lines.length > 0 && _.has(vm.payment, 'id')) generalPaymentService.updatePaymentLines(vm.payment.id, vm.removed_lines, vm.viewName).then();
            goBack();
        }

        function headInvalidate() {
            dissociateLines();
            vm.payment.is_header_valid = false;
        }

        function dissociateLines() {
            handleDissociateLines();
        }

        function handleDissociateLines() {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.payment.lines = [];
                vm.promise = generalPaymentService.savePayment(vm.payment, vm.viewName, vm.mnModel).then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                saveSuccess(data);
            }
        }

        function splitAmountLines() {
            $mdDialog.show(require('general-payment/dialogs/payment-calculate-dialog')).then((data) => {
                vm.calculateConfig = data;
                _.forEach(vm.paymentLines, resetAmount);
                _.forEach(vm.paymentLines, encasementAmountClick);

                function resetAmount(line) {
                    line.encasement_amount = 0.0;
                }
            });
        }

        function inputClick($event) {
            $event.stopPropagation();
        }

        function inputChange(line) {
            if (line.encasement_amount == 0) {
                line.is_selected = false;
                vm.paymentDetail = _.remove(vm.paymentDetails, function (item) {
                    if (item.paid_doc.number == line.paid_doc.number &&
                        item.paid_doc.id == line.paid_doc.id &&
                        item.paid_doc._module == line.paid_doc._module &&
                        item.paid_doc._model == line.paid_doc._model) return item;
                });
            } else {
                if (line.encasement_amount > 0 && !line.is_selected) {
                    line.is_selected = true;
                    vm.paymentDetails.push(line);
                }
            }
        }

        function encasementAmountClick(line) {
            if (line.encasement_amount > 0) return;
            let amount = getCurrentRemainingAmount();
            let lineRemainingAmount = line.remaining_amount;

            if (vm.calculateConfig.type === 'percent') {
                lineRemainingAmount = line.remaining_amount * vm.calculateConfig.value / 100;
            } else {
                if (line.remaining_amount > vm.calculateConfig.value) {
                    lineRemainingAmount = vm.calculateConfig.value;
                }
            }

            if (amount > 0) {
                if (amount - lineRemainingAmount > 0) line.encasement_amount = lineRemainingAmount;
                else line.encasement_amount = amount;
                inputChange(line);
            }
        }

        function getTotal() {
            let total = _.reduce(vm.paymentLines, getTotal, 0);

            return _.isNaN(total) ? 0 : total;

            function getTotal(result, value) {
                return result + value['encasement_amount'];
            }
        }

        function getCurrentRemainingAmount() {
            let model_amount = vm.payment.received_amount - vm.payment.consumed_amount;
            return model_amount - getTotal();
        }

        function duplicate() {
            $state.go('app.billing.payment-form', {
                paymentId: null,
                beneficiaryType: vm.payment.beneficiary_type,
                beneficiary: null,
            });
        }

        function getLineRemainingAmount(line) {
            if (_.get(line, 'paid_doc._model') == 'ContractInstance') {
                // if (line.details_date != line.paid_doc.next_payment_period) {
                //     return 0
                // }
                // else {
                return line.paid_doc.remaining_amount
                // }
            } else {
                return _.get(line, 'paid_doc.remaining_amount')
            }
        }

        function validateHeader() {
            vm.payment['is_header_valid'] = true;
            vm.submit();
        }

        function selectDocs(type) {
            switch (type) {
                case 'Contract':
                    vm.promise = generalPaymentService.getUnpaidContracts({
                        "_module": "billing.models",
                        "_model": "ContractInstance",
                        _parent: vm.paidDoc._parent,
                        beneficiary_id: vm.payment.beneficiary.id,
                        beneficiary_type: getBeneficiaryType()
                    }).then(refreshByContract)

                    break;
                case 'Invoice':
                    vm.promise = generalPaymentService.getUnpaidInvoices({
                        _module: vm.paidDoc._module,
                        _model: "Invoice",
                        beneficiary_id: vm.payment.beneficiary.id,
                        beneficiary_type: getBeneficiaryType()
                    }).then(function (data) {
                        _.forEach(data, function (invoice) {
                            refreshLines(invoice, {"_module": "billing.models", "_model": "Invoice"});
                        });
                    })
                    break;
            }
        }
    }
})();