(function () {
    'use strict';

    const angular = require('angular');

    const medicalCare = require('./components/medical-care');
    const medicalCareScheduling = require('./components/medical-care-scheduling');
    const medicalPlanProcedures = require('./components/medical-plan-procedures');

    const medicalCareService = require('./services/medicalCareService');

    const MedicalCareContainerCtrl = require("./controllers/MedicalCareContainerCtrl");
    const MedicalCareListCtrl = require("./controllers/MedicalCareListCtrl");
    const MedicalCareFormCtrl = require("./controllers/MedicalCareFormCtrl");
    const MedicalCareMedicalFileCtrl = require("./controllers/MedicalCareMedicalFileCtrl");
    const MedicalCareFilesCtrl = require("./controllers/MedicalCareFilesCtrl");
    const MedicalCareSchedulingCtrl = require("./controllers/MedicalCareSchedulingCtrl");

    angular
        .module("medinet")

        .component("mnMedicalCare", medicalCare)
        .component("mnMedicalCareScheduling", medicalCareScheduling)
        .component("mnMedicalPlanProcedures", medicalPlanProcedures)

        .controller('MedicalCareContainerCtrl', MedicalCareContainerCtrl)
        .controller('MedicalCareListCtrl', MedicalCareListCtrl)
        .controller('MedicalCareFormCtrl', MedicalCareFormCtrl)
        .controller('MedicalCareMedicalFileCtrl', MedicalCareMedicalFileCtrl)
        .controller('MedicalCareFilesCtrl', MedicalCareFilesCtrl)
        .controller('MedicalCareSchedulingCtrl', MedicalCareSchedulingCtrl)

        .service('medicalCareService', medicalCareService)

})();


