/**
 * Created by BETALOS on 01/03/2017.
 */
(function () {

    'use strict';

    module.exports = dentalService;

    const {Subject, BehaviorSubject} = require("rxjs");

    dentalService.$inject = ["$q", "$http", "mnWebSocket", "configService", "Upload", "$translate", "moment"];

    function dentalService($q, $http, mnWebSocket, configService, Upload, $translate, moment) {
        let self = this;

        self.getDentalProcedureGroups = getDentalProcedureGroups;
        self.getDentalProcedureGroupDetail = getDentalProcedureGroupDetail;
        self.handleProcedureGroups = handleProcedureGroups;
        self.removeProcedureGroup = removeProcedureGroup;
        self.getDentalProcedureGroupsTable = getDentalProcedureGroupsTable;
        self.getDentalProcedureGroupsTableItem = getDentalProcedureGroupsTableItem;

        self.getDentalProcedureTable = getDentalProcedureTable;
        self.getDentalProcedureTableItem = getDentalProcedureTableItem;
        self.getDentalProcedureDetail = getDentalProcedureDetail;
        self.handleDentalProcedureDetail = handleDentalProcedureDetail;
        self.removeDentalProcedure = removeDentalProcedure;

        self.getConsultationDetail = getConsultationDetail;
        self.getPatientConsultations = getPatientConsultations;
        self.getPatientCondition = getPatientCondition;
        self.getPatientPlans = getPatientPlans;

        self.refreshFinancialStatus = refreshFinancialStatus;
        self.removeFinancialStatus = removeFinancialStatus;

        self.saveCondition = saveCondition;
        self.saveConsultation = saveConsultation;

        self.savePlan = savePlan;
        self.removePlan = removePlan;
        self.planDetail = planDetail;
        self.getPlanTable = getPlanTable;
        self.savePlanDetail = savePlanDetail;
        self.toothPlanedProcedures = toothPlanedProcedures;
        self.getPlanDelivery = getPlanDelivery;
        self.handlePlanDelivery = handlePlanDelivery;

        self.chartSubject = new Subject();

        // Quotation
        self.getPatientQuotations = getPatientQuotations;
        self.handleQuotation = handleQuotation;
        self.getQuotationDetail = getQuotationDetail;
        self.getQuotationDuplicate = getQuotationDuplicate;
        self.saveQuotationDetail = saveQuotationDetail;
        self.getQuotationTable = getQuotationTable;
        self.removeQuotation = removeQuotation;

        // Dental groups
        self.chartOpacity = new BehaviorSubject(false);
        self.dentalProcedureGroups = new BehaviorSubject([]);

        // perio charting
        self.perioCharting = new BehaviorSubject(false);

        // panoramic methods
        self.panoramics = new Subject();
        self.uploadPanoramic = uploadPanoramic;
        self.updatePanoramics = updatePanoramics;
        self.patientPanoramics = patientPanoramics;
        self.handleDcmPanoramics = handleDcmPanoramics;
        self.handleFirePacsDcmPanoramics = handleFirePacsDcmPanoramics;


        function getDentalProcedureTable() {
            const deferred = $q.defer();
            const url = '/api/dental-procedure-table/';

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getDentalProcedureTableItem(id) {
            const deferred = $q.defer();
            const url = `/api/dental-procedure-table/${id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getDentalProcedureGroups() {
            const deferred = $q.defer();
            const url = '/api/dental-procedure-group/';

            $q.all([$http.get(url), configService.get(["dental_procedure_group", "dental_chart_opacity", "dental_perio_charting_config"], true)])
                .then(data => procedureGrpSuccess(deferred, data), () => deferred.resolve([]));

            return deferred.promise;
        }

        function getDentalProcedureGroupDetail(group) {
            const deferred = $q.defer();

            if (_.isNil(group)) deferred.resolve({
                dental_procedures: []
            });

            else $http.get(`/api/dental-procedure-group/${group}/`)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleProcedureGroups(group) {
            const deferred = $q.defer();
            const url = `/api/dental-procedure-group/${_.has(group, 'id') ? group.id + '/' : ''}`;

            $http.post(url, group)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeProcedureGroup(group) {
            return $http.delete(
                `/api/dental-procedure-group/${group.id}/`
            );
        }

        function getDentalProcedureGroupsTableItem(id) {
            const deferred = $q.defer();
            const url = `/api/dental-procedure-group-table/${id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getDentalProcedureGroupsTable() {
            const deferred = $q.defer();
            const url = '/api/dental-procedure-group-table/';

            $q.all([$http.get(url), configService.get(["dental_procedure_group", "dental_perio_charting_config"], true)])
                .then(data => procedureGrpSuccess(deferred, data, true), deferred.reject);

            return deferred.promise;
        }

        function procedureGrpSuccess(deferred, response, full = false) {
            const data = _.isNil(response[1]) ? response[0].data : _.chain(response[0].data)
                .filter(item => full || !_.get(response, `[1].dental_procedure_group.disabled.${item.id}`))
                .map(item => _.assign(item, {dental_procedures: _.compact(item.dental_procedures)}))
                .orderBy(item => _.get(response, `[1].dental_procedure_group.order.${item.id}`))
                .value();

            let opacity = _.get(response, "[1].dental_chart_opacity");
            self.chartOpacity.next(opacity);

            let reversePerioConfig = _.get(response, "[1].dental_perio_charting_config", {}) || {};
            self.perioCharting.next(reversePerioConfig);

            self.dentalProcedureGroups.next(data);
            deferred.resolve(data);
        }

        function getDentalProcedureDetail(procedure) {
            let deferred = $q.defer();

            if (_.isNil(procedure)) deferred.resolve({
                style: "",
                steps: [],
                overlay: [],
                charting: []
            });

            else $http.get(`/api/dental-procedure-form/${procedure}/`)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleDentalProcedureDetail(procedure) {
            let deferred = $q.defer();
            let url = `/api/dental-procedure-form/${_.has(procedure, 'id') ? procedure.id + '/' : ''}`;

            $http.post(url, procedure)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeDentalProcedure(procedure) {
            return $http.delete(
                `/api/dental-procedure-form/${procedure.id}/`
            );
        }

        function getConsultationDetail(consultation) {
            if (_.isNil(consultation.id)) return $q.reject();

            let deferred = $q.defer();
            const url = `/api/dental-consultation/${consultation.id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getPatientConsultations(patient) {
            let deferred = $q.defer();
            const url = `/api/dental-consultation/?patient=${patient}`;

            $http.get(url)
                .then(success, () => deferred.resolve([]));

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getPatientCondition(patient) {
            let deferred = $q.defer();
            const url = `/api/patient-teeth-condition/?patient=${patient}`;

            $http.get(url)
                .then(success, () => deferred.resolve([]));

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getPatientPlans(patient) {
            let deferred = $q.defer();
            const url = `/api/dental-treatment-plan/?patient=${patient}`;

            $http.get(url)
                .then(success, () => deferred.resolve([]));

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function saveConsultation(consultation, patient) {
            let deferred = $q.defer();
            const isUpdate = _.has(consultation, 'id');
            const url = `/api/dental-consultation/${isUpdate ? consultation.id + '/' : ''}?patient=${patient}`

            consultation['teeth_procedures'] = _.map(consultation['teeth_procedures'], handleConsultationMap);

            let http = $http[isUpdate ? 'put' : 'post'](url, consultation);

            http.then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            function handleConsultationMap(item) {
                let obj = {};

                if (_.has(item, 'dental_procedure') || item['dental_procedure_id']) {
                    obj['dental_procedure'] = _.get(item, 'dental_procedure', {id: item['dental_procedure_id']});
                }

                if (_.has(item, 'treatment_plan') || item['treatment_plan_id']) {
                    obj['treatment_plan'] = _.get(item, 'treatment_plan', {id: item['treatment_plan_id']})
                }

                return _.assign(item, obj);
            }

            return deferred.promise;
        }


        function refreshFinancialStatus(visit, procedures) {
            return mnWebSocket.call('visit.VisitPayment.set_visit_procedures',
                {visit, procedures}
            )
        }

        function removeFinancialStatus(visit, procedures_uid) {
            return mnWebSocket.call('visit.VisitPayment.remove_visit_procedures',
                {visit, procedures_uid}
            )
        }

        function saveCondition(condition) {
            let deferred = $q.defer();
            const url = `/api/patient-teeth-condition/${condition.id}/`;

            $http.put(url, condition)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function savePlan(plan) {
            let deferred = $q.defer();
            const isUpdate = !_.isNil(plan.id);
            const hasLines = _.has(plan, 'teeth_procedures');
            const url = `/api/dental-treatment-plan${hasLines ? '-quotation' : ''}/${isUpdate ? plan.id + '/' : ''}`;

            if (isUpdate) plan.patient = {id: plan.patient_id};
            if (hasLines) plan.teeth_procedures = _.map(plan.teeth_procedures, handleLinesMap);

            $http.post(url, plan)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(
                    _.assign(response.data, {isNew: !isUpdate})
                );
            }

            return deferred.promise;
        }

        function removePlan(plan) {
            return $http.delete(
                `/api/dental-treatment-plan/${plan.id}/`
            )
        }

        function planDetail(plan) {
            let deferred = $q.defer();
            const url = `/api/dental-treatment-plan-detail/${plan.id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                // map plan detail to include max discount and min price
                const plan = mapPlanDetail(response.data);
                deferred.resolve(plan);
            }

            return deferred.promise;
        }

        function getPlanTable(plan) {
            let deferred = $q.defer();
            const url = `/api/dental-treatment-plan/${plan.id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function savePlanDetail(plan) {
            let deferred = $q.defer();
            const url = `/api/dental-treatment-plan-detail/${plan.id}/`;

            plan.teeth_procedures = _.map(plan.teeth_procedures, handleLinesMap);

            $http.post(url, plan)
                .then(success, deferred.reject);

            function success(response) {
                // map plan detail to include max discount and min price
                const plan = mapPlanDetail(response.data);
                deferred.resolve(plan);
            }


            return deferred.promise;
        }

        // plan detail map function
        function mapPlanDetail(plan) {
            return _.assign(plan, {
                max_global_discount: maxGlobalDiscount(plan),
                teeth_procedures: _.map(plan.teeth_procedures, item => {
                    return _.assign(item, {
                        min_price: minProcedurePrice(plan, item),
                        can_remove: canRemoveProcedure(plan, item),
                        max_discount: maxProcedureDiscount(plan, item),
                    })
                })
            });

            // handle partially payed plan
            function minProcedurePrice(plan, procedure) {
                let payedAmount = _.get(plan, 'financial_status.paid_amount', 0);

                if (payedAmount === 0) return 0;
                else {
                    const procedurePrice = procedure.price - procedure.discount;
                    const total = _.get(plan, 'financial_status.total', 0);
                    const payedAmount = _.get(plan, 'financial_status.paid_amount', 0);
                    const minPrice = (payedAmount + procedurePrice) - total

                    return _.isNaN(minPrice) ? minPrice : Math.max(0, minPrice);
                }
            }

            function maxProcedureDiscount(plan, procedure) {
                let payedAmount = _.get(plan, 'financial_status.paid_amount', 0);

                if (payedAmount === 0) return procedure.price;
                else {
                    const total = _.get(plan, 'financial_status.total', 0);
                    const maxDiscount = Math.min(procedure.price, (total + procedure.discount) - payedAmount);

                    return _.isNaN(maxDiscount) ? 0 : Math.max(maxDiscount, 0);
                }
            }

            function canRemoveProcedure(plan, procedure) {
                let procedurePrice = procedure.price - procedure.discount;
                let total = _.get(plan, 'financial_status.total', 0);
                let payedAmount = _.get(plan, 'financial_status.paid_amount', 0);

                return payedAmount === 0 || procedurePrice === 0 || (total - procedurePrice) > payedAmount;
            }

            function maxGlobalDiscount(plan) {
                let grossTotal = _.get(plan, 'financial_status.gross_total', 0);
                let payedAmount = _.get(plan, 'financial_status.paid_amount', 0);

                if (payedAmount === 0) return grossTotal;
                else {
                    const discount =  Math.min(grossTotal, grossTotal - payedAmount);
                    return _.isNaN(discount) ? 0 : Math.max(discount, 0);
                }
            }


        }

        function toothPlanedProcedures(tooth, patient) {
            const deferred = $q.defer();
            const query = {tooth, patient};
            const values = ['applied_by_name', 'code', 'name', 'teeth', 'comment', 'is_done', 'steps', 'step_comments'];

            mnWebSocket.call('dental_consultation.Dental.tooth_treatment_plan_procedures', query)
                .then(success, deferred.reject);

            function success(data) {
                deferred.resolve(
                    _.chain(data).reduce(handleReduce, []).filter(item => _.includes(item.teeth, tooth) && !item.is_done).value()
                );
            }

            function handleReduce(sum, item) {
                return _.concat(
                    sum, _.map(item['teeth_procedures'], handleConsultationItems)
                );

                function handleConsultationItems(procedure) {
                    return _.assign({}, _.pick(procedure, values), {treatment_plan_title: item.title});
                }
            }

            return deferred.promise;
        }

        function getPlanDelivery(plan) {
            let deferred = $q.defer();
            const url = `/api/dental-treatment-plan-stock/${plan.id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handlePlanDelivery(plan) {
            let deferred = $q.defer();
            const url = `/api/dental-treatment-plan-stock/${plan.id}/`;

            $http.put(url, plan)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }


            return deferred.promise;
        }

        // quotation
        function getPatientQuotations(patient) {
            let deferred = $q.defer();
            const url = `/api/dental-quotation/?patient=${patient}`;

            $http.get(url)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function handleQuotation(quotation) {
            const deferred = $q.defer();
            const isUpdate = !_.isNil(quotation.id);
            const hasLines = _.has(quotation, 'teeth_procedures');

            let url = `/api/dental-quotation${hasLines ? '-duplicate' : ''}/${isUpdate ? quotation.id + '/' : ''}`;

            if (isUpdate || hasLines) {
                quotation.patient = {id: quotation.patient_id}
            }
            if (hasLines) quotation['teeth_procedures'] = _.map(quotation['teeth_procedures'], handleLinesMap);

            $http.post(url, quotation)
                .then(response => {
                    deferred.resolve(
                        _.assign(response.data, {isNew: !isUpdate})
                    )
                }, deferred.reject);

            return deferred.promise;
        }

        function getQuotationDetail(quotation) {
            let deferred = $q.defer();
            const url = `/api/dental-quotation-detail/${quotation.id}/`;

            $http.get(url)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function getQuotationDuplicate(quotation) {
            let deferred = $q.defer();
            const url = `/api/dental-quotation-duplicate/${quotation.id}/`;

            $http.get(url)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function saveQuotationDetail(quotation) {
            let deferred = $q.defer();
            const url = `/api/dental-quotation-detail/${quotation.id}/`;

            quotation['teeth_procedures'] = _.map(quotation['teeth_procedures'], handleLinesMap);

            $http.put(url, quotation)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }


        function getQuotationTable(quotation) {
            const deferred = $q.defer();
            const url = `/api/dental-quotation/${quotation.id}/`;

            $http.get(url)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function removeQuotation(quotation) {
            return $http.delete(
                `/api/dental-quotation/${quotation.id}/`
            )
        }


        // patient panoramic
        function patientPanoramics(patient) {
            let deferred = $q.defer();
            const url = `/api/patient-panoramics/?patient=${patient}`;

            $http.get(url)
                .then((response) => panoramicsSuccess(response, deferred), deferred.reject);

            return deferred.promise;
        }


        function uploadPanoramic(pk, files) {
            let deferred = $q.defer();
            const url = `/api/patient-panoramics/${pk}/upload/`;

            Upload.upload({
                url: url,
                data: {files},
            }).then((response) => panoramicsSuccess(response, deferred), deferred.reject);

            return deferred.promise;
        }

        function updatePanoramics(patientPanoramics) {
            let deferred = $q.defer();
            const url = `/api/patient-panoramics/${patientPanoramics.id}/`;

            $http.put(url, patientPanoramics)
                .then((response) => panoramicsSuccess(response, deferred), deferred.reject);


            return deferred.promise;
        }

        function panoramicsSuccess(response, deferred) {
            let data = panoramicPreviewMap(response.data);

            self.panoramics.next(data);
            deferred.resolve(data);
        }

        function handleDcmPanoramics(patient, instance) {
            let deferred = $q.defer();

            mnWebSocket.call('dcm.DcmInstanceImport.handle_imported_panoramics', {
                patient, instance: instance.id
            }).then(data => {
                let preview = panoramicPreviewMap(data);
                self.panoramics.next(preview);

                deferred.resolve(data);
            }, deferred.reject);

            return deferred.promise;
        }

        function handleFirePacsDcmPanoramics(patient, study) {
            let deferred = $q.defer();

            mnWebSocket.call('dcm.FirePacsStudies.import_instances_panoramic', {patient, study}).then(data => {
                let preview = panoramicPreviewMap(data);
                self.panoramics.next(preview);

                deferred.resolve(data);
            }, deferred.reject);

            return deferred.promise;
        }

        function panoramicPreviewMap(data) {
            const previews = _.map(data.panoramics, (item, index) => {
                return {
                    'mime': 'image/png',
                    'id': `${data.id}-${index}`,
                    'sort_date': item.sort_date,
                    'url': `/api/patient-panoramics/${data.id}/data/?index=${index}&t=${moment().valueOf()}`,
                    'name': $translate['instant']('dental_module_patient_panoramics_title', item),
                }
            });

            return _.assign(data, {previews: previews});
        }

        // multi purpose
        function handleLinesMap(item) {
            let obj = {};

            if (_.has(item, 'dental_procedure') || item['dental_procedure_id']) {
                obj['dental_procedure'] = _.get(item, 'dental_procedure', {id: item['dental_procedure_id']})
            }

            if (_.has(item, 'dental_consultation') || item['dental_consultation_id']) {
                obj['dental_consultation'] = _.get(item, 'dental_consultation', {id: item['dental_consultation_id']})
            }

            return _.assign(item, obj);
        }
    }

})();
