(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");
    const {statesHeaders} = require("../../utils/states-headers");

    class ExternalPhysicianActivityCtrl {
        constructor(genericStatesService, system, $translate, $scope, $state, $stateParams, moment) {
            this.moment = moment;
            this.genericStatesService = genericStatesService;
            this.system = system;
            this.translate = $translate;
            this.dateFormat = system['date_format'].js;
            this.currentState = genericStatesService.getCurrentState("external_treating_physician_activity");
            this.query = {
                "activity_nature": "RENTING",
                "start": this.moment().startOf("month").format(this.dateFormat),
                "end": this.moment().endOf("month").format(this.dateFormat)
            };
            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {},
                onlyData: false
            });
            this.headers = {
                _id: {
                    type: "number",
                    caption: this.translate.instant('unique_id')
                },
                referring_physician: {
                    type: "string",
                    caption: this.translate.instant("referring_physician")
                },
                code: {
                    type: "string",
                    caption: this.translate.instant("code")
                },
                description: {
                    type: "string",
                    caption: this.translate.instant("description")
                },
                date: {
                    type: "string",
                    caption: this.translate.instant("date")
                },
                sub_total: {
                    type: "number",
                    caption: this.translate.instant("sub_total")
                },
                num_months: {
                    type: "number",
                    caption: this.translate.instant("num_months")
                },
                taxed_amount: {
                    type: "number",
                    caption: this.translate.instant('amount')
                }
            };
            this.isChart = false;
        }

        static get $inject() {
            return ["genericStatesService", "system", "$translate", "$scope", "$state", "$stateParams", "moment"];
        }

        $onInit() {
            this.maxDate = this.moment().endOf("month").format(this.dateFormat);
            this.getData();
        }

        getSlice() {
            return {
                "expands": {
                    "expandAll": false,
                    "rows": [
                        {"tuple": []}
                    ]
                },
                "drills": {
                    "drillAll": false
                },
                "rows": [
                    {
                        "uniqueName": "referring_physician"
                    },
                    {
                        "uniqueName": "date"
                    },
                    {
                        "uniqueName": "description"
                    }
                ],
                "columns": [
                    {
                        "uniqueName": "code"
                    }
                ],
                "measures": [

                    {
                        "uniqueName": "num_months",
                        "aggregation": "sum",
                        "format": "integer",
                        "individual": false,
                        "grandTotalCaption": this.translate.instant('num_months')
                    },
                    {
                        "uniqueName": "sub_total",
                        "aggregation": "sum",
                        "format": "currency",
                        "individual": false,
                        "caption": this.translate.instant("amount"),
                        "grandTotalCaption": this.translate.instant("received_amount_sum")
                    }
                ],
            };
        }

        getData(onlyData = false) {
            this.promise = this.genericStatesService.getExternalPhysicianActivity(this.query)
                .then(data => {
                    if (onlyData) {
                        this.$dataSource.next({
                            onlyData: true,
                            data: data
                        });
                    } else {
                        this.$dataSource.next({
                            slice: this.getSlice(),
                            data: data,
                            headers: this.headers
                        });
                    }
                });
        }

        handleFileName() {
            return `${this.translate.instant('states_external_treating_physician_activity')}`;
        }

        onComplete(instance) {
        }

        queryChanged(onlyData = true) {
            this.getData(onlyData)
        }
    }

    module.exports = ExternalPhysicianActivityCtrl;
})();