(function () {

    'use strict';


    class FormTemplateCtrl {
        constructor() {
        }

        static get $inject() {
            return [];
        }

        $onInit() {
            this.onChange = this.onChange || _.noop;
        }
    }


    module.exports = {
        template: require("../views/form-template.tpl.html"),
        bindings: {
            formTemplate: "=template",
            patientId: "<patient",
            onChange: "&?"
        },
        controllerAs: "vm",
        controller: FormTemplateCtrl
    }
})();