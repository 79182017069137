(function () {

    'use strict';

    class DentalQuotationDialogCtrl {
        constructor($mdDialog, $translate, system, dentalService, moment, $q) {
            this.$q = $q;
            this.dialog = $mdDialog;
            this.$translate = $translate;
            this.dentalService = dentalService;

            this.dateFormat = system['date_format'].js;
            this.today = moment().format(this.dateFormat);
            this.quotationTitle = this.$translate.instant('dental_module_quotation_default_title', {date: this.today});

            this.field = {
                key: "key",
                meta_data: {dict_uid: null},
                label: $translate["instant"]('dental_treatment_plan_description'),
            }
        }

        static get $inject() {
            return ["$mdDialog", "$translate", "system", "dentalService", "moment", "$q"];
        }

        $onInit() {
            this.patient = this.patient ? this.patient : null;
            this.duplicate = this.duplicate ? this.duplicate : false;
            this.quotation = this.quotation ? this.quotation : false;

            let promise = [];

            if (this.duplicate) promise.push(
                this.dentalService.getQuotationDuplicate(this.duplicate)
            );
            else if (this.quotation) promise.push(this.quotation);
            else {
                promise.push({
                    sessions_nbr: 1,
                    creation_date: this.today,
                    title: this.quotationTitle,
                    patient: {id: this.patient}
                });
            }

            this.promise = this.$q.all(promise)
                .then(data => {
                    if (this.duplicate) this.quotation = _.chain(data).get(0).omit('id').assign({
                        title: this.$translate.instant('dental_module_quotation_duplicate_title', this.duplicate)
                    }).value();
                    else this.quotation = data[0];
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.dentalService.handleQuotation(this.quotation)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: DentalQuotationDialogCtrl,
        template: require("../views/dental-quotation-dialog.tpl.html"),
    };

})();
