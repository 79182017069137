(function () {

    'use strict';

    module.exports = {
        controller: EntryRoomDialogCtrl,
        controllerAs: "vm",
        template: require("frontdesk/views/entry-room-update-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
    };

    EntryRoomDialogCtrl.$inject = ["$mdDialog", "frontDeskService"];

    function EntryRoomDialogCtrl($mdDialog, frontDeskService) {
        let vm = this;

        vm.submit = submit;
        vm.abort = $mdDialog.cancel;


        function submit() {
            let data = _.pick(vm.entry, ['id', 'consultation_room']);

            frontDeskService.partialUpdateEntry(data)
                .then($mdDialog.hide);
        }

    }

})();
