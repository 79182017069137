(function () {

    'use strict';

    class AvailabilityDialogCtrl {
        constructor($mdDialog, frontDeskService, moment, system) {
            this.moment = moment;
            this.dialog = $mdDialog;
            this.service = frontDeskService;

            this.dateFormat = system['date_format'].js;
            this.research = _.mnDelay(() => this.researchNow(), 400);

            this.intervals = [
                {label: "appointment_availability_next_15_days", params: [15, 'day']},
                {label: "appointment_availability_next_month", params: [1, 'month']},
                {label: "appointment_availability_next_three_months", params: [3, 'month']},
            ];
        }

        static get $inject() {
            return ["$mdDialog", "frontDeskService", "moment", "system"];
        }

        $onInit() {
            this.query = this.query ? this.query : {};
            this.researchNow();
        }

        cancel() {
            this.dialog.cancel();
        }

        researchNow() {
            this.promise = this.service.appointmentAvailability(this.query)
                .then(data => {
                    this.data = _.sortBy(data, item => this.moment(item.date).valueOf());
                });
        }

        setInterval(item) {
            this.query.start_date = this.moment().add(item.params[0], item.params[1]).format(this.dateFormat);
            this.research();
        }

        selectItem(value) {
            if (this.selectedValue === value) this.selectedValue = null;
            else this.selectedValue = value;
        }

        isSelected(value) {
            return this.selectedValue === value;
        }

        selectDuration() {
            this.dialog.hide(this.selectedValue);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: AvailabilityDialogCtrl,
        template: require("../views/appointement-availability-dialog.tpl.html"),
    };

})();
