(function () {

    'use strict';

    const ZONES = require('../json/tooth-zones.json');

    class ToothSurfaceDialogCtrl {
        constructor($element, $scope, $mdDialog, $translate, dentalUtilsService) {
            this.$scope = $scope;
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.dentalUtilsService = dentalUtilsService;

            this.zoneStyle = "";
            this.svgContainer = $('.surface-svg', $element);

            $element.on(
                'click', ".surface-svg .zones > [class^='z-']", (ev) => this.handleClick(ev)
            );
        }

        static get $inject() {
            return ["$element", "$scope", "$mdDialog", "$translate", "dentalUtilsService"];
        }

        $onInit() {
            this.tooth = this.tooth ? this.tooth : null;
            this.charting = this.charting ? this.charting : null;

            this.isIncisor = _.chain(this.tooth).get(1).parseInt().value() < 4;
            this.hoverStyle = `.surface-svg .zones > *:hover ${this.charting['style_tag']}`;
            this.surfaces = _.chain(ZONES).filter('isSurface').filter(item => {
                return _.includes(['both', this.isIncisor ? 'incisor' : 'molar'], item['surfaceType']);
            }).sortBy(i => this.translate.instant(i['labelShort'])).value();

            this.promise = this.dentalUtilsService.getSurfaceSvg(this.isIncisor ? 'incisor' : 'molar')
                .then(svg => this.svgContainer.html(svg));
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.dialog.hide(this.charting);
        }

        exists(zone) {
            return _.includes(this.charting.zones, zone);
        }

        handleClick(ev) {
            const zone = $(ev.currentTarget).attr('class');

            this.toggle(zone);
            this.$scope.$applyAsync();
        }

        toggle(zone) {
            if (this.exists(zone)) _.pull(this.charting.zones, zone);
            else this.charting.zones.push(zone);

            this.zoneStyle = this.zoneStyleConstructor();
        }

        zoneStyleConstructor() {
            if (_.isEmpty(this.charting.zones)) return "";
            else {
                const selector = _.chain(this.charting.zones).map(i => `.surface-svg .zones .${i}`).join(' , ').value();
                return `${selector} ${this.charting['style_tag']}`;
            }
        }
    }


    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ToothSurfaceDialogCtrl,
        template: require("dental-consultation/views/tooth-surface-dialog.tpl.html"),
    };

})()
