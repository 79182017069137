/**
 * Created by Maria. edited by amine on 06/03/2020.
 */
(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");
    const STATES_CONFIG = require('../json/states-config.json');

    class GenericStatesService {
        constructor(mnWebSocket, configService, $q, system, $translate, moment) {
            this.ws = mnWebSocket;
            this.configService = configService;
            this.translate = $translate;
            this.moment = moment
            this.q = $q;

            this.dateFormat = system['date_format'].js;
            this.exportDateFormat = "DD-MM-YYYY";

            this.$config = new BehaviorSubject(null);
            this.$printConfig = new BehaviorSubject(null);
            this.load = _.throttle(() => this._load(), 400);
        }

        static get $inject() {
            return ["mnWebSocket", "configService", "$q", "system", "$translate", "moment"];
        }

        _load() {
            if (_.isNil(this.$config.getValue())) {
                return this.q.all([
                    this.configService.getByHttp('states_config'),
                    this.configService.getByHttp('states_print')
                ]).then(data => {
                    this.$config.next(data[0]);
                    this.$printConfig.next(data[1]);

                    return true;
                });
            } else {
                return this.q.when(true);
            }
        }

        getActivities(query) {
            return this.ws.call('generic_states.Activity.get_data', query);
        }

        getPatientState(query) {
            return this.ws.call('generic_states.Patient.get_data', query);
        }

        getInsuredReport(query) {
            return this.ws.call('generic_states.Insured.get_data', query);
        }

        getPatientsByAge(query) {
            return this.ws.call('generic_states.Age.get_data', query);
        }

        getAppointmentReport(query) {
            return this.ws.call('generic_states.Appointment.get_data', query);
        }

        getNewPatients(query) {
            return this.ws.call('generic_states.Patient.new_patients', query);
        }

        getContactPhysician(query) {
            return this.ws.call('generic_states.ContactPhysician.get_data', query);
        }

        getPregnancyReport(query) {
            return this.ws.call('generic_states.Pregnancy.get_data', query);
        }

        getMedicineReport(query) {
            return this.ws.call('generic_states.Medicine.get_data', query);
        }

        getPathologies(query) {
            return this.ws.call("generic_states.Pathology.get_data", query)
        }

        getAgingBalance() {
            return this.ws.call("generic_states.Billing.balance_status")
        }

        getRecoveries() {
            return this.ws.call("generic_states.Billing.client_recoveries")
        }

        buildLocaleProvider(formatString) {
            return {
                formatDate: date => {
                    if (date) return this.moment(date).format(formatString);
                    else return null;
                },
                parseDate: dateString => {
                    if (dateString) {
                        const m = this.moment(dateString, formatString, true);
                        return m.isValid() ? m.toDate() : new Date(NaN);
                    } else return null;
                }
            };
        }

        getCurrentState(name) {
            return _.find(STATES_CONFIG, ['name', name]);
        }

        getSubcategory(name) {
            return _.chain(STATES_CONFIG)
                .find({
                    name: name,
                    subcategory: true
                })
                .get("items", [])
                .value();
        }

        activitiesDataModification(type, data) {
            switch (type) {
                case "payment":
                    return _.reduce(data, (result, item) => {
                        const value_date = this.moment(item.date, "YYYY-MM-DD").format(this.dateFormat);
                        item.payment_label = "-";
                        item.payment_id = `#${item._id}`

                        if (item.str_id.includes("v/")) {
                        }
                        item.payment_label = `${this.translate.instant(`visit_at`)}${value_date}`;


                        if (item.str_id.includes("p/"))
                            item.payment_label = this.translate.instant(`dental_module_treatment_plan_default_title`, {date: value_date});

                        if (item.str_id.includes("mc/"))
                            item.payment_label = this.translate.instant(`medical_care.default_title_long`, {date: value_date});

                        return _.concat(result, item);
                    }, []);
                case "encasement":
                    return _.reduce(data, (result, item) => {
                        const encasement_date = this.moment(item.encasement_date, "YYYY-MM-DD").format(this.dateFormat);
                        item.label = `${this.translate.instant(`encasement_at`)} ${encasement_date}`;

                        return _.concat(result, item);
                    }, []);
                default :
                    return data;
            }
        }

        getChecks(query) {
            return this.ws.call("generic_states.BillingPayment.check_checks", query)
        }

        getExternalPhysicianActivity(query) {
            return this.ws.call("generic_states.Billing.get_external_physician_activity", query);
        }
    }

    module.exports = GenericStatesService;

})();
