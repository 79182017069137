(function () {

    'use strict';

    const COLOR_PICKER_PARAMS = require('../../parameters/json/color-picker-config.json');

    class ProcedureDialogCtrl {
        constructor(medicalCareService, $mdDialog, $translate) {
            this.medicalCareService = medicalCareService;
            this.dialog = $mdDialog;

            this.options = _.assign({}, COLOR_PICKER_PARAMS, {
                label: $translate.instant('medical_care.color'),
                okText: $translate.instant('select'),
                cancelText: $translate.instant('cancel'),
                openOnInput: false
            });
        }

        static get $inject() {
            return ["medicalCareService", "$mdDialog", "$translate"];
        }

        $onInit() {
            this.model = this.model || {};
        }

        submit() {
            this.medicalCareService.saveProcedure(this.model)
                .then(data => this.dialog.hide(data), _.noop);
        }

        cancel() {
            this.dialog.cancel();
        }

        procedureChanged() {
            if (!this.model.title) {
                this.model.title = this.model.procedure.name;
            }
        }

        reasonChanged() {
            if (!this.model.color) {
                this.model.color = this.model.reason.color;
            }
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: ProcedureDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/medical-care-procedure-dialog.tpl.html"),
    };

})();
