(function () {

    'use strict';

    const {Subject, BehaviorSubject} = require("rxjs");
    const PERIO_EMPTY_EXAM_BODY = require('../json/empty-periodontal-exam.json');

    class PeriodontalService {
        constructor($q, $http, system, moment, $mdDialog, $translate) {
            this.q = $q;
            this.http = $http;
            this.moment = moment;
            this.dialog = $mdDialog;
            this.translate = $translate;

            this.dateFormat = system['date_format'].js;

            this.newItemSubject = new Subject();
            this.activeItemSubject = new Subject();
            this.lockSubject = new BehaviorSubject({lock: true});
        }

        static get $inject() {
            return ["$q", "$http", "system", "moment", "$mdDialog", "$translate"];
        }

        getPeriodontalExams(patient) {
            const deferred = this.q.defer();
            const url = `/api/dental-periodontal-exam-list/?patient=${patient}`;

            this.http.get(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        getPeriodontalExamDetail(exam, visit) {
            const deferred = this.q.defer();

            if (_.isNil(exam)) {
                deferred.resolve({
                    visit: {id: visit},
                    body: _.cloneDeep(PERIO_EMPTY_EXAM_BODY),
                    exam_date: this.moment().format(this.dateFormat),
                })
            } else this.http.get(`/api/dental-periodontal-exam-detail/${exam}/`)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        handlePeriodontalExam(exam) {
            const deferred = this.q.defer();
            const url = `/api/dental-periodontal-exam-detail/${_.has(exam, 'id') ? exam.id + '/' : ''}`;

            this.http.post(url, exam)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        removeExam(exam, ev) {
            const deferred = this.q.defer();

            const confirm = this.dialog.confirm()
                .targetEvent(ev)
                .ariaLabel('remove plan confirm')
                .ok(this.translate['instant']('confirm_ok'))
                .cancel(this.translate['instant']('confirm_cancel'))
                .title(this.translate['instant']('periodontal_remove_confirm', exam))

            this.dialog.show(confirm)
                .then(
                    () => this.removeExamExecution(exam).then(deferred.resolve, deferred.reject), deferred.reject
                );

            return deferred.promise;
        }

        removeExamExecution(exam) {
            let url = `/api/dental-periodontal-exam-detail/${exam.id}/`;
            return this.http.delete(url);
        }
    }

    module.exports = PeriodontalService;

})();
