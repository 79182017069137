(function () {
    'use strict';

    function statesHeaders($translate, pick, options = {}) {
        options = _.assign({dateAsObject: true, dueDateCase: false}, options);

        const STATES_HEADERS = {
            payment: {
                _id: {
                    type: "number",
                    caption: $translate.instant('unique_id')
                },
                str_id: {
                    type: "string",
                    caption: $translate.instant('unique_id')
                },
                payment_date: {
                    type: _.get(options, "dateAsObject", false) ? "date" : "date string",
                    caption: $translate.instant('payment_date')
                },
                payment_id: {
                    type: "string",
                    caption: $translate.instant('payment_id')
                },
                payment_label: {
                    type: "string",
                    caption: $translate.instant('payment_label')
                },
                total_amount: {
                    type: "number",
                    caption: $translate.instant('payment_total_amount')
                },
                total_paid_amount: {
                    type: "number",
                    caption: $translate.instant('payment_paid_amount')
                },
                amount: {
                    type: "number",
                    caption: $translate.instant('payment_consumed_part_amount')
                },
                remaining_amount: {
                    type: "number",
                    caption: $translate.instant('payment_unpaid_amount')
                },
                global_discount: {
                    type: "number",
                    caption: $translate.instant('payment_discount')
                },
                payment_mode: {
                    type: "string",
                    caption: $translate.instant('payment_mode_long')
                },
                patient_id: {
                    type: "number",
                    caption: $translate.instant('unique_id')
                },
                patient_fullname: {
                    type: "string",
                    caption: $translate.instant('dcm_patient_full_name')
                },
                physician_fullname: {
                    type: "string",
                    caption: $translate.instant('daily_workflow.physician')
                },
                date: {
                    type: "date string",
                    caption: $translate.instant('payment_date')
                },
                is_exempt: {
                    type: "boolean",
                    caption: $translate.instant('exempt_visit_label')
                },
                exempt_amount: {
                    type: "number",
                    caption: $translate.instant("exempt_amount"),
                }
            },
            procedure: {
                _id: {
                    type: "number",
                    caption: $translate.instant('unique_id')
                },
                visit_date: {
                    type: _.get(options, "dateAsObject", false) ? "date" : "date string",
                    caption: $translate.instant('payment_date')
                },
                patient_id: {
                    type: "number",
                    caption: $translate.instant('unique_id')
                },
                patient_fullname: {
                    type: "string",
                    caption: $translate.instant('dcm_patient_full_name')
                },
                procedure_name: {
                    type: "string",
                    caption: $translate.instant('procedure_name')
                },
                procedure_price: {
                    type: "number",
                    caption: $translate.instant('procedure_unit_price')
                },
                procedure_total_price: {
                    type: "number",
                    caption: $translate.instant('procedure_total_price')
                },
                procedure_quantity: {
                    type: "number",
                    caption: $translate.instant('procedure_qte')
                },
                physician_fullname: {
                    type: "string",
                    caption: $translate.instant('daily_workflow.physician')
                }
            },
            encasement: {
                _id: {
                    type: "number",
                    caption: $translate.instant('unique_id')
                },
                label: {
                    type: "string",
                    caption: $translate.instant('payment_label')
                },
                encasement_date: {
                    type: _.get(options, "dateAsObject", false) ? "date" : "date string",
                    caption: $translate.instant('payment_date')
                },
                payee_fullname: {
                    type: "string",
                    caption: $translate.instant('payment_payee_label')
                },
                payment_mode: {
                    type: "string",
                    caption: $translate.instant('payment_mode_long')
                },
                physician_fullname: {
                    type: "string",
                    caption: $translate.instant('daily_workflow.physician')
                },
                total_amount: {
                    type: "number",
                    caption: _.get(options, "dueDateCase", false) ? $translate.instant('payment_to_receive_amount') : $translate.instant('payment_received_amount')
                },
                consumed_amount: {
                    type: "number",
                    caption: $translate.instant('payment_consumed_amount')
                },
                remaining_amount: {
                    type: "number",
                    caption: $translate.instant('payment_outstanding_amount')
                },
                payee_type: {
                    type: "number",
                    caption: $translate.instant('payment_payee_type')
                },
                due_date: {
                    type: "date string",
                    caption: $translate.instant('due_date')
                }
            },
            general_account: {
                _id: {
                    type: "number",
                    caption: $translate.instant('unique_id')
                },
                str_id: {
                    type: "string",
                    caption: $translate.instant('unique_id')
                },
                value: {
                    type: "number",
                    caption: $translate.instant('general_account_value')
                },
                value_label: {
                    type: "string",
                    caption: $translate.instant('general_account_value_label')
                },
                value_type: {
                    type: "string",
                    caption: $translate.instant('general_account_value_type')
                },
                value_date: {
                    type: "date string",
                    caption: $translate.instant('general_account_date')
                },
                is_exempt: {
                    type: "boolean",
                    caption: $translate.instant('exempt_visit_label')
                },
            }
        };

        return pick ? _.pick(STATES_HEADERS, pick) : STATES_HEADERS;
    }

    module.exports = {
        statesHeaders
    };

})();



