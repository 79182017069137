(function () {

    'use strict';

    class FormsContainerCtrl {
        constructor($q, $transition$, $state, visitService, formsService, mnWebSocket) {
            this.state = $state;
            this.$q = $q;
            this.visitService = visitService;
            this.formsService = formsService;
            this.mnWebSocket = mnWebSocket;
            this.items = [];
            this.isLocked = true;
            this.currentPatient = _.get($transition$.params('to'), 'pId');
        }

        static get $inject() {
            return ["$q", "$transition$", "$state", "visitService", "formsService", "mnWebSocket"];
        }

        $onInit() {
            this.items = []
            this.templates = [];
            this.newFormSubscription = this.formsService.newFormSubject.subscribe(this.handleNewForm.bind(this));
            this.editFormSubscription = this.formsService.editFormSubject.subscribe(this.handleItemEdited.bind(this));
            this.deleteFormSubscription = this.formsService.deleteFormSubject.subscribe(this.handleItemDeleted.bind(this));
            this.currentPatientSubscription = this.visitService.currentPatientSubject.subscribe(data => this.patient = data);
            let promises = [this.formsService.getFormsTemplates(), this.formsService.getForms(this.currentPatient)];

            this.promise = this.$q.all(promises).then(data => {
                this.templates = _.filter(data[0], ['is_hidden', false]);
                this.items = data[1];
            });
            this.mnWebSocket.sub('forms.FormTemplate.reload_templates', msg => {
                if (msg.reload) {
                    this.formsService.getFormsTemplates().then(data => {
                        this.templates = _.filter(data, ['is_hidden', false]);
                    });
                }
            });
        }

        $onDestroy() {
            this.currentPatientSubscription.unsubscribe();
            this.newFormSubscription.unsubscribe();
            this.editFormSubscription.unsubscribe();
            this.deleteFormSubscription.unsubscribe();
            this.mnWebSocket.sub('forms.formTemplate.templates_notify');
        }

        editItem(f) {
            this.formsService.editState(f, true);
        }

        deleteItem(f) {
            this.formsService.deleteForm(f.id).then(_.noop);
        }

        newForm(t) {
            this.formsService.editState(null, true, t);
        }

        handleNewForm(item) {
            let index = _.findIndex(this.items, {id: item.id});
            if (index === -1) {
                this.items = _.concat(item, this.items);
                this.currentForm = item.id;
            } else this.items[index] = item;
        }

        handleItemDeleted(pk) {
            _.remove(this.items, {id: pk});
            if (this.currentForm === pk) {
                this.state.go("app.visit.forms", {}).then(_.noop);
            }
        }

        handleItemEdited(item) {
            this.currentForm = item;
        }

        // for the filter in the sidenav
        selectFilter(templateId) {
            if (this.filter === templateId) {
                this.filter = null;
            } else {
                this.filter = templateId;
            }
        }
    }

    module.exports = FormsContainerCtrl;

})();
