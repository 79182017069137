(function () {
    'use strict';

    class CustomToolbar {
        get labels() {
            return _.get(this.component, 'localization.toolbar', {});
        }

        constructor(component, toolbar) {
            this.component = component;
            this.toolbar = toolbar;

        }

        exportHandler(exportType) {
            if (!this.component.emptyData) this.component.exportHandler(exportType);
        }

        printHandler() {
            if (!this.component.emptyData) this.component.printHandler();
        }

        chartsHandler(chartType) {
            if (!this.component.emptyData) {
                this.component.pivot.collapseAllData();
                this.toolbar.chartsHandler(chartType);
                this.component.gridState(false);
            }
        }

        getTabs() {
            let tabs = [
                {
                    divider: true,
                    rightGroup: true
                },
                {
                    title: this.labels.export_print,
                    id: "wdr-tab-export-print",
                    mobile: false,
                    rightGroup: true,
                    icon: "<md-icon class=\"mdi mdi-printer\"></md-icon>",
                    handler: () => this.printHandler()
                },
                {
                    title: this.labels.export,
                    id: "wdr-tab-export",
                    mobile: false,
                    rightGroup: true,
                    icon: "<md-icon class=\"mdi mdi-database-export\"></md-icon>",
                    menu: [
                        {
                            title: this.labels.export_excel,
                            id: "wdr-tab-export-excel",
                            handler: exportType => this.exportHandler(exportType),
                            args: "excel",
                            icon: "<md-icon class=\"mdi mdi-file-excel-outline\"></md-icon>"
                        },
                        {
                            title: this.labels.export_pdf,
                            id: "wdr-tab-export-pdf",
                            handler: exportType => this.exportHandler(exportType),
                            args: "pdf",
                            icon: "<md-icon class=\"mdi mdi-file-pdf-box\"></md-icon>"
                        },
                    ]
                },
                {
                    title: this.labels.format,
                    id: "wdr-tab-format",
                    icon: "<md-icon class=\"mdi mdi-table-edit\"></md-icon>",
                    rightGroup: true,
                    menu: [
                        {
                            title: this.toolbar.osUtils.isMobile ? this.labels.format_cells_mobile : this.labels.format_cells,
                            id: "wdr-tab-format-cells",
                            handler: () => {
                                if (!this.component.emptyData) this.toolbar.formatCellsHandler()
                            },
                            icon: "<md-icon class=\"mdi mdi-format-letter-matches\"></md-icon>"
                        },
                        {
                            title: this.toolbar.osUtils.isMobile ? this.labels.conditional_formatting_mobile : this.labels.conditional_formatting,
                            id: "wdr-tab-format-conditional",
                            handler: () => {
                                if (!this.component.emptyData) this.toolbar.conditionalFormattingHandler()
                            },
                            icon: "<md-icon class=\"mdi mdi-format-color-highlight\"></md-icon>"
                        }
                    ]
                },
                {
                    title: this.labels.fields,
                    id: "wdr-tab-fields",
                    handler: () => {
                        if (!this.component.emptyData) this.toolbar.fieldsHandler();
                    },
                    icon: "<md-icon class=\"mdi mdi-table-settings\"></md-icon>",
                    rightGroup: true
                },
                {
                    title: this.labels.options,
                    id: "wdr-tab-options",
                    handler: () => {
                        if (!this.component.emptyData) this.toolbar.optionsHandler()
                    },
                    icon: "<md-icon class=\"mdi mdi-cog\"></md-icon>",
                    rightGroup: true
                },
                // {
                //     divider: true,
                //     rightGroup: true
                // },
                // {
                //     title: this.labels.fullscreen,
                //     id: "wdr-tab-fullscreen",
                //     handler: () => {
                //         if (!this.component.emptyData) this.toolbar.fullscreenHandler()
                //     },
                //     mobile: false,
                //     icon: "<md-icon class=\"mdi mdi-overscan\"></md-icon>",
                //     rightGroup: true
                // }
            ];

            if (!this.component.legacy) {
                tabs = _.concat(tabs, [
                    {
                        title: this.labels.grid,
                        icon: "<md-icon class=\"mdi mdi-table-eye\"></md-icon>",
                        id: "fm-tab-grid",
                        handler: () => {
                            if (!this.component.emptyData) {
                                this.toolbar.gridHandler()
                                this.component.gridState(true);
                            }
                        }
                    },
                    {
                        title: this.labels.charts,
                        icon: "<md-icon class=\"mdi mdi-chart-multiple\"></md-icon>",
                        id: "fm-tab-charts",
                        menu: [
                            {
                                title: this.labels.charts_column,
                                id: "fm-tab-charts-column",
                                args: "column",
                                icon: "<md-icon class=\"mdi mdi-chart-bar\"></md-icon>",
                                handler: chartType => this.chartsHandler(chartType)
                            },
                            {
                                title: this.labels.charts_bar_horizontal,
                                id: "fm-tab-charts-bar-horizontal",
                                args: "bar_h",
                                icon: "<md-icon class=\"mdi mdi-poll mdi-rotate-90\"></md-icon>",
                                handler: chartType => this.chartsHandler(chartType)
                            },
                            {
                                title: this.labels.charts_line,
                                id: "fm-tab-charts-line",
                                args: "line",
                                icon: "<md-icon class=\"mdi mdi-chart-line\"></md-icon>",
                                handler: chartType => this.chartsHandler(chartType)
                            },
                            {
                                title: this.labels.charts_scatter,
                                id: "fm-tab-charts-scatter",
                                args: "scatter",
                                icon: "<md-icon class=\"mdi mdi-chart-scatter-plot\"></md-icon>",
                                handler: chartType => this.chartsHandler(chartType)
                            },
                            {
                                title: this.labels.charts_pie,
                                id: "fm-tab-charts-pie",
                                args: "pie",
                                icon: "<md-icon class=\"mdi mdi-chart-pie\"></md-icon>",
                                handler: chartType => this.chartsHandler(chartType)
                            },
                            {
                                title: this.labels.charts_stacked_column,
                                id: "fm-tab-charts-stacked-column",
                                args: "stacked_column",
                                flat: false,
                                icon: "<md-icon class=\"mdi mdi-chart-bar-stacked\"></md-icon>",
                                handler: chartType => this.chartsHandler(chartType)
                            },
                            {
                                title: this.labels.charts_column_line,
                                id: "fm-tab-charts-column-line",
                                args: "column_line",
                                icon: "<md-icon class=\"mdi mdi-finance\"></md-icon>",
                                handler: chartType => this.chartsHandler(chartType)
                            }
                        ]
                    },
                    {
                        divider: true,
                    }
                ])
            }

            return tabs;
        }
    }

    module.exports = CustomToolbar;
})();
