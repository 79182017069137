(function () {

    'use strict';

    const COLOR_PICKER_PARAMS = require('parameters/json/color-picker-config.json');

    class AgendasDialogCtrl {
        constructor($mdDialog, $translate, frontDeskService) {
            this.dialog = $mdDialog;
            this.frontDeskService = frontDeskService;

            this.options = _.assign({}, COLOR_PICKER_PARAMS, {label: $translate.instant('reason_dialog_color')});
        }

        static get $inject() {
            return ["$mdDialog", "$translate", "frontDeskService"];
        }

        $onInit() {
            this.model = _.isNil(this.model) ? {use_as_resource: true} : this.model;
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.frontDeskService.handleAgenda(this.model)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: AgendasDialogCtrl,
        template: require("frontdesk/views/agenda-form-dialog.tpl.html"),
    };

})();