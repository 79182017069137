/**
 * Created by amine on 03/11/2020.
 */
(function () {

    'use strict';


    class MedicalCareSchedulingCtrl {
        constructor(medicalCareService, configService, patientService, frontDeskService, authService, $q, $state, $transition$, $scope, moment, $translate, system) {
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.patientService = patientService;
            this.frontDeskService = frontDeskService;
            this.authService = authService;
            this.q = $q;
            this.state = $state;
            this.translate = $translate;
            this.moment = moment;

            this.$parent = $scope.$parent.vm;
            this.dateFormat = system.date_format.js;
            this.datetimeFormat = system.datetime_format.js;

            this.currentPatient = _.get($transition$.params('to'), "pId");
            this.currentItemId = _.get($transition$.params('to'), "id");
            this.item = _.get($transition$.params('to'), "item");

            this.config = {};

            this.minDate = moment().subtract(1, "day").toDate();
        }

        static get $inject() {
            return ["medicalCareService", "configService", "patientService", "frontDeskService", "authService", "$q", "$state", "$transition$", "$scope", "moment", "$translate", "system"];
        }

        $onInit() {
            //this.configService.getByHttp('medical_care_config')
            const promises = [
                this.patientService.getMinimalPatient(this.currentPatient)
            ];
            if (this.currentItemId) {
                if (this.item) {
                    promises.push(this.q.when(this.item));
                } else {
                    promises.push(this.medicalCareService.get(this.currentItemId, 'plan'));
                }
            } else this.state.go("app.visit.medical-care.list", {type: 'plan'});

            this.$parent.containerPromise = this.q.all(promises)
                .then(data => {
                    // this.config = data[0];
                    this.patient = data[0];
                    this.item = data[1];
                    this.medicalCareService.linkSubject.next("plan_scheduling");
                    this.medicalCareService.formSubject.next({
                        type: 'plan',
                        item: this.item
                    });
                }, () => this.state.go("app.visit.medical-care.list", {type: 'plan'}));

        }

        scheduleChanged() {
            this.medicalCareService.get(this.currentItemId, 'plan')
                .then(data => {
                    this.item = data;
                    this.medicalCareService.formSubject.next({
                        type: 'plan',
                        item: this.item
                    });
                })
        }
    }


    module.exports = MedicalCareSchedulingCtrl;

})();
