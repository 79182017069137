/**
 * Created by amine on 26/07/2021.
 */
(function () {

    'use strict';

    class GroupAppointmentEntryDialogCtrl {
        constructor($mdDialog, frontDeskService, appointmentFormService, groupAppointmentService, $translate, moment, system) {
            this.dialog = $mdDialog;
            this.frontDeskService = frontDeskService;
            this.appointmentFormService = appointmentFormService;
            this.groupAppointmentService = groupAppointmentService;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;


            this.query = {
                limit: 10,
                page: 1,
                order: "last_name",
                orderKey: "last_name",
                orderDir: "asc"
            }
            this.options = [5, 10, 20];
            this.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }

            this.onReorder = order => this.#onReorderEvent(order);
            this.onPaginate = (page, limit) => this.#onPaginateEvent(page, limit);

            this.selectedPatients = [];
            this.appointment = {};
        }

        static get $inject() {
            return ["$mdDialog", "frontDeskService", "appointmentFormService", "groupAppointmentService", "$translate", "moment", "system"];
        }

        get patients() {
            const pageIndex = this.query.page - 1
            const start = (pageIndex * this.query.limit);

            return _.chain(this.appointment.patients)
                .orderBy(this.query.orderKey, this.query.orderDir)
                .slice(start, start + this.query.limit)
                .value() || [];
        }

        $onInit() {
            this.selectedPatients = this.selectedPatients || [];
            this.appointmentId = this.appointmentId || null;

            if (_.isNumber(this.appointmentId)) {
                this.groupAppointmentService
                    .getAppointment(this.appointmentId)
                    .then(data => {
                        this.appointment = data;

                    });
            } else {
                this.dialog.cancel();
            }
        }

        cancel() {
            this.dialog.cancel();
        }

        select(ev) {
            this.dialog.hide({appointment: this.appointment, patients: this.selectedPatients});
        }

        selectAll(select = true) {
            if (select) {
                this.selectedPatients = _.chain(this.appointment.patients)
                    .cloneDeep()
                    .filter(item => !this.patientEntered(item))
                    .map(item => _.get(item, ['id']))
                    .value();
            } else {
                this.selectedPatients = [];
            }
        }

        patientEntered(patient) {
            return this.appointment.patients_entered.includes(patient.id);
        }

        #onReorderEvent(order) {
            const orderDir = this.query.order.includes("-") ? "desc" : "asc";
            const orderKey = order.replace("-", "");
            this.query = _.assign(this.query, {order, orderDir, orderKey});
        }

        #onPaginateEvent(page, limit) {
            this.query = _.assign(this.query, {page, limit});
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: GroupAppointmentEntryDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("frontdesk/views/group-appointment-entry-dialog.tpl.html"),
    };

})();