/**
 * Created by BETALOS on 22/09/2016.
 */
(function () {

    'use strict';

    class FilterCtrl {
        constructor(physicianService, frontDeskService, WorkFlowService, $element) {
            this.element = $element;

            //this.initialValue = [];
            this.selectedItems = [];
            this.filters = {
                'agenda': frontDeskService.agendaSubject,
                'agenda.id': frontDeskService.agendaSubject,
                'waiting_room_id': frontDeskService.roomSubject,
                'consultation_room_id': frontDeskService.roomSubject,
                'physician': physicianService.physicianSubject,
                'physician_id': physicianService.physicianSubject,
                'reason': frontDeskService.reasonSubject,
                'reason.id': frontDeskService.reasonSubject,
                'stat': WorkFlowService.filterSubject,
            };

            this.agendaModels = ['agenda', 'agenda.id'];
        }

        static get $inject() {
            return ["physicianService", "frontDeskService", "WorkFlowService", "$element"];
        }

        $onInit() {
            this.initialValue = _.isEmpty(this.initialValue) ? [] : this.initialValue;
            this.filterObj = _.isEmpty(this.filterObj) ? {} : this.filterObj;
            this.filterModel = _.isEmpty(this.filterModel) ? '' : this.filterModel;
            this.filterCall = _.isFunction(this.filterCall) ? this.filterCall : _.noop;
            this.namespace = _.isEmpty(this.namespace) ? this.filterModel : this.namespace;

            if (this.agendaModels.includes(this.filterModel)) {
                this.filterObj = _.assign(this.filterObj, {is_disabled: false});
            }

            this.subject = this.filters[this.filterModel]
                .subscribe(data => {
                    this.items = _.filter(data, this.filterObj);

                    if (this.items.length < 2) this.element.remove();
                    else this.selectedItems = this.getLastState();

                    if (!this.isAllShown() && this.items.length > 1) setTimeout(() => {
                        this.filterCall({data: {[this.filterModel]: this.getSelectedItems()}, isAll: false});
                    });
                });
        }

        $onChanges(changes) {
            if (_.has(changes, "initialValue.currentValue")) this.selectedItems = this.getLastState();
        }

        $onDestroy() {
            if (this.subject) this.subject.unsubscribe();
        }

        isShown(item) {
            return _.includes(this.selectedItems, item.id);
        }

        isAllShown() {
            return this.selectedItems.length === this.items.length;
        }

        isFilterActive() {
            return !this.isAllShown();
        }

        toggle(item) {
            if (this.isAllShown()) this.selectedItems = _.castArray(item.id);
            else if (_.includes(this.selectedItems, item.id)) _.pull(this.selectedItems, item.id);
            else this.selectedItems.push(item.id);

            this.filterCall({data: {[this.filterModel]: this.getSelectedItems()}, isAll: this.isAllShown()});
            this.handleState();
        }

        toggleAll() {
            if (this.isAllShown()) this.selectedItems = [];
            else this.selectedItems = _.map(this.items, "id");

            this.filterCall({data: {[this.filterModel]: this.getSelectedItems()}, isAll: this.isAllShown()});
            this.handleState();
        }

        getLastState() {
            if (this.agendaModels.includes(this.filterModel)) {
                if (_.isEmpty(this.initialValue)) {
                    return _.chain(this.items)
                        .filter({is_selected: true})
                        .map("id")
                        .value();
                } else {
                    return _.cloneDeep(this.initialValue);
                }
            } else {
                const savedState = localStorage.getItem(this.namespace);

                if (savedState) return JSON.parse(savedState);
                else return _.map(this.items, "id");
            }
        }

        handleState() {
            if (this.agendaModels.includes(this.filterModel)) return false;

            if (this.isAllShown()) localStorage.removeItem(this.namespace);
            else localStorage.setItem(this.namespace, JSON.stringify(this.selectedItems));
        }

        resetState() {
            this.selectedItems = this.getLastState();
            this.filterCall({data: {[this.filterModel]: this.getSelectedItems()}, isAll: this.isAllShown()});
        }

        getSelectedItems() {
            if (this.isAllShown()) return _.concat(this.selectedItems, null);
            else return this.selectedItems;
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($element, $attrs) {
        let span = "";
        let icon = "";
        let tooltipMsg = "";
        let resetHtml = "";

        const model = $attrs['model'];
        const classStr = $element.is('[dialog]') ? "md-icon-button" : "mn-header-icon";

        if (model === 'waiting_room_id') {
            tooltipMsg = "room_filter";
            icon = "mdi-seat-legroom-normal";
            span = "::item.name";
        } else if (model === 'consultation_room_id') {
            icon = "mdi-seat-legroom-normal";
            tooltipMsg = "consultation_room_filter";
            span = "::item.name";
        } else if (model === 'physician' || model === 'physician_id') {
            tooltipMsg = "physician_filter";
            icon = "mdi-account-multiple-check";
            span = "::item.full_name";
        } else if (model === 'reason' || model === 'reason.id') {
            icon = "mdi-bookmark-outline";
            tooltipMsg = "reason_filter";
            span = "::item.description";
        } else if (model === 'agenda' || model === 'agenda.id') {
            span = "::item.name";
            icon = "mdi-calendar-text";
            tooltipMsg = "agenda_filter";
            resetHtml = `
               <md-menu-divider></md-menu-divider>
               <md-menu-item>
                   <md-button ng-click="vm.resetState()" md-prevent-menu-close="md-prevent-menu-close">
                       <md-icon md-font-icon="mdi-refresh" md-font-set="mdi"></md-icon>
                       <span translate-once="reset_search"></span>
                   </md-button>
               </md-menu-item>
            `
        } else if (model === 'stat') {
            icon = "mdi-filter-variant";
            span = "item.label | translate";
            tooltipMsg = "daily_workflow.filter";
        }

        return `
            <md-menu md-position-mode="target-right target" style="padding: 0 1px;">
               <md-button aria-label="filter menu" ng-click="$mdMenu.open($event)" class="${classStr}">
                   <md-icon class="${$attrs["model"]}" md-font-icon="${icon}" md-font-set="mdi" 
                        ng-class="{'filter-active': vm.isFilterActive()}"></md-icon>
                   <md-tooltip md-direction="bottom" md-delay="150">
                        <span translate-once="${tooltipMsg}"></span>
                   </md-tooltip>
               </md-button>
               <md-menu-content width="3" class="practice-menu">
                   <md-menu-item>
                       <md-button ng-click="vm.toggleAll()" md-prevent-menu-close="md-prevent-menu-close">
                           <md-icon md-font-icon="mdi-check" md-font-set="mdi" ng-show="vm.isAllShown()"></md-icon>
                           <span translate-once="all"></span>
                       </md-button>
                   </md-menu-item>
                   <md-menu-item ng-repeat="item in vm.items track by item.id">
                       <md-button ng-click="vm.toggle(item)" md-prevent-menu-close="md-prevent-menu-close">
                           <md-icon md-font-icon="mdi-check" md-font-set="mdi" ng-show="vm.isShown(item)"></md-icon>
                            <span ng-bind="${span}"></span>
                       </md-button>
                   </md-menu-item>
                   ${resetHtml}
               </md-menu-content>
            </md-menu>
        `
    }

    module.exports = {
        template: tpl,
        bindings: {
            namespace: '@',
            filterObj: '<',
            filterCall: '&call',
            filterModel: '@model',
            initialValue: '<'
        },
        controllerAs: "vm",
        controller: FilterCtrl,
    };

})();
