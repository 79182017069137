/**
 * Created by amine on 21/04/2022.
 */
(function () {

    'use strict';

    class ExamContainerCtrl {
        constructor($state,examService, visitService, $transitions, $transition$, observationService) {
            this.state = $state;
            this.examService = examService;
            this.visitService = visitService;
            this.$transitions = $transitions;
            this.$transition$ = $transition$;
            this.observationService = observationService;

            this.listener = $transitions.onBefore({}, (transition) => this.toList(transition));
            this.currentPatient = _.get($transition$.params('to'), 'pId');
            this.observationId = _.get($transition$.params('to'), 'observationId');

            this.lock = false;
            this.observationContext = !!this.observationId
            this.showModel = true;
            this.subscriptions = {};
            this.initModels = [];
            this.initExams = [];
            this.currentTemplate = null;
        }

        static get $inject() {
            return ["$state","examService", "visitService", "$transitions", "$transition$", "observationService"];
        }

        $onInit() {
            if (this.state.is("app.visit.exam")) this.examService.listState();

            this.subscriptions.sideNaveSubscription = this.examService.sideNaveSubject
                .subscribe(lock => this.lock = _.isNil(lock) ? false : lock);
            this.subscriptions.formSubscription = this.examService.formSubject
                .subscribe(isForm => this.isForm = isForm);
            this.subscriptions.selectedTemplateSubscription = this.examService.selectedTemplate
                .subscribe(template => this.filterExamModels(template));
            this.subscriptions.currentModelSubscription = this.examService.currentModelSubject
                .subscribe(this.currentModel = status);
            this.subscriptions.modelsSubscription = this.examService.examModelSubject
                .subscribe(status => {
                    if (_.has(status, "added")) {
                        this.initModels = _.pushOrUpdate(this.initModels, status.added);
                        this.filterExamModels(status.added.template);
                    }

                    if (_.has(status, "deleted")) {
                        let i = _.findIndex(this.initModels, {id: status.deleted});
                        this.initModels.splice(i, 1);
                    }
                });
            this.subscriptions.currentPatientSubscription = this.visitService.currentPatientSubject
                .subscribe(patient => this.patient = patient);

            this.examService.getExamModels().then(data => {
                this.initModels = data;
                this.filterExamModels();
            });

        }

        $onDestroy() {
            this.listener();

            this.subscriptions.sideNaveSubscription.unsubscribe();
            this.subscriptions.currentModelSubscription.unsubscribe();
            this.subscriptions.formSubscription.unsubscribe();
            this.subscriptions.selectedTemplateSubscription.unsubscribe();
            this.subscriptions.modelsSubscription.unsubscribe();
            this.subscriptions.currentPatientSubscription.unsubscribe();
        }

        filterExamModels(template) {
            if (!template) template = this.examService.selectedTemplate.getValue();
            if (template === this.currentTemplate) return false;

            const sortFunc = (item) => {
                return _.chain(item.title).deburr().toLower().value();
            }

            this.currentTemplate = template;

            if (template && _.has(template, "id")) {
                this.models = _.chain(this.initModels)
                    .filter({template_id: template.id})
                    .sortBy(item => sortFunc(item))
                    .value();

                this.reloadExams(template);
            } else {
                this.models = _.sortBy(this.initModels, item => sortFunc(item));
                this.initExams = [];
            }

            if (!this.list || (this.list && this.models.length !== this.list.length) || (this.list && this.list.length < 1)) {
                this.showModel = true;
                this.list = this.models;
            }
        }

        reloadExams(template) {
            this.examService.getExamListTable({
                patient: this.currentPatient,
                limit: false,
                page: false,
                order: "-exam_date",
                search: {
                    templates: [template.id]
                }
            }).then(data => {
                this.initExams = data.list;
            });
        }

        useItem(item) {
            if (_.has(item, "exam_date")) {
                this.editItem(item)
            } else {
                this.useModel(item)
            }
        }

        useModel(model) {
            this.examService.newState(model.template, model);
        }

        addModel(model) {
            if (this.isForm) this.examService.contentSubject.next(model);
            else this.useModel(model);
        }

        editItem(item) {
            this.examService.editState(item, this.currentTemplate, true);
        }

        duplicateItem(item) {
            this.examService.duplicateState(item, this.currentTemplate);
        }

        deleteItem(item) {
            this.examService.removeExam(item, this.currentPatient)
                .then(_.noop, _.noop);
        }

        toggleModels() {
            this.showBiologyExamTree = false;
            this.showModel = !this.showModel;
            this.list = this.showModel ? this.models : this.initExams;
        }

        listState() {
            this.examService.listState();
        }

        editModel(model) {
            this.examService.editModelState(model);
        }

        duplicateModel(model) {
            this.examService.duplicateModelState(model);
        }

        moveModel(model, ev) {
            this.examService.moveExamModel(model, ev);
        }

        deleteModel(model, ev) {
            this.examService.deleteExamModel(model, ev);
        }

        returnToObservation(id) {
            this.observationService.returnTo(id);
        }

        toList(transition) {
            let state = transition.to();

            if (!_.eq(state.name, "app.visit.exam.list-view") && _.eq(state.name, "app.visit.exam")) {
                this.examService.listState();

                return false;
            }

            return true;
        }

    }

    module.exports = ExamContainerCtrl;

})();
