/**
 * Created by amine on 21/10/2020.
 */
(function () {

    'use strict';

    class MedicalCareSchedulingCtrl {
        constructor(
            medicalCareService, configService, patientService, frontDeskService, authService, $q, $scope, moment,
            $translate, system
        ) {
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.patientService = patientService;
            this.frontDeskService = frontDeskService;
            this.authService = authService;
            this.q = $q;
            this.translate = $translate;
            this.moment = moment;

            this.$parent = $scope.$parent.vm;
            this.dateFormat = system.date_format.js;
            this.datetimeFormat = system.datetime_format.js;

            this.item = this.item || {};
            this.patient = this.patient || {};
            this.config = {};
            this.selectedSession = null;

            this.minDate = moment().subtract(1, "day").toDate();
        }

        static get $inject() {
            return [
                "medicalCareService", "configService", "patientService", "frontDeskService", "authService", "$q",
                "$scope", "moment", "$translate", "system"
            ];
        }

        $onInit() {
            this.delayedSave = _.mnDelay((session) => this.promise = this.submit(session), 750);
            this.change = this.change || _.noop;
        }

        $onChanges(changes) {
            if (changes.item && changes.item.currentValue) {
                this.item = changes.item.currentValue;
                this.sessions = this.item.cures ? _.flatMap(this.item.cures, (cure) => cure.sessions) : [];
            }
        }

        submit(session) {
            if (session.old_appointment) {
                const oldAppointment = session.old_appointment;
                session.old_appointment = null;
                session.is_postponed = true;
                session.ignore = true;

                const postponed = {
                    session: _.pick(session, 'id'),
                    appointment: oldAppointment
                }

                return this.q.all([
                    this.submit(session),
                    this.medicalCareService.savePostponedSession(postponed),
                    this.frontDeskService.removeAppointment(oldAppointment.id)
                ]);
            } else {
                return this.medicalCareService.saveSession(session)
                    .then(session => {
                        this.sessions = _.pushOrUpdate(this.sessions, session);
                        this.change();
                    });
            }
        }

        setAppointment(session) {
            const defaultPhysician = _.get(this.configService.defaultValues, "physician", null);
            const physician = this.authService.staff || defaultPhysician;

            session.provisional_date = session.planned_date;

            session.appointment = {
                reason: session.care_procedure.reason,
                physician,
                patient: this.patient,
                from_medical_care: true,
                is_waiting_list: true,
                waiting_list_comment: this.translate.instant('medical_care.comment_w_date', {
                    date: session.planned_date,
                    title: session.care_procedure.title
                })
            };

            this.delayedSave(session);
        }

        editAppointment(session) {
            if (!this.item.is_validated) return false;

            const deferred = this.q.defer();
            let appointmentDate = _.get(session, 'appointment.date', this.moment().format(this.dateFormat));
            const reason = session.care_procedure.reason;
            let event = {
                date: this.moment().format(this.dateFormat),
                start_time: "09:00",
                end_time: `09:${_.get(reason, 'appointment_duration', 10)}`,
                patient: this.patient,
                from_medical_care: true,
                waiting_list_comment: this.translate.instant('vaccination_wl_comment', {title: session.care_procedure.title}),
                reason
            }

            if (session.status === "missed") {
                session.old_appointment = _.pick(session.appointment, 'id');
            } else {
                if (_.has(session, 'appointment.id')) {
                    event = {
                        id: session.appointment.id,
                        assignIn: {
                            date: appointmentDate
                        }
                    }
                } else {
                    event.date = appointmentDate
                }
            }

            deferred.resolve(event);

            return deferred.promise;
        }

        markAsDone(session) {
            if (!session.done_at) {
                session.done_at = this.moment().format(this.datetimeFormat);
                if (!session.done_by) session.done_by = _.cloneDeep(session.appointment.physician);
            }

            return this.submit(session);
        }

        unlockToUpdate(session, forced = true) {
            let doneBy = session.done_by;
            if (!doneBy) doneBy = this.authService.staff || (forced ? this.configService.defaultValues.physician : null);
            
            session.is_forced = true;
            session.locked = false;
            session.done_by = doneBy;
            session.updates.push({
                forced,
                is_done: session.is_done,
                done_by: doneBy,
                edited_by: this.authService.staff || null,
                edited_at: this.moment().format(this.datetimeFormat)
            });
        }

        selectSession(session) {
            if (this.selectedSession === session.id) this.selectedSession = null;
            else this.selectedSession = session.id;
        }
    }

    module.exports = {
        bindings: {
            item: "<plan",
            patient: "<patient",
            promise: "=",
            change: "&onChange"
        },
        template: require('medical-care/views/medical-care-scheduling.tpl.html'),
        controllerAs: "vm",
        controller: MedicalCareSchedulingCtrl
    };

})();




