(function () {

    'use strict';

    const COLOR_PICKER_PARAMS = require('parameters/json/color-picker-config.json');

    class RoomDialogCtrl {
        constructor($mdDialog, frontDeskService, $translate) {
            this.dialog = $mdDialog;
            this.frontDeskService = frontDeskService;

            this.options = _.assign({}, COLOR_PICKER_PARAMS, {label: $translate.instant('reason_dialog_color')});
        }

        static get $inject() {
            return ["$mdDialog", "frontDeskService", "$translate"];
        }

        $onInit() {
            this.model = this.model ? this.model : {type: "WR", capacity: 10};
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.frontDeskService.handleRoom(this.model)
                .then(data => this.dialog.hide(data));
        }

    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: RoomDialogCtrl,
        template: require("../views/room-dialog.tpl.html"),
    };

})();
