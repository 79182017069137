/**
 * Created by amine on 24/09/2018.
 */

(function () {
    'use strict';

    class TeethProceduresCtrl {
        constructor($translate) {
            this.translate = $translate;
        }

        static get $inject() {
            return ["$translate"];
        }

        dentalLineText(line) {
            if (_.isEmpty(line.teeth)) {
                return "-"
            } else if (line.teeth.length > 4) {
                return `${this.translate.instant("dental_setup_is_multi_teeth")}`
            } else {
                return `${line.teeth.join(",")}`;
            }
        }

        $onInit() {
        }
    }

    module.exports = {
        controller: TeethProceduresCtrl,
        controllerAs: "vm",
        bindings: {
            plan: "<plan"
        },
        template: require('dental-consultation/views/teeth-procedures.tpl.html'),
    };
})();
