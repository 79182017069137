(function () {

    'use strict';

    const EMPTY_DELIVERY_DOCUMENT = {
        price_mode: 'TTC',
        patient: null,
        doc_date: null,
        warehouse: null,
        lines: []
    };

    class PlanStockDialogCtrl {
        constructor($mdDialog, $q, dentalService, warehouseService, stockService, moment) {
            this.dialog = $mdDialog;
            this.dentalService = dentalService;
            this.warehouseService = warehouseService;
            this.stockService = stockService;
            this.moment = moment;
            this.q = $q;

            this.deliveryDocument = null;
            this.defaultWarehouse = null;
            this.deliveryDocumentIndex = null;
        }

        static get $inject() {
            return ["$mdDialog", "$q", "dentalService", "warehouseService", "stockService", "moment"];
        }

        $onInit() {
            this.plan = this.plan || {};
            this.readOnly = this.readOnly || false;

            const promises = [
                this.warehouseService.getDefault()
            ];

            if (this.hasDeliveries) promises.push(this.dentalService.getPlanDelivery(this.plan));

            this.promise = this.q.all(promises).then(data => {
                this.defaultWarehouse = data[0];

                if (data.length > 1) {
                    this.plan = _.assign(this.plan, data[1]);
                    this.originalDocuments = _.compact(this.plan.delivery_documents);

                    this.plan.delivery_documents = _.cloneDeep(this.originalDocuments);

                    if (this.plan.delivery_documents.length < 1) this.noDocuments();
                } else this.noDocuments();

                this.plan = _.pick(this.plan, ['id', 'delivery_documents', 'patient_id', 'creation_date']);
            });

        }

        get hasDeliveries() {
            if (this.plan.delivery_documents) return !!_.filter(this.plan.delivery_documents, doc => _.has(doc, 'id')).length;
            else return _.get(this.plan, 'has_deliveries', false);
        }

        noDocuments() {
            this.deliveryDocument = this.getNewDeliveryForm();
            this.deliveryDocumentIndex = 0;
            this.plan.delivery_documents = [this.deliveryDocument];
        }

        showForm(index) {
            if (_.isNil(index)) {
                this.deliveryDocument = this.getNewDeliveryForm();
                this.deliveryDocumentIndex = this.plan.delivery_documents ? this.plan.delivery_documents.length : 0;
                this.plan.delivery_documents.push(this.deliveryDocument);
            } else {
                this.deliveryDocumentIndex = index;
                this.deliveryDocument = this.plan.delivery_documents[index];
            }
        }

        showList() {
            this.plan.delivery_documents = _.cloneDeep(this.originalDocuments);
            this.deliveryDocument = null;
            this.deliveryDocumentIndex = null;
        }

        deleteDocument(index) {
            this.stockService.deleteDelivery(this.plan.delivery_documents[index])
                .then(() => {
                    this.plan.delivery_documents.splice(index, 1);
                    this.promise = this.dentalService.handlePlanDelivery(this.plan)
                        .then(() => this.dialog.hide(this.plan.delivery_documents.length));
                });
        }

        getNewDeliveryForm() {
            return _.assign({}, EMPTY_DELIVERY_DOCUMENT, {
                patient: this.plan.patient_id,
                doc_date: this.moment(),
                warehouse: this.defaultWarehouse,
            })
        }

        isValidForm(form) {
            if (_.isNil(this.deliveryDocument)) return false;
            else return this.deliveryDocument.lines.length > 0 && this.stockService.isAllValid(this.deliveryDocument, 'OUT', 'DeliveryForm') && !form.$invalid;
        }

        submit() {
            this.stockService.saveDelivery(this.deliveryDocument)
                .then(data => {
                    if (!data.validate_error) {
                        if (!this.deliveryDocument.id) {
                            this.plan.delivery_documents[this.deliveryDocumentIndex] = data.data;
                            this.deliveryDocument = data.data;
                            this.promise = this.dentalService.handlePlanDelivery(this.plan)
                                .then(() => this.cancel());
                        } else this.cancel();
                    } else this.cancel();
                }, () => this.cancel());
        }

        cancel() {
            this.dialog.hide(this.hasDeliveries);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: PlanStockDialogCtrl,
        template: require("../views/dental-plan-stock-dialog.tpl.html"),
    };

})();
