/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    module.exports = frontDeskService;

    const {Subject, BehaviorSubject} = require("rxjs");

    const CALENDAR_RESOURCE_KEY = "calendar_resource";
    const ENTRY_DIALOG = require('../dialogs/entry-dialog');
    const PLANNING_DIALOG = require('../dialogs/planning-dialog');
    const APPOINTMENT_DIALOG = require('../dialogs/appointment-dialog');
    const ENTRY_RESUME_DIALOG = require('../dialogs/entry-resume-dialog');

    frontDeskService.$inject = [
        "mnWebSocket", "$q", "$http", "system", "$mdToast", "$translate", "configService", "$state",
        "$mdDialog", "practiceService", "$injector"
    ];

    function frontDeskService(
        mnWebSocket, $q, $http, system, $mdToast, $translate, configService, $state, $mdDialog, practiceService,
        $injector
    ) {
        let self = this;

        const timeFormat = system['time_format'].js;
        const dateFormat = system['date_format'].js;
        const dateTimeFormat = system['date_format'].naive;

        const defaultResource = localStorage.getItem(CALENDAR_RESOURCE_KEY) || 'physician_resource';
        self.resourceSubject = new BehaviorSubject(defaultResource);
        self.CALENDAR_RESOURCE_KEY = CALENDAR_RESOURCE_KEY;

        self.getReasons = getReasons;
        self.handleReason = handleReason;
        self.removeReason = removeReason;
        self.reasonSubject = new BehaviorSubject([]);

        self.getAgendas = getAgendas;
        self.handleAgenda = handleAgenda;
        self.removeAgenda = removeAgenda;
        self.getAgendasResource = getAgendasResource;
        self.agendaSubject = new BehaviorSubject([]);

        self.getRooms = getRooms;
        self.handleRoom = handleRoom;
        self.removeRoom = removeRoom;
        self.newRoomSubscriber = new Subject();
        self.roomSubject = new BehaviorSubject([]);

        self.getAppointment = getAppointment;
        self.handleAppointment = handleAppointment;
        self.removeAppointment = removeAppointment;
        self.searchAppointment = searchAppointment;
        self.patientAppointments = patientAppointments;
        self.editAppointment = editAppointment;
        self.partialUpdateAppointment = partialUpdateAppointment;
        self.getNextAppointment = getNextAppointment;

        self.addNewAppointment = addNewAppointment;

        self.appointmentAvailability = appointmentAvailability;

        self.handlePause = handlePause;
        self.handleEvent = handleEvent;

        self.getBackgroundEvent = getBackgroundEvent;
        self.getBackgroundEvents = getBackgroundEvents;
        self.getBackgroundTableEvent = getBackgroundTableEvent;
        self.removeBackgroundEvent = removeBackgroundEvent;

        self.getFullEntry = getFullEntry;
        self.handleEntry = handleEntry;
        self.updateEntry = updateEntry;
        self.cancelEntry = cancelEntry;
        self.partialUpdateEntry = partialUpdateEntry;
        self.checkPatientEntry = checkPatientEntry;
        self.getEntryByVisit = getEntryByVisit;
        self.reopenEntry = reopenEntry;
        self.entryResume = entryResume;
        self.editEntry = editEntry;

        self.generateVisit = generateVisit;
        self.startNewVisit = startNewVisit;
        self.appointmentToEntry = appointmentToEntry;
        self.createEntryFromCalendar = createEntryFromCalendar;
        self.startShowVisitPatientFile = startShowVisitPatientFile;

        self.decideMethod = decideMethod;

        self.planningEditSubject = new Subject();
        self.planningListSubject = new Subject();

        self.getPlanning = getPlanning;
        self.getPlanningList = getPlanningList;
        self.getPlanningListItem = getPlanningListItem;
        self.handlePlanning = handlePlanning;
        self.editPlanning = editPlanning;
        self.duplicatePlanning = duplicatePlanning;
        self.removePlanning = removePlanning;

        function getReasons() {
            const deferred = $q.defer();
            const url = "/api/reason/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                self.reasonSubject.next(response.data);
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleReason(reason) {
            const deferred = $q.defer();
            const url = `/api/reason/${reason.id ? reason.id + "/" : ''}`;

            $http.post(url, reason)
                .then(success, deferred.reject);

            function success(response) {
                const values = _.pushOrUpdate(self.reasonSubject.getValue(), response.data);
                self.reasonSubject.next(values);
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }


        function removeReason(reason) {
            const url = `/api/reason/${reason.id}/`;

            return $http.delete(url)
                .then(() => {
                    const values = _.remove(self.reasonSubject.getValue(), {id: reason.id});
                    self.reasonSubject.next(values);
                });
        }


        function getAgendas() {
            const deferred = $q.defer();
            const url = "/api/agenda/";

            $q.all([$http.get(url), configService.get("agenda_list_config")])
                .then(success, deferred.reject);

            function success(response) {
                let result = response[0].data;
                let config = response[1] || {};

                result = _.map(result, item => {
                    return _.assign(item, {
                        is_disabled: !!_.get(config, `disabled.${item.id}`),
                        is_selected: !_.get(config, `unselected.${item.id}`),
                    });
                });

                self.agendaSubject.next(result);
                deferred.resolve(result);
            }

            return deferred.promise;
        }

        function handleAgenda(agenda) {
            const deferred = $q.defer();
            const url = `/api/agenda/${agenda.id ? agenda.id + '/' : ''}`;

            $http.post(url, agenda)
                .then(success, deferred.reject);

            function success(response) {
                const values = _.pushOrUpdate(self.agendaSubject.getValue(), response.data);
                self.agendaSubject.next(values);
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getAgendasResource(filter) {
            const deferred = $q.defer();
            const agendas = _.get(filter, 'agenda', []);

            self.agendaSubject.subscribe(success);

            function success(data) {
                const items = _.chain(data)
                    .filter({use_as_resource: true, is_disabled: false})
                    .filter(item => agendas.length > 0 ? _.includes(agendas, item.id) : true)
                    .orderBy(item => _.lowerCase(item.name))
                    .map((item, order) => new Object({
                        order,
                        title: item['name'],
                        color: item['color'],
                        id: `agenda_${item.id}`
                    })).value();

                deferred.resolve(items);
            }

            return deferred.promise;
        }

        function removeAgenda(agenda, ev) {
            const deferred = $q.defer();

            const confirm = $mdDialog.confirm()
                .targetEvent(ev)
                .ariaLabel('remove plan confirm')
                .ok($translate.instant('confirm_ok'))
                .cancel($translate.instant('confirm_cancel'))
                .title($translate.instant('agenda_remove_confirm', agenda));

            $mdDialog.show(confirm).then(remove, deferred.reject);

            function remove() {
                const url = `/api/agenda/${agenda.id}/`;
                $http.delete(url).then(success);
            }

            function success() {
                const values = _.remove(self.agendaSubject.getValue(), {id: agenda.id});
                self.agendaSubject.next(values);
                deferred.resolve(true)
            }

            return deferred.promise;
        }


        function getRooms() {
            const deferred = $q.defer();
            const url = "/api/room/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                self.roomSubject.next(response.data);
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleRoom(room) {
            const deferred = $q.defer();
            const url = `/api/room/${room.id ? room.id + '/' : ''}`;

            $http.post(url, room)
                .then(success, deferred.reject);

            function success(response) {
                const values = _.pushOrUpdate(self.roomSubject.getValue(), response.data);
                self.roomSubject.next(values);
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeRoom(room) {
            const url = `/api/room/${room.id}/`;
            return $http.delete(url)
        }

        function getAppointment(appointment) {
            const deferred = $q.defer();
            const url = `/api/appointment/${appointment}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleAppointment(appointment) {
            const deferred = $q.defer();
            const url = `/api/appointment/${appointment.id ? appointment.id + "/" : ''}`;

            if (appointment['is_waiting_list']) {
                appointment = _.assign({}, appointment, {
                    'date': null,
                    'start_time': null,
                    'end_time': null,
                    'ignore': true
                });
            }

            $http.post(url, appointment)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function partialUpdateAppointment(id, data) {
            const deferred = $q.defer();
            const url = `/api/appointment/${id}/`;

            $http.put(url, data)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function getNextAppointment(patient, ev) {
            return mnWebSocket.call('frontdesk.AppointmentForm.patient_next_appointment', {patient})
                .then(appointment => {
                    if (appointment) editAppointment(appointment, ev);
                    else {
                        const simpleToast = $mdToast.simple()
                            .textContent($translate.instant('patient_has_no_next_appointment'))
                            .position("bottom left")
                            .hideDelay(2000);

                        $mdToast.show(simpleToast);
                    }
                });
        }

        function addNewAppointment(patient, ev) {
            ev.stopPropagation();

            $mdDialog.show(_.assign({}, APPOINTMENT_DIALOG, {
                targetEvent: ev,
                locals: {
                    timeRange: null,
                    hasPause: false,
                    patient: patient,
                    event: {is_waiting_list: false}
                }
            }));
        }

        function appointmentAvailability(query) {
            return mnWebSocket.call('frontdesk.AppointmentForm.availability', query);
        }

        function validateAppointment(id, is_entered, reason = null) {
            const deferred = $q.defer();
            const url = `/api/appointment/${id}/`;

            $http.put(url, _.assign({is_entered}, reason ? {reason} : {}))
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function removeAppointment(event_id) {
            const deferred = $q.defer();
            const condition = _.isLength(event_id);

            const url = `/api/${condition ? 'appointment' : 'background-event'}/${condition ? event_id : event_id.split('-')[1]}/`;

            $http.delete(url)
                .then(success, deferred.reject);

            function success() {
                deferred.resolve({
                    id: event_id, is_delete: true
                });
            }

            return deferred.promise;
        }

        function searchAppointment(query) {
            return mnWebSocket.call('frontdesk.AppointmentForm.search', query);
        }

        function patientAppointments(query) {
            return mnWebSocket.call('frontdesk.AppointmentForm.patient_appointments', query);
        }

        function editAppointment(appointment, ev) {
            return $mdDialog.show(_.assign({}, APPOINTMENT_DIALOG, {
                targetEvent: ev,
                locals: {
                    timeRange: null,
                    hasPause: false,
                    patient: null,
                    event: appointment
                },
                multiple: true
            }));
        }

        function getBackgroundEvents() {
            const deferred = $q.defer();
            const url = "/api/background-event-table/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function getBackgroundEvent(event) {
            const deferred = $q.defer();
            const url = `/api/background-event/${event}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function getBackgroundTableEvent(event) {
            const deferred = $q.defer();
            const url = `/api/background-event-table/${event}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function removeBackgroundEvent(event) {
            const deferred = $q.defer();
            const url = `/api/background-event-table/${event}/`;

            $http.delete(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function handlePause(pause) {
            const deferred = $q.defer();
            const url = `/api/background-event/${pause.id ? pause.id + '/' : ''}`;

            const startTime = moment(pause.start_time, timeFormat);
            const endTime = moment(pause.end_time, timeFormat);

            const start = moment(pause.date, dateFormat)
                .set({hours: startTime.get('hour'), minutes: startTime.get('minute')})
                .format(dateTimeFormat);

            const end = moment(pause.date, dateFormat)
                .add({hours: endTime.get('hour'), minutes: endTime.get('minute')})
                .format(dateTimeFormat);


            const backgroundEvent = _.assign({
                start: start,
                end: end,
                is_pause: true,
                unavailable: true,
            }, _.pick(pause, ['id', 'title', 'physician']));

            $http.post(url, backgroundEvent)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function handleEvent(event) {
            const deferred = $q.defer();
            const url = `/api/background-event/${event.id ? event.id + '/' : ''}`;

            $http.post(url, event)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function checkPatientEntry(id) {
            const deferred = $q.defer();

            mnWebSocket.call('frontdesk.Practice.patient_entry_check', {id})
                .then(success);

            function success(data) {
                if (data) deferred.resolve(data);
                else {
                    const simpleToast = $mdToast.simple()
                        .textContent($translate.instant('patient_has_entry'))
                        .position("bottom left")
                        .hideDelay(2000);

                    $mdToast.show(simpleToast);
                    deferred.reject(data);
                }
            }

            return deferred.promise;
        }

        function getEntryByVisit(visit, patient) {
            return mnWebSocket.call('frontdesk.Practice.get_entry_by_visit', {visit, patient})
        }

        function reopenEntry(entry, stat) {
            const newEntry = {
                stat: stat,
                reason: _.pick(entry.reason, 'id'),
                other_comment: entry.other_comment,
                patient: _.pick(entry.patient, 'id'),
                physician: {id: entry['physician_id']},
                entry_time: moment().format(dateTimeFormat),
            };

            return handleEntry(newEntry);
        }

        function entryResume(locals, ev, assign = {}) {
            const dialog = _.assign({}, ENTRY_RESUME_DIALOG, {
                targetEvent: ev,
                locals: locals,
            }, assign);

            return $mdDialog.show(dialog);
        }

        function editEntry(entry, ev) {
            return $mdDialog.show(_.assign({}, ENTRY_DIALOG, {
                targetEvent: ev, locals: {entryTmp: _.cloneDeep(entry)}
            }))
        }

        function getFullEntry(entry) {
            const deferred = $q.defer();
            const url = `/api/entry/${entry.id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function handleEntry(entry) {
            const deferred = $q.defer();
            const url = `/api/entry/${entry.id ? entry.id + "/" : ''}`;

            const promises = [$http.post(url, entry)];

            if (entry.appointment) promises.push(validateAppointment(entry.appointment.id, true, entry.reason));
            if (entry.has_appointment) promises.push(validateAppointment(entry.appointment_id, true, entry.reason));

            $q.all(promises)
                .then(success, deferred.reject);

            function success(response) {
                if (promises.length === 2) {
                    deferred.resolve({
                        entry: response[0].data,
                        appointment: response[1]
                    });
                } else deferred.resolve(response[0].data);
            }

            return deferred.promise
        }


        function updateEntry(id, source, target) {
            const obj = {
                id, stat: target.toUpperCase()
            };

            switch (target) {
                case "wr" :
                    obj.visit_start_time = null;
                    obj.visit_end_time = null;
                    obj.exit_time = null;
                    break;
                case "sv" :
                    if (source === "wr") obj.visit_start_time = moment().format(dateTimeFormat);
                    obj.visit_end_time = null;
                    obj.exit_time = null;
                    break;
                case "ev" :
                    obj.visit_end_time = moment().format(dateTimeFormat);
                    obj.exit_time = null;
                    break;
                case "ex" :
                    obj.exit_time = moment().format(dateTimeFormat);
                    break;
            }

            return partialUpdateEntry(obj);
        }

        function partialUpdateEntry(entry) {
            const deferred = $q.defer();
            const url = `/api/entry/${entry.id}/`;

            $http.put(url, entry)
                .then(success, deferred.reject);

            function success(response) {
                mnWebSocket.pub("frontdesk.Practice.stat_changed", {
                    id: response.data.id
                }, false);

                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function cancelEntry(entry) {
            const deferred = $q.defer();
            const url = `/api/entry/${entry.id}/`;

            const promises = [$http.delete(url)];

            if (entry['has_appointment']) promises.push(validateAppointment(entry['appointment_id'], false));

            $q.all(promises)
                .then(success, deferred.reject);

            function success(response) {
                if (promises.length > 1) deferred.resolve({
                    entry: entry,
                    appointment: response[1]
                });

                else deferred.resolve({entry: entry});
            }

            return deferred.promise
        }

        function generateVisit(entry, state) {
            if (entry['has_visit'] && _.isInteger(entry['visit_id']) && entry['visit_id'] !== -1) return goToVisit(entry);
            else return startNewVisit(entry).then(doneCallbacks);

            function doneCallbacks(data) {
                mnWebSocket.pub("frontdesk.Practice.entry_updated", data, false);
                goToVisit(data);
            }

            function goToVisit(data) {
                return $state.go(state, {visitId: data.visit_id, pId: data.patient.id});
            }
        }

        function startNewVisit(entry) {
            const deferred = $q.defer();
            const url = `/api/entry/${entry.id}/`;

            const obj = {
                stat: "SV",
                visit_start_time: _.isNil(entry["visit_start_time"]) ? moment().format(dateTimeFormat) : entry["visit_start_time"],
                patient: _.assign(entry.patient, {has_visit: true}),
                visit: {
                    patient: _.pick(entry.patient, 'id'),
                    visit_date: moment().format(dateFormat),
                    physician: {id: entry['physician_id'] || _.get(entry, 'physician.id')}
                }
            };

            $http.put(url, obj)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function createEntryFromCalendar(calendarEvent, stat, room) {
            const deferred = $q.defer();

            getAppointment(calendarEvent.id)
                .then(changeToEntry);

            function changeToEntry(appointment) {
                checkPatientEntry(appointment.patient.id)
                    .then(success);

                function success() {
                    const entry = appointmentToEntry(appointment, stat, room);
                    handleEntry(entry).then(deferred.resolve);
                }
            }

            return deferred.promise
        }

        function appointmentToEntry(appointment, stat, room) {
            const wr = _.get(configService.defaultValues, "entry.waiting_room", null);
            const cr = _.get(configService.defaultValues, "entry.consultation_room", null);

            stat = _.toUpper(stat);
            const entry = _.assign({
                stat: stat,
                consultation_room: cr,
                waiting_room: room || wr,
                appointment: _.pick(appointment, 'id'),
                entry_time: moment().format(dateTimeFormat),
            }, _.pick(appointment, ['patient', 'physician', 'reason', 'operator', 'other_comment', 'has_video_call']));

            if (stat === 'SV') entry['visit_start_time'] = moment().format(dateTimeFormat);

            return entry;
        }


        function startShowVisitPatientFile(patient, ev, state = "app.visit.consultation") {
            if (ev) ev.stopPropagation();

            const deferred = $q.defer();

            if (patient.is_draft) {
                const simpleToast = $mdToast.simple()
                    .textContent($translate.instant('patient_is_not_completed'))
                    .position("bottom left")
                    .hideDelay(2000);

                $mdToast.show(simpleToast);
                deferred.reject(false);
            } else mnWebSocket.call('frontdesk.Practice.get_patient_entry', {id: patient.id})
                .then(success);

            function success(data) {
                if (_.isNull(data)) createNewEntry();
                else getEntry(data);
            }

            function getEntry(data) {
                if (!data['has_visit'] && data.stat !== "EV") startNewVisit(data).then(doneCallback);
                else if (data['has_visit']) doneCallback(data);
            }

            function createNewEntry() {
                const entry = {
                    physician: getPhysician(),
                    patient: _.chain(patient).pick('id').assign({has_visit: true}).value(),
                    reason: _.get(configService.defaultValues, "entry.reason", null),
                    entry_time: moment().format(dateTimeFormat),
                    visit_start_time: moment().format(dateTimeFormat),
                    stat: "SV",
                    visit: {
                        physician: getPhysician(),
                        patient: _.pick(patient, 'id'),
                        visit_date: moment().format(dateFormat),
                    }
                };

                handleEntry(entry).then(entryCreated);
            }

            function entryCreated(data) {
                practiceService.updateEntries(data);
                mnWebSocket.pub("frontdesk.Practice.entry_added", data);

                goToVisit(data);
            }

            function doneCallback(data) {
                practiceService.entryUpdated(data);
                mnWebSocket.pub("frontdesk.Practice.entry_updated", data);

                goToVisit(data);
            }

            function goToVisit(data) {
                const visitId = data.visit_id ? data.visit_id : data.visit.id;
                $state.go(state, {visitId: visitId, pId: data.patient.id});
                deferred.resolve(true);
            }

            return deferred.promise;
        }

        function decideMethod(item, source, target) {
            target = target ? target : "ex";
            switch (true) {
                case _.eq(source, "wr") && (_.eq(target, "cv") || _.eq(target, "sv")) :
                    startVisit(item, source, 'sv');
                    break;
                case _.eq(source, "wr") && _.eq(target, "ex"):
                    //exit(item, source);
                    break;
                case (_.eq(source, "cv") || _.eq(source, "sv")) && _.eq(target, "wr"):
                    backToWR(item, source, target);
                    break;
                case _.eq(source, "sv") && _.eq(target, "ev") :
                    endVisit(item, source, target);
                    break;
                case _.eq(source, "ev") && _.eq(target, "sv") :
                    backToVisit(item, source, target);
                    break;
                case (_.eq(source, "cv") || _.eq(source, "ev")) && _.eq(target, "ex") && _.eq(item.stat, 'EV'):
                    exitVisit(item, source);
                    break;
            }
        }

        function backToWR(item, source, target) {
            updateEntry(item.id, source, target);
        }

        function startVisit(item, source, target) {
            if (!item.patient['is_draft']) {
                return updateEntry(item.id, source, target)
            }
        }

        function backToVisit(item, source, target) {
            if (item['visit_financial_status']['remaining_amount'] === item['visit_financial_status'].total) {
                return updateEntry(item.id, source, target)
            }
        }

        function endVisit(item, source, target) {
            if (item['has_visit']) return updateEntry(item.id, source, target)
        }

        function exitVisit(item, source) {
            return updateEntry(item.id, source, "ex")
        }

        // planning
        function handlePlanning(planning, ev) {
            const deferred = $q.defer();
            const isEdit = _.has(planning, 'id');
            const url = `/api/planning/${isEdit ? planning.id + '/' : ''}`;

            const confirm = $mdDialog.confirm()
                .multiple(true)
                .targetEvent(ev)
                .ariaLabel('remove plan confirm')
                .ok($translate.instant('confirm_ok'))
                .cancel($translate.instant('confirm_cancel'))
                .title($translate.instant('planning_edit_confirm_title'))
                .textContent($translate.instant('planning_edit_confirm_content'));

            if (isEdit) $mdDialog.show(confirm).then(post, deferred.reject);
            else post();

            function post() {
                $http.post(url, planning)
                    .then(success, deferred.reject);
            }

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;

        }

        function getPlanning(planning_id) {
            const deferred = $q.defer();
            const url = `/api/planning/${planning_id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getPlanningList(patient) {
            const deferred = $q.defer();
            const url = `/api/planning-list/?patient=${patient}`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getPlanningListItem(planning) {
            const deferred = $q.defer();
            const url = `/api/planning-list/${planning.id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removePlanning(planning) {
            const url = `/api/planning-list/${planning.id}/`;
            return $http.delete(url);
        }

        function editPlanning(planning, ev) {
            return $mdDialog.show(_.assign({}, PLANNING_DIALOG, {
                targetEvent: ev,
                locals: {
                    currentPlanningId: planning.id
                }
            }));
        }

        function duplicatePlanning(planning, ev) {
            return $mdDialog.show(_.assign({}, PLANNING_DIALOG, {
                targetEvent: ev,
                locals: {
                    currentPlanningId: planning.id,
                    copy: true
                }
            }));
        }


        //
        function getPhysician() {
            let authService = $injector.get("authService");

            if (authService.staff && authService.staff.is_physician) return authService.staff;
            else return _.get(configService.defaultValues, "physician", null);
        }
    }

})();
