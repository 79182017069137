/**
 * Created by Amine on 25/10/2021
 */
(function () {

    'use strict';

    class FormLinksCtrl {
        constructor(formsService, $state) {
            this.formsService = formsService;
            this.state = $state;
            this.formTemplates = [];
        }

        static get $inject() {
            return ["formsService", "$state"];
        }

        $onInit() {
            this.formsService.getFormsTemplates()
                .then(data => {
                    this.formTemplates = data;
                });

        }

        selectForm(template) {
            this.state.go("app.visit.forms.detail", {template}, {inherit: true}).then(this.state.reload);
        }
    }

    module.exports = {
        bindings: {},
        template: require("forms/views/form-links.tpl.html"),
        controllerAs: "vm",
        controller: FormLinksCtrl,
    };

})();
