(function () {

    'use strict';

    const STATES_CONFIG = require('../../json/states-config.json');

    class GenericStatesCtrl {
        constructor(genericStatesService, authService, $scope, $translate) {
            this.genericStatesService = genericStatesService;
            this.translate = $translate;
            this.scope = $scope;

            this.isRoot = authService.isRootProfile();
            this.allStates = this.states = [];
        }

        static get $inject() {
            return ["genericStatesService", "authService", "$scope", "$translate"];
        }

        $onInit() {
            this.allStates = _.filter(STATES_CONFIG, item => !_.has(item, "subcategory"));

            this.genericStatesService.load()
                .then(() => {
                    this.statesConfig = this.genericStatesService.$config.getValue();
                    this.scope.$applyAsync(() => {
                        this.states = _.cloneDeep(this.allStates);

                        if (_.isFunction(this.filterMenu)) this.filterMenu();
                    });
                })
        }

        showItem(item) {
            if (this.isRoot) {
                item.deactivated = _.get(this.statesConfig, item.name, false);

                return true;
            }

            return this.statesConfig ? !_.get(this.statesConfig, item.name, false) : true;
        }

        filterMenu() {
            if (_.size(this.key) === 0) {
                this.states = _.cloneDeep(this.allStates);
            } else {
                let key = _.chain(this.key).toLower().deburr().value();

                this.states = _.reduce(this.allStates, (result, item) => {
                    let label = _.chain(this.translate.instant(item.label)).toLower().deburr().value();

                    if (_.includes(label, key)) return _.concat(result, item);
                    else return result
                }, []);
            }
        }

        clearKey() {
            this.key = '';
            this.states = _.cloneDeep(this.allStates);
        }
    }

    module.exports = GenericStatesCtrl;
})();
