/**
 * Created by BETALOS on 24/09/2016.
 */
(function () {

    'use strict';

    const {APPOINTMENT, DELETE} = require('stand-alone/calendar/utils/consts');

    class SearchDialogCtrl {
        constructor($mdDialog, patientService, frontDeskService, groupAppointmentService, system, $translate, mnWebSocket, $q, $timeout, moment, externalConnectionService) {
            this.q = $q;
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.mnWebSocket = mnWebSocket;
            this.patientService = patientService;
            this.frontDeskService = frontDeskService;
            this.groupAppointmentService = groupAppointmentService;
            this.externalConnectionService = externalConnectionService;

            this.searchNotStarted = true;
            this.initialAppointments = null;
            this.dateFormat = system['date_format'].js;

            this.query = {page: 1, limit: 5};
            this.search = {
                deleted: false,
                has_video_call: false,
                start: moment().format(this.dateFormat),
                end: moment().format(this.dateFormat),
            };

            this.selectedAppointments = [];
            this.selectedGroupAppointments = [];

            this.paginationLabel = {
                of: $translate.instant('of'),
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
            };

            const nEqual = {"$ne": true};
            this.filter = {is_deleted: nEqual, is_archived: nEqual};
            this.printSearch = () => this.printSearchCall();
        }

        static get $inject() {
            return [
                "$mdDialog", "patientService", "frontDeskService", "groupAppointmentService", "system", "$translate",
                "mnWebSocket", "$q", "$timeout", "moment", "externalConnectionService"
            ];
        }

        $onInit() {
            this.removePatient = () => this.removePatientFunc();
            this.patientCallback = (patient) => this.patientCallbackFunc(patient);

            this.onPaginate = (page, limit) => this.onPaginateFunc(page, limit);
            this.onGroupPaginate = (page, limit) => this.onPaginateFunc(page, limit, true);
        }

        cancel() {
            this.dialog.cancel();
        }

        startSearch() {
            const query = this.getSearchQuery();

            this.promise = this.q.all([
                this.frontDeskService.searchAppointment(query),
                this.frontDeskService.searchAppointment(_.assign({}, query, {isGroup: true}))
            ]).then(data => {
                this.searchNotStarted = false;
                this.initialAppointments = data[0];
                this.initialGroupAppointments = data[1];

                this.size = this.initialAppointments.length;
                this.appointments = this.filterFunc(this.query);
                this.groupAppointments = this.filterFunc(this.query, true);
                this.selectAppointments();
            });
        }

        resetSearch() {
            this.searchNotStarted = true;
            this.initialAppointments = null;
        }

        patientCallbackFunc(patient) {
            this.patientService.getMinimalPatient(patient.id)
                .then(data => this.search.patient = data);
        }

        removePatientFunc() {
            this.search.patient = null;
        }

        getSearchQuery() {
            let search = _.clone(this.search);

            if (search.reason) search.reason = _.map(search.reason, 'id');
            if (search.agenda) search.agenda = _.map(search.agenda, 'id');
            if (search.physician) search.physician = _.map(search.physician, 'id');
            if (search.patient) search.patient = _.get(search.patient, 'id');

            return search;
        }

        edit(appointment, ev) {
            switch (appointment.event_type) {
                case "rdv":
                    return this.frontDeskService.editAppointment(appointment, ev);
                case "group-rdv":
                    return this.groupAppointmentService.updateAppointment(ev, null, appointment.id);
            }
        }

        printSearchCall() {
            const deferred = this.q.defer();

            setTimeout(() => {
                deferred.resolve({
                    "ids": this.selectedAppointments,
                    "group_ids": this.selectedGroupAppointments
                });
            }, 300);

            return deferred.promise;
        }

        sendSMS($event) {
            this.externalConnectionService
                .showSMSDialog($event, "send_appointment_sms", {
                    "ids": this.selectedAppointments,
                    "group_ids": this.selectedGroupAppointments
                });
        }

        clearInterval() {
            this.search.end = null;
            this.search.start = null;
        }

        filterFunc(query, isGroup = false) {
            const initialLength = isGroup ? this.initialAppointments.length : this.initialGroupAppointments.length

            let offset = (query["page"] - 1) * query["limit"];

            let start = offset > initialLength ? 0 : offset;
            let end = start + query["limit"];

            return _.slice(isGroup ? this.initialGroupAppointments : this.initialAppointments, start, end);
        }

        onPaginateFunc(page, limit, isGroup) {
            this.appointments = this.filterFunc({page: page, limit: limit}, isGroup);
        }

        remove(appointment) {
            this.promise = this.frontDeskService.removeAppointment(appointment.id)
                .then(() => {
                    _.remove(this.initialAppointments, {id: appointment.id});

                    this.size = this.initialAppointments.length;
                    this.appointments = this.filterFunc(this.query);
                    this.mnWebSocket.pub("frontdesk.Calendar.notify", {
                            cmd: DELETE,
                            type: APPOINTMENT,
                            event: appointment.id,
                            event_type: appointment.event_type
                        },
                        false
                    );
                });
        }

        selectAppointments(select = true) {
            if (select) {
                this.selectedAppointments = _.map(this.initialAppointments, 'id');
                this.selectedGroupAppointments = _.map(this.initialGroupAppointments, 'id');
            } else {
                this.selectedAppointments = [];
                this.selectedGroupAppointments = [];
            }
        }
    }

    module.exports = {
        locals: {},
        controllerAs: "vm",
        clickOutsideToClose: false,
        controller: SearchDialogCtrl,
        template: require("../views/appointment-search.tpl.html"),
    };

})();
