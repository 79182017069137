(function () {
    "use strict";

    var breakDialog = {
        controller: breakDialogCtrl,
        controllerAs: "vm",
        template: require("oximetry/views/break-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
    };

    module.exports = breakDialog;

     breakDialogCtrl.$inject = ["$mdDialog", "$scope", "locationService", "warehouseService"];

    function breakDialogCtrl($mdDialog, $scope, locationService, warehouseService) {
        var vm = this;
        vm.$onInit=init;
        vm.shortBreak = shortBreak;
        vm.longBreak = longBreak;

        function init() {

        }
        function shortBreak() {
            vm.break.type='short';
            $mdDialog.hide(vm.break);
        }
        function longBreak() {
            vm.break.type='long';
            $mdDialog.hide(vm.break);
        }
    }


})();