/**
 * Created by amine on 24/11/2020.
 */

(function () {
    'use strict';

    class MedicalPlanProceduresCtrl {
        constructor(medicalCareService, configService) {
            this.plan = this.plan || null;
            this.medicalCareService = medicalCareService;

            this.advanceForm = configService.hasAdvanceVisitFinancial();
            this.hideTablePrices = false;
        }

        static get $inject() {
            return ["medicalCareService", "configService"];
        }

        $onInit() {
            if (!_.has(this.plan, "procedures")) this.medicalCareService.get(this.plan.id || this.plan, 'plan')
                .then(plan => {
                    this.plan = plan;
                });

        }
    }

    module.exports = {
        controller: MedicalPlanProceduresCtrl,
        controllerAs: "vm",
        bindings: {
            plan: "<plan"
        },
        template: require('medical-care/views/medical-plan-procedures.tpl.html'),
    };
})();
