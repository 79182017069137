(function () {
    'use strict';

    const {BehaviorSubject} = require("rxjs");

    class AgingBalanceCtrl {
        constructor(genericStatesService, system, $translate) {
            this.genericStatesService = genericStatesService;
            this.system = system;
            this.translate = $translate;
            this.dateFormat = system['date_format'].js;

            this.currentState = genericStatesService.getCurrentState("aging_balance");

            this.showFilter = false;
            this.isChart = false;
            this.headers = {
                _id: {
                    type: "number",
                    caption: this.translate.instant('unique_id')
                },
                number: {
                    type: "string",
                    caption: this.translate.instant("invoice_number")
                },
                b_15: {
                    type: "number",
                    caption: this.translate.instant('p_15')
                },
                b_30: {
                    type: "number",
                    caption: this.translate.instant('p_30')
                },
                b_60: {
                    type: "number",
                    caption: this.translate.instant('p_60')
                },
                b_120: {
                    type: "number",
                    caption: this.translate.instant('p_120')
                }
            };
            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {},
                onlyData: false
            });
        }

        static get $inject() {
            return ["genericStatesService", "system", "$translate", "$scope"];
        }

        $onInit() {
            this.getData();
        }

        getSlice() {
            return {
                "expands": {
                    "expandAll": true,
                    "rows": [
                        {"tuple": []}
                    ]
                },
                "drills": {
                    "drillAll": false
                },
                "rows": [

                    {
                        "uniqueName": "number",
                    }

                ],
                "columns": [
                    {
                        "uniqueName": "Measures"
                    }
                ],
                "measures": [
                    {
                        "uniqueName": "b_15",
                        "individual": true,
                        "format": "currency",
                        "formula": "sum(\"b_15\")",
                    },
                    {
                        "uniqueName": "b_30",
                        "individual": true,
                        "format": "currency",
                        "formula": "sum(\"b_30\")",
                    },
                    {
                        "uniqueName": "b_60",
                        "individual": true,
                        "format": "currency",
                        "formula": "sum(\"b_60\")",
                    },
                    {
                        "uniqueName": "b_120",
                        "individual": true,
                        "format": "currency",
                        "formula": "sum(\"b_120\")",
                    }
                ],
            };
        }

        getData(onlyData = false) {
            this.promise = this.genericStatesService.getAgingBalance()
                .then(data => {
                    if (onlyData) {
                        this.$dataSource.next({
                            onlyData: true,
                            data: data
                        });
                    } else {
                        this.$dataSource.next({
                            slice: this.getSlice(),
                            data: data,
                            headers: this.headers
                        });
                    }
                });
        }

        handleFileName() {
            return `${this.translate.instant('aging_balance')}`;
        }

        onComplete(instance) {
        }
    }

    module.exports = AgingBalanceCtrl;
})();