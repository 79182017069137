/* edited by amine 21/04/2022 */
(function () {

    'use strict';

    class MoveModelDialogCtrl {
        constructor($mdDialog, examService, $state) {
            this.dialog = $mdDialog;
            this.examService = examService;
            this.state = $state;

            this.promise = null;
            this.model = null;
        }

        static get $inject() {
            return ["$mdDialog", "examService", "$state"];
        }

        $onInit() {
            if (!this.model) this.dialog.cancel();

            this.promise = this.examService.getExamTemplates()
                .then(data => {
                    this.items = data;
                    this.query = {id: this.model.id};
                });
        }

        submit() {
            this.examService.saveExamModel(this.model)
                .then(() => {
                    if (this.state.includes("app.visit.exam")) {
                        this.examService.editModelState(this.model);
                    } else {
                        this.examService.formModelState(this.model.template, null, false, true);
                    }

                    this.dialog.hide(true);
                });
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: MoveModelDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./move-model-dialog.tpl.html"),
    };

})();