/**
 * Created by BETALOS on 17/05/2017.
 */
(function () {

    'use strict';
    const COLOR_PICKER_PARAMS = require('parameters/json/color-picker-config.json');


    class DentalProcedureGroupDialogCtrl {
        constructor($mdDialog, dentalService, $q, dragulaService, $scope, $translate) {
            this.$q = $q;
            this.dialog = $mdDialog;
            this.dentalService = dentalService;
            this.options = _.assign({}, COLOR_PICKER_PARAMS, {label: $translate.instant('reason_dialog_color')});


            dragulaService.options($scope, "item-bag", {
                revertOnSpill: false,
                moves: (el, container, handle) => $(handle).is('.drag-handle.mdi-drag')
            });
        }

        static get $inject() {
            return ["$mdDialog", "dentalService", "$q", "dragulaService", "$scope", "$translate"];
        }

        $onInit() {
            this.promise = this.$q.all([
                this.dentalService.getDentalProcedureGroupDetail(this.item),
                this.dentalService.getDentalProcedureTable()
            ]).then((data) => {
                this.group = data[0];
                this.procedures = data[1];
            });
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.dentalService.handleProcedureGroups(this.group)
                .then((data) => {
                    this.promise = this.dentalService.getDentalProcedureGroupsTableItem(data.id)
                        .then(this.dialog.hide);
                });
        }

        addToList(item) {
            this.group['dental_procedures'] = _.pushOrUpdate(this.group['dental_procedures'], item);
        }

        removeFromList(index) {
            this.group['dental_procedures'].splice(index, 1);
        }
    }


    module.exports = {
        locals: {},
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: DentalProcedureGroupDialogCtrl,
        template: require("dental-consultation/views/dental-procedure-group-dialog.tpl.html"),
    };

})();