(function () {

    'use strict';


    class FormBlockCtrl {
        constructor(measureService) {
            this.measureService = measureService;

            this.block = null;
            this.measurements = [];
            this.formTemplate = {};
        }

        static get $inject() {
            return ["measureService"];
        }

        $onInit() {
            this.patientId = this.patientId || null;
            setTimeout(() => {
                this.measureService.getMeasurementResume({patient_id: this.patientId}).then(data => {
                    this.measurements = _.get(data, 'measurements');
                })
            }, 100);

            this.onChange = this.onChange || _.noop;
        }

        loadMeasurementValue(f) {
            f.value = _.get(this.measurements, `${f.options.measurement.id}.value`);
            this.onChange();
        }

        checkChoice(f, o) {
            return _.includes(f.value, o);
        }

        addChoice(f, o, idx) {
            if (_.includes(f.value, o)) {
                f.value.splice(idx, 1)
            } else {
                f.value = f.value || [];
                f.value.push(o);
            }

            this.onChange();
        }

        hasFormulas() {
            return _.find(this.block.fields, (f) => {
                return !_.isNil(f.options.formula)
            });
        }

        calculateFormula(fd) {
            let formula = fd.options.formula;

            if (!!formula) {
                formula = _.reduce(this.formTemplate.blocks, (s1, b) => {
                    return _.reduce(b.fields, (s2, f) => {
                        return _.replace(s2, new RegExp(`\{${b.name}.${f.name}\}`, 'g'), f.value);
                    }, s1);
                }, formula);

                try {
                    fd.value = eval(formula);
                    this.onChange();
                } catch (error) {
                    fd.value = null;
                    console.warn(error);
                }

                this.onChange();
            }
        }

        calculateBlocFormula() {
            if (!_.isEmpty(this.block.fields)) {
                _.forEach(this.block.fields, this.calculateFormula.bind(this));

                this.onChange();
            }
        }
    }


    module.exports = {
        template: require("../views/form-block.tpl.html"),
        bindings: {
            block: "=formBlock",
            formTemplate: "=template",
            onChange: "&?"
        },
        controllerAs: "vm",
        controller: FormBlockCtrl
    }
})();