(function () {

    'use strict';

    class paymentCalculateDialogCtrl {
        constructor($mdDialog, $translate, system) {
            this.system = system;
            this.$mdDialog = $mdDialog;
            this.$translate = $translate;
        }

        static get $inject() {
            return ["$mdDialog", "$translate", "system"];
        }

        $onInit() {
            this.calculateConfig = {value: 100, type: 'percent'};
        }

        submit() {
            this.$mdDialog.hide(this.calculateConfig);
        }
    }

    module.exports = {
        template: require("../views/payment-calculate-dialog.tpl.html"),
        controllerAs: "vm",
        controller: paymentCalculateDialogCtrl,
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true
    }
})();