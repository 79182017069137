/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    class HospitalizationService {
        constructor($q, $http, configService) {
            this.q = $q;
            this.http = $http;
            this.configService = configService;
        }

        static get $inject() {
            return ['$q', '$http', 'configService'];
        }

        // clinic location related
        getFloors() {
            const deferred = this.q.defer();
            const url = `/api/hospitalization/floor/`;

            this.http.get(url)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        getRooms() {
            const deferred = this.q.defer();
            const url = `/api/hospitalization/clinic-room/`;

            this.http.get(url)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        getBeds() {
            const deferred = this.q.defer();
            const url = `/api/hospitalization/bed/`;

            this.http.get(url)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        handleLocation(type, item) {
            const deferred = this.q.defer();
            const url = `/api/hospitalization/${type}/${item.id ? item.id + '/' : ''}`;

            this.http.post(url, item)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        removeLocation(type, item) {
            const deferred = this.q.defer();
            const url = `/api/hospitalization/${type}/${item.id ? item.id + '/' : ''}`;

            this.http.delete(url)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        // procedure related
        getProcedures() {
            const deferred = this.q.defer();
            const url = '/api/hospitalization/procedure/';

            this.q.all([this.http.get(url), this.configService.get("hospitalization_procedure_sort", true)])
                .then(response => {
                    const data = _.isNil(response[1]) ? response[0].data : _.orderBy(
                        response[0].data, item => _.get(response, `[1].${item.id}`)
                    );

                    deferred.resolve(data);
                }, deferred.reject);

            return deferred.promise;
        }

        removeProcedures(procedure) {
            const url = `/api/hospitalization/procedure/${procedure.id}/`;
            return this.http.delete(url);
        }

        // intervention related
        getInterventionReasons() {
            const deferred = this.q.defer();
            const url = `/api/hospitalization/intervention-reason/`;

            this.http.get(url)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        handleInterventionReason(reason) {
            const deferred = this.q.defer();
            const url = `/api/hospitalization/intervention-reason/${reason.id ? reason.id + '/' : ''}`;

            this.http.post(url, reason)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        removeInterventionReason(reason) {
            const url = `/api/hospitalization/intervention-reason/${reason.id}/`;
            return this.http.delete(url);
        }

        // hospitalization reasons
        getHospitalizationReasons() {
            const deferred = this.q.defer();
            const url = `/api/hospitalization/hospitalization-reason/`;

            this.http.get(url)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        handleHospitalizationReason(reason) {
            const deferred = this.q.defer();
            const url = `/api/hospitalization/hospitalization-reason/${reason.id ? reason.id + '/' : ''}`;

            this.http.post(url, reason)
                .then((response) => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        removeHospitalizationReason(reason) {
            const url = `/api/hospitalization/hospitalization-reason/${reason.id}/`;
            return this.http.delete(url);
        }
    }

    module.exports = HospitalizationService;

})();
