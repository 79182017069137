/**
 * Created by amine on 10/11/2021.
 */
(function () {
    'use strict';

    class GeneralSetupDialog {
        constructor(configService, genericStatesService, $mdDialog) {
            this.configService = configService;
            this.genericStatesService = genericStatesService;
            this.dialog = $mdDialog;

            this.config = null;
        }

        static get $inject() {
            return ["configService", "genericStatesService", "$mdDialog"];
        }

        $onInit() {
            this.genericStatesService.load()
                .then(() => {
                    this.config = this.genericStatesService.$config.getValue();
                });
        }

        submit() {
            this.config.flexmonster_license_key = !this.config.flexmonster_license_key || !this.config.flexmonster_license_key.length ? null : this.config.flexmonster_license_key.trim().replaceAll("\n", "")
            this.configService.set({"states_config": this.config})
                .then(() => {
                    this.genericStatesService.$config.next(this.config);
                    this.dialog.hide(true);
                });
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: GeneralSetupDialog,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        escapeToClose: false,
        template: require("./general-setup.tpl.html"),
    };

})();

