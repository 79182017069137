/**
 * Created by amine on 28/07/2021.
 */
(function () {

    'use strict';

    const GROUP_APPOINTMENT_DIALOG = require('../dialogs/group-appointment-dialog');
    const GROUP_APPOINTMENT_ENTRY_DIALOG = require('../dialogs/group-appointment-entry-dialog');

    const {UPDATE, APPOINTMENT} = require('stand-alone/calendar/utils/consts');

    class GroupAppointmentService {
        constructor($q, mnWebSocket, $http, $mdDialog, frontDeskService, configService, practiceService, appointmentFormService, system) {
            this.q = $q;
            this.http = $http;
            this.ws = mnWebSocket;
            this.dialog = $mdDialog;
            this.frontDeskService = frontDeskService;
            this.configService = configService;
            this.practiceService = practiceService;
            this.appointmentFormService = appointmentFormService;

            this.naiveFormat = system.date_format.naive;

            this.baseURL = "/api/group-appointment/";
        }

        static get $inject() {
            return ["$q", "mnWebSocket", "$http", "$mdDialog", "frontDeskService", "configService", "practiceService", "appointmentFormService", "system"];
        }

        getAppointment(appointment) {
            const deferred = this.q.defer();

            this.http.get(this.#handleUrl(!!appointment.id ? appointment : {id: appointment}))
                .then(response => deferred.resolve(response.data), () => deferred.reject());

            return deferred.promise;
        }

        handleAppointment(appointment) {
            const deferred = this.q.defer();

            if (appointment['is_waiting_list']) {
                appointment = _.assign({}, appointment, {
                    'date': null,
                    'start_time': null,
                    'end_time': null,
                    'ignore': true
                });
            }

            this.http
                .post(this.#handleUrl(appointment), appointment)
                .then(response => deferred.resolve(response.data), err => deferred.reject(err));

            return deferred.promise;
        }

        partialUpdateAppointment(appointment, data) {
            const deferred = this.q.defer();

            this.http.put(this.#handleUrl(appointment), data)
                .then(response => deferred.resolve(response.data), () => deferred.reject());

            return deferred.promise
        }

        updateAppointment(ev = null, appointment = null, appointmentId = null) {
            return this.dialog
                .show(
                    _.assign(
                        {},
                        GROUP_APPOINTMENT_DIALOG, {
                            targetEvent: ev,
                            locals: {appointment, appointmentId}
                        }
                    )
                );
        }

        removeAppointment(appointment) {
            const deferred = this.q.defer();

            this.http.delete(this.#handleUrl(_.isNumber(appointment) ? {id: appointment} : appointment))
                .then(() => deferred.resolve({
                    id: !!appointment.id ? appointment.id : appointment, is_delete: true
                }), () => deferred.reject());

            return deferred.promise;
        }

        createEntries(appointment, ev, stat, room, locals = {}) {
            const deferred = this.q.defer();

            this.dialog
                .show(
                    _.assign(
                        {},
                        GROUP_APPOINTMENT_ENTRY_DIALOG, {
                            targetEvent: ev,
                            locals: _.assign({
                                appointmentId: !!appointment.id ? appointment.id : appointment
                            }, locals)
                        }
                    )
                )
                .then(data => {
                    const {appointment, patients} = data;

                    const promises = _.map(patients, patient => {
                        const checkDeferred = this.q.defer();

                        this.frontDeskService
                            .checkPatientEntry(patient)
                            .then(() => checkDeferred.resolve(patient), () => checkDeferred.resolve(null))

                        return checkDeferred.promise
                    });

                    this.q.all(promises).then(data => {
                        this.#saveEntries(data, {appointment, patients, stat, room})
                            .then(() => deferred.resolve())
                    });
                }, _.noop);
            return deferred.promise
        }

        appointmentToEntry(appointment, patient, stat, room) {
            const wr = _.get(this.configService.defaultValues, "entry.waiting_room", null);
            const cr = _.get(this.configService.defaultValues, "entry.consultation_room", null);

            stat = _.toUpper(stat);
            const entry = _.assign({
                patient: {id: patient},
                stat,
                consultation_room: cr,
                waiting_room: room || wr,
                group_appointment: _.pick(appointment, ['id', 'patients_entered']),
                entry_time: moment().format(this.naiveFormat),
            }, _.pick(appointment, ['physician', 'reason', 'operator', 'other_comment']));

            if (stat === 'SV') entry['visit_start_time'] = moment().format(this.naiveFormat);

            return entry;
        }

        handleEntry(entry, noValidation = false) {
            const deferred = this.q.defer();
            const url = `/api/entry/${entry.id ? entry.id + "/" : ''}`;

            const appointment = entry.has_group_appointment ? {id: entry.group_appointment_id} : entry.group_appointment;

            this.q.all([
                this.http.post(url, entry),
                appointment && !noValidation ? this.validateAppointment(appointment, true, entry) : this.q.when(null)
            ])
                .then(response => {
                    deferred.resolve({
                        entry: response[0].data,
                        appointment: response[1]
                    });
                }, deferred.reject);

            return deferred.promise
        }

        validateAppointment(appointment, is_entered, entry) {
            const deferred = this.q.defer();
            const data = _.assign(
                {
                    is_entered
                },
                entry.reason ? {reason: entry.reason} : {},
                appointment.patients_entered ? {patients_entered: _.concat(appointment.patients_entered, [entry.patient.id])} : {}
            )

            this.http.put(this.#handleUrl(appointment), data)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        #validateAppointmentOnce(appointment, patients) {
            const deferred = this.q.defer();
            const data = {
                is_entered: true,
                patients_entered: _.concat(appointment.patients_entered, patients)
            }

            this.http.put(this.#handleUrl(appointment), data)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        #saveEntries(promisesData, opts) {
            const {appointment, patients, stat, room} = opts;
            const entryPromises = _.chain(promisesData)
                .compact()
                .map(patient => this.appointmentToEntry(appointment, patient, stat, room))
                .map(entry => this.handleEntry(entry, true))
                .concat([this.#validateAppointmentOnce(appointment, patients)])
                .value();

            return this.q
                .all(entryPromises)
                .then(entries => {
                    const editedAppointment = entries.splice(-1, 1)[0];

                    entries.forEach(entryData => {
                        const {entry} = entryData;
                        this.practiceService.entryAdded(entry);
                        this.ws.pub("frontdesk.Practice.entry_added", entry);
                    });

                    this.appointmentFormService.notifyCalendar(UPDATE, APPOINTMENT, editedAppointment);
                });
        }

        #handleUrl(data = null) {
            return `${this.baseURL}/${data && data.id ? data.id + '/' : ''}`.replace(new RegExp('//', 'g'), '/');
        }
    }

    module.exports = GroupAppointmentService;
})();
