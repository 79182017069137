(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");

    class CheckChecksCtrl {
        constructor(genericStatesService, system, $translate, moment, $state) {
            this.genericStatesService = genericStatesService;
            this.system = system;
            this.translate = $translate;
            this.dateFormat = system['date_format'].js;
            this.$state = $state;
            this.currentState = genericStatesService.getCurrentState("check_checks");

            this.showFilter = true;
            this.isChart = false;
            this.headers = {

                due_date: {
                type: "string",
                    caption: this.translate.instant("due_date")
            }  ,
                number: {
                    type: "string",
                    caption: this.translate.instant("payment_number")
                } ,
                payer_name: {
                    type: "string",
                    caption: this.translate.instant("payer_name")
                } ,
                ref: {
                    type: "string",
                    caption: this.translate.instant("payment_ref")
                } ,
                bank: {
                    type: "string",
                    caption: this.translate.instant("bank")
                },
                received_amount: {
                    type: "number",
                    caption: this.translate.instant("payment_received_amount")
                }
            };
            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {},
                onlyData: false
            });
        }

        static get $inject() {
            return ["genericStatesService", "system", "$translate", "moment", "$state"];
        }

        $onInit() {
            this.query = {
                start: moment().startOf('month').format(this.dateFormat),
                end: moment().format(this.dateFormat),
                filter_type: 'check'
            };
            this.getData();
        }


        getSlice() {
            return {
                "expands": {
                    "expandAll": false,
                    "rows": [
                        {"tuple": []}
                    ]
                },
                "drills": {
                    "drillAll": false
                },
                "rows": [
                    {
                        "uniqueName": "due_date"
                    },
                    {
                        "uniqueName": "payer_name"
                    },
                    {
                        "uniqueName": "number"
                    },
                    {
                        "uniqueName": "ref",
                    }

                ],
                "columns": [
                    {
                        "uniqueName": "bank"
                    },
                    {
                        "uniqueName": "Measures"
                    }
                ],
                "measures": [
                    {
                        "uniqueName": "ref",
                        "caption": this.translate.instant("ref_sum"),
                        "individual": true,
                        "format": "integer",
                        "formula": "count(\"ref\")",
                    },
                    {
                        "uniqueName": "received_amount",
                        "format": "currency",
                        "formula": "sum(\"received_amount\")",
                        "grandTotalCaption": this.translate.instant("received_amount_sum")
                    }
                ],
            };
        }

        getData(onlyData = false) {
            this.promise = this.genericStatesService.getChecks(this.query)
                .then(data => {
                    if (onlyData) {
                        this.$dataSource.next({
                            onlyData: true,
                            data: data
                        });
                    } else {
                        this.$dataSource.next({
                            slice: this.getSlice(),
                            data: data,
                            headers: this.headers
                        });
                    }
                });
        }

        handleFileName() {
            return `${this.translate.instant('check_checks')}`;
        }

        onComplete(instance) {
        }

        queryChanged() {
            const start = moment(this.query.start, this.dateFormat);
            const end = moment(this.query.end, this.dateFormat);

            if (start.isAfter(end)) {
                this.query.end = start.add(3, "months").endOf('month').format(this.dateFormat);
            }

            this.$state.transitionTo(
                this.$state.current.name,
                this.query,
                {notify: false}
            ).then(() => this.getData());
        }

    }

    module.exports = CheckChecksCtrl;
})();