/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    const AGENDA_TPL = require('frontdesk/views/agenda.html');
    const PATIENT_TPL = require('patient/views/patient-list.html');

    const ENTRY_DIALOG = require('../dialogs/entry-dialog');
    const PLANNING_DIALOG = require('../dialogs/planning-dialog');
    const APPOINTMENT_DIALOG = require('../dialogs/appointment-dialog');
    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');
    const BACKGROUND_EVENT_DIALOG = require('../dialogs/background-event-dialog');
    const APPOINTMENT_SEARCH_DIALOG = require('../dialogs/appointment-search-dialog');

    class AgendaCtrl {
        constructor(
            $mdDialog, $element, $compile, $scope, configService, patientService, frontDeskService, practiceService,
            visitService, careSheetService, groupAppointmentService
        ) {

            this.$scope = $scope;
            this.dialog = $mdDialog;
            this.$element = $element;
            this.$compile = $compile;

            this.configService = configService;
            this.patientService = patientService;
            this.practiceService = practiceService;
            this.frontDeskService = frontDeskService;

            this.cancel = this.dialog.cancel;

            this.filter = {};
            this.filterEvents = list => {
                this.filter = _.assign({}, this.filter, list);
                this.$scope.$applyAsync();
            };

            this.handleAppointment = (obj, event, jsEv) => {
                this.dialog.show(_.assign({}, APPOINTMENT_DIALOG, {
                    targetEvent: jsEv,
                    multiple: this.isDialog,
                    locals: {
                        event: event,
                        patient: null,
                        hasPause: true,
                        timeRange: obj,
                    }
                }));
            };

            this.handleGroupAppointment = (appointment, appointmentId, jsEvent) => {
                groupAppointmentService.updateAppointment(jsEvent, appointment, appointmentId);
            }

            visitService.visitSubLinks.subscribe(data => {
                let linkConfig = _.find(VISIT_SUB_LINKS, ['key', data.favorite]);

                this.actions = {
                    multiple: [],
                    single: [
                        {
                            action: 'get',
                            resource: 'patient',
                            behavior: 'disable',
                            label: 'patient_details',
                            icon: 'mdi-card-account-details',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: (item, ev) => this.entryResume(item, ev)
                        },
                        {
                            action: 'update',
                            label: 'edition',
                            icon: 'mdi-pencil',
                            resource: 'patient',
                            behavior: 'disable',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: (item, ev) => patientService.goToEdition(item, ev)
                        },
                        {
                            label: 'wr',
                            action: 'create',
                            resource: 'entry',
                            behavior: 'remove',
                            icon: 'mdi-seat-recline-extra',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: (item, ev) => this.addEntry(item, ev)
                        },
                        _.assign(
                            {
                                action: 'create',
                                behavior: 'remove',
                                resource: 'visit',
                                condition: "!item.is_deleted && !item.is_archived"
                            },
                            {
                                icon: linkConfig.icon,
                                label: linkConfig.tooltip,
                                method: (p, e) => frontDeskService.startShowVisitPatientFile(p, e, linkConfig.link)
                            }
                        ),
                        {
                            action: 'create',
                            behavior: 'remove',
                            label: 'patient_alerts',
                            resource: 'notification',
                            icon: 'mdi-account-alert',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: (item, ev) => patientService.patientAlerts(item, ev)
                        },
                        {
                            action: 'create',
                            resource: 'visit',
                            behavior: 'remove',
                            icon: 'mdi-folder-plus',
                            label: 'patient_care_sheet',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: (item, ev) => careSheetService.patientCareSheet(item, ev)
                        }
                    ]
                }

            });
        }

        static get $inject() {
            return [
                "$mdDialog", "$element", "$compile", "$scope", "configService", "patientService", "frontDeskService",
                "practiceService", "visitService", "careSheetService", "groupAppointmentService"
            ];
        }

        $onInit() {
            this.isDialog = _.isNil(this.isDialog) ? false : this.isDialog;

            if (!this.isDialog) this.configService.getByHttp('frontdesk_patient_list')
                .then(data => {
                    const tpl = data ? PATIENT_TPL : AGENDA_TPL;

                    let compiledDirective = this.$compile(tpl);
                    let directiveElement = compiledDirective(this.$scope);

                    this.$element.html(directiveElement);
                });
        }

        searchEvents(ev) {
            this.dialog.show(_.assign({}, APPOINTMENT_SEARCH_DIALOG, {
                targetEvent: ev,
                multiple: this.isDialog,
                parent: this.isDialog ? $(document.body) : $("md-content", this.$element).get(0),
            }));
        }

        addEvent(ev) {
            this.dialog.show(_.assign({}, BACKGROUND_EVENT_DIALOG, {
                targetEvent: ev,
                multiple: this.isDialog,
                locals: {
                    event: null
                }
            }));
        }

        addPlanning(ev) {
            this.dialog.show(_.assign({}, PLANNING_DIALOG, {
                targetEvent: ev,
                multiple: this.isDialog,
                locals: {
                    sessions_nbr: 1,
                    patient_id: null,
                    currentPlanningId: null
                }
            }));
        }

        // patient list actions
        show(value) {
            this.filter = this.patientService.patientFilter(value);
        }

        addEntry(patient, ev) {
            ev.stopPropagation();

            this.dialog.show(_.assign({}, ENTRY_DIALOG, {
                targetEvent: ev,
                multiple: true,
                locals: {entryTmp: null, patient: patient}
            })).then(data => this.practiceService.entryAdded(data));
        }

        entryResume(item, ev) {
            ev.stopPropagation();

            this.frontDeskService.entryResume({
                onlyResume: true,
                'patient-id': _.get(item, 'id')
            }, ev);
        }
    }

    module.exports = AgendaCtrl;

})();
