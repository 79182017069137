(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");

    class NewPatientsCtrl {
        constructor(genericStatesService, system, $translate, $scope, $state, $stateParams, moment) {
            this.genericStatesService = genericStatesService;
            this.system = system;
            this.translate = $translate;
            this.scope = $scope;
            this.$state = $state;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;

            this.currentState = genericStatesService.getCurrentState("new_patients");

            this.showAdvFilter = true;
            this.showFilter = true;
            this.query = {
                start: $stateParams.start || this.moment().subtract(3, "months").startOf('month').format(this.dateFormat),
                end: $stateParams.end || this.moment().endOf('month').format(this.dateFormat),
            };

            this.headers = {
                _id: {
                    type: "number",
                    caption: this.translate.instant('unique_id')
                },
                physician_fullname: {
                    type: "string",
                    caption: this.translate.instant('daily_workflow.physician')
                },
                fullname: {
                    type: "string",
                    caption: this.translate.instant('dcm_patient_full_name')
                },
                visit: {
                    type: "string",
                    caption: this.translate.instant('states_w_wo_visit')
                },
                visit_date: {
                    type: "date",
                    caption: this.translate.instant('payment_date')
                },
            };
            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {},
                onlyData: false
            });
        }

        static get $inject() {
            return ["genericStatesService", "system", "$translate", "$scope", "$state", "$stateParams", "moment"];
        }

        $onInit() {
            this.maxDate = this.moment().toDate();
            this.getData();
        }


        getSlice() {
            return {
                "expands": {
                    "expandAll": true,
                    "rows": [
                        {"tuple": []}
                    ]
                },
                "drills": {
                    "drillAll": false
                },
                "rows": [
                    {
                        "uniqueName": "physician_fullname"
                    },
                    {
                        "uniqueName": "fullname"
                    }
                ],
                "columns": [
                    {
                        "uniqueName": "visit"
                    },
                    {
                        "uniqueName": "Measures"
                    }
                ],
                "measures": [
                    {
                        "uniqueName": "total",
                        "caption": this.translate.instant("states_w_wo_visit"),
                        "individual": true,
                        "format": "integer",
                        "formula": "count(\"visit\")",
                    }
                ],
            };
        }

        modifyData(data) {
            return _.reduce(data, (result, item) => {
                item.visit = this.translate.instant(`states_new_patients_${item.visit}`);
                return _.concat(result, item);
            }, []);
        }

        getData(onlyData = false) {
            this.promise = this.genericStatesService.getNewPatients(this.query)
                .then(data => {
                    if (onlyData) {
                        this.$dataSource.next({
                            onlyData: true,
                            data: this.modifyData(data)
                        });
                    } else {
                        this.$dataSource.next({
                            slice: this.getSlice(),
                            data: this.modifyData(data),
                            headers: this.headers
                        });
                    }
                });
        }

        handleFileName() {
            const start = moment(this.query.start, this.dateFormat).format(this.genericStatesService.exportDateFormat);
            const end = moment(this.query.end, this.dateFormat).format(this.genericStatesService.exportDateFormat);

            return `${this.translate.instant('states_new_patients_file_name', {start, end})}`;
        }

        queryChanged() {
            const start = moment(this.query.start, this.dateFormat);
            const end = moment(this.query.end, this.dateFormat);

            if (start.isAfter(end)) {
                this.query.end = start.add(3, "months").endOf('month').format(this.dateFormat);
            }

            this.$state.transitionTo(this.$state.current.name, this.query, {
                notify: false
            }).then(() => this.getData(true));
        }

        onComplete(instance) {

        }
    }

    module.exports = NewPatientsCtrl;
})();
