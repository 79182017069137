/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    //@TODO clean fix after version release

    'use strict';

    const CALENDAR_DURATION_DIALOG = require('./calendar-duration-dialog');
    const APPOINTMENT_AVAILABILITY_DIALOG = require('./appointement-availability-dialog');
    const {UPDATE, APPOINTMENT, WAITING_LIST, BACKGROUND, DELETE} = require('stand-alone/calendar/utils/consts');

    AppointmentDialogCtrl.$inject = ["$mdDialog", "system", "mnWebSocket", "patientService", "frontDeskService", "appointmentFormService", "$state", "configService", "$q"];

    function AppointmentDialogCtrl($mdDialog, system, mnWebSocket, patientService, frontDeskService, appointmentFormService, $state, configService, $q) {
        let vm = this;

        const timeFormat = system['time_format'].js;
        const dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.submitPause = submitPause;

        vm.remove = remove;
        vm.createEntry = createEntry;
        vm.cancel = $mdDialog.cancel;

        vm.changeInterval = changeInterval;
        vm.showAvailabilities = showAvailabilities;
        vm.showDurationCalendar = showDurationCalendar;

        vm.query = query;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.handleTitleChange = patientService.handleTitleChange;

        vm.handleWaitingToggle = handleWaitingToggle;
        vm.checkToday = checkToday;

        function init() {
            vm.toPractice = false;
            vm.maxDate = moment().toDate();

            vm.appointment = {
                start_time: moment().minutes(0).format(timeFormat),
                patient: {
                    file_date: moment().format(dateFormat),
                    profession: _.get(configService.defaultValues, "profession", null),
                    contact_info: _.assign({phone_numbers: []}, _.get(configService.defaultValues, "contact_info", null)),
                }
            };

            vm.pause = {};
            vm.is_pause = false;
            vm.is_event_edit = false;

            if (!_.isNil(vm.event)) handleEvent();
            if (!_.isNil(vm.timeRange)) handleTimeRange(vm.timeRange, true);

            if (!_.isNil(vm.patient)) {
                vm.is_event_edit = true;

                if (_.isNumber(vm.patient)) patientCallback({id: vm.patient});
                if (_.isObject(vm.patient)) patientCallback({id: vm.patient.id});
            }

            vm.hasPause = !!vm.hasPause || _.get(vm.event, 'is_pause', false);
            vm.is_pause = _.get(vm.event, 'is_pause', false);
        }

        function submit(is_toPatient, $event) {
            if (_.isUndefined(vm.appointment.patient.id)) return checkExistence();
            else return handleAppointment();

            function checkExistence() {
                let deferred = $q.defer();

                patientService.checkExistence(vm.appointment.patient).then(success, fail);

                function success() {
                    handleAppointment().then(deferred.resolve, deferred.reject);
                }

                function fail(data) {
                    const locals = {'patient-id': data.id};
                    const assign = {clickOutsideToClose: false, escapeToClose: false, multiple: true};

                    frontDeskService.entryResume(locals, $event, assign)
                        .then(usePatient, checkAppointmentHandle);

                    function usePatient() {
                        vm.appointment.patient = data;
                        success();
                    }

                    function checkAppointmentHandle(use) {
                        if (use) success();
                        else deferred.reject();
                    }
                }

                return deferred.promise;
            }

            function handleAppointment() {
                let deferred = $q.defer();

                vm.promise = frontDeskService.handleAppointment(vm.appointment)
                    .then(doneCallbacks, deferred.reject);

                return deferred.promise;

                function doneCallbacks(data) {
                    vm.appointment.id = data.id;

                    if (vm.toPractice) {
                        createEntry(vm.toPractice, data);
                    } else {
                        notifyCalendar(vm.appointment.is_waiting_list ? WAITING_LIST : UPDATE, APPOINTMENT, data.id);
                        $mdDialog.hide(vm.appointment);
                    }

                    if (is_toPatient) $state.go("app.patient-form", {'patient_id': data.patient.id});

                    deferred.resolve(data);
                }
            }
        }

        function submitPause() {
            vm.promise = frontDeskService.handlePause(vm.pause)
                .then(doneCallbacks);

            return vm.promise;

            function doneCallbacks(data) {
                notifyCalendar(UPDATE, BACKGROUND, data.id);
                $mdDialog.cancel();
            }
        }

        function createEntry(stat, appointment) {
            frontDeskService.checkPatientEntry(appointment.patient.id)
                .then(success, error);

            function success() {
                let wr = _.get(configService.defaultValues, "entry.waiting_room", null);
                let entry = frontDeskService.appointmentToEntry(appointment, stat, wr);
                vm.promise = frontDeskService.handleEntry(entry)
                    .then(doneCallback);

                function doneCallback(data) {
                    mnWebSocket.pub("frontdesk.Practice.entry_added", data.entry, false);
                    notifyCalendar(UPDATE, APPOINTMENT, data.id);

                    $mdDialog.cancel();
                }
            }

            function error() {
                notifyCalendar(UPDATE, APPOINTMENT, vm.appointment.id);
                $mdDialog.cancel();
            }
        }

        function remove() {
            vm.promise = frontDeskService.removeAppointment(vm.appointment.id)
                .then(doneCallbacks);

            function doneCallbacks(data) {
                notifyCalendar(DELETE, APPOINTMENT, data.id);
                $mdDialog.hide({deleted: true});
            }
        }

        function handleTimeRange(timeRange, timeout) {
            const assignObject = {
                date: timeRange.start.format(dateFormat),
                start_time: timeRange.start.format(timeFormat),
                end_time: timeRange.end.format(timeFormat)
            };

            vm.pause = _.assign(vm.pause, assignObject);
            vm.appointment = _.assign(vm.appointment, assignObject);

            let agenda = timeRange.agenda;
            let physician = timeRange.physician;

            checkToday();

            if (timeout) setTimeout(setResource, 50);
            else setResource();

            function setResource() {
                if (agenda) vm.appointment.agenda = agenda;
                if (physician) vm.appointment.physician = vm.pause.physician = physician;
            }
        }

        function handleEvent() {
            if (vm.event.is_pause) {
                vm.is_event_edit = true;
                _.unset(vm, 'appointment');
                setTimeout(() =>  vm.pause = transformToPause(vm.event));
                
            } else if (vm.event.id) {
                vm.is_event_edit = true;

                vm.promise = frontDeskService.getAppointment(vm.event.id)
                    .then(data => {
                        vm.is_today = (data.date === moment().format(dateFormat));
                        if (_.has(vm.event, "assignIn")) data = _.assign(data, vm.event.assignIn);
                        vm.appointment = data;
                    });

                _.unset(vm, 'pause');
            } else {
                if (vm.event.patient) vm.is_event_edit = true;
                if (vm.event.is_waiting_list) vm.hasPause = false;

                setTimeout(() => {
                    vm.appointment = _.assign({}, vm.appointment, vm.event);
                    changeInterval();
                }, 100)
            }
        }

        function changeInterval() {
            vm.appointment = appointmentFormService.changeInterval(vm.appointment);
        }

        function showAvailabilities(ev) {
            appointmentFormService
                .showAvailabilities(vm.appointment, ev)
                .then(data => vm.appointment = data, _.noop);
        }

        function showDurationCalendar(ev) {
            // let dialog = _.assign({}, CALENDAR_DURATION_DIALOG, {
            //     targetEvent: ev,
            // });
            //
            // $mdDialog.show(dialog).then(data => {
            //     const duration = moment.duration(data.end.diff(data.start)).asMinutes();
            //     const reasonDuration = _.get(vm.appointment, 'reason.appointment_duration', 20);
            //
            //     if (duration < reasonDuration) data.end = data.start.clone().add(reasonDuration, 'minutes');
            //
            //     handleTimeRange(data, false);
            // });
            appointmentFormService
                .showDurationCalendar(vm.appointment, ev)
                .then(data => {
                    vm.appointment = _.assign({}, vm.appointment, data.assignObject);
                    vm.isToday = data.isToday;
                }, _.noop);
        }

        function query() {
            let nEqual = {"$ne": true};

            return _.chain(vm.appointment.patient).pick(['first_name', 'last_name']).assign({
                is_archived: nEqual,
                is_deleted: nEqual
            }).value();
        }

        function patientCallback(patient) {
            vm.promise = patientService.getMinimalPatient(patient.id)
                .then(success);

            function success(data) {
                vm.appointment.patient = data;
            }
        }

        function removePatient() {
            vm.appointment.patient = {};
        }

        function transformToPause(event) {
            return _.assign(event, {
                id: _.last(event.id.split('-')),
                physician: {id: event.physician_id},
                date: event.startMoment.format(dateFormat),
                start_time: event.startMoment.format(timeFormat),
                end_time: event.endMoment.format(timeFormat),
            });
        }


        function handleWaitingToggle() {
            vm.toPractice = false;

            if (!vm.appointment.is_waiting_list && _.isNil(vm.appointment.start_time)) {
                vm.appointment.start_time = moment().minutes(0).format(timeFormat);
                changeInterval();
            }
        }

        function checkToday() {
            vm.is_today = appointmentFormService.checkToday(vm.appointment);
        }

        // calendar notification
        function notifyCalendar(cmd, type, data) {
            const event = data.id;
            const event_type = data.event_type;

            mnWebSocket.pub("frontdesk.Calendar.notify", {cmd, type, event, event_type}, false);
        }
    }

    module.exports = {
        locals: {},
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: AppointmentDialogCtrl,
        template: require("frontdesk/views/appointment-dialog.tpl.html"),
    };

})();
