/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    const ADMISSION_FORM_DIALOG  = require('../../dialogs/admission-form/admission-form.dialog');

    class ActiveHospitalizationsCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ['$mdDialog'];
        }

        $onInit() {

        }


        // admission related
        handleAdmission(item, ev) {
            this.dialog.show(_.assign({}, ADMISSION_FORM_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(item)
                }
            })).then(data => {
                console.log(data);
            });
        }
    }

    module.exports = ActiveHospitalizationsCtrl;

})();
