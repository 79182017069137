(function () {

    'use strict';

    const COLOR_PICKER_PARAMS = require('parameters/json/color-picker-config.json');

    class HospitalizationReasonDialogCtrl {
        constructor($q, $mdDialog, $translate, hospitalizationService) {
            this.$q = $q;
            this.dialog = $mdDialog;
            this.hospitalizationService = hospitalizationService;

            this.reasons = [];
            this.procedures = [];
            this.options = _.assign(
                {}, COLOR_PICKER_PARAMS, {label: $translate.instant('reason_dialog_color')}
            );
        }

        static get $inject() {
            return ["$q","$mdDialog", "$translate", "hospitalizationService"];
        }

        $onInit() {
            this.model = this.model ? this.model : {procedures: [], interventions: []};

            this.promise = this.$q.all([
                this.hospitalizationService.getProcedures(),
                this.hospitalizationService.getInterventionReasons(),
            ]).then(data => {
                this.procedures = data[0];
                this.reasons = data[1];
            });
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.hospitalizationService.handleHospitalizationReason(this.model)
                .then(data => this.dialog.hide(data));
        }

        // procedures related
        addProcedure(procedure) {
            let existence = _.find(this.visitProcedures, {name: procedure.name, procedure: {id: procedure.id}});

            if (!existence) this.model.procedures.push(this.procedureToVisit(procedure));
            else existence.qte += 1;
        }

        removeProcedure(index) {
            this.model.procedures.splice(index, 1);
        }

        procedureToVisit(procedure) {
            let visitProcedure = _.pick(procedure, ['name', 'price']);
            return _.assign(visitProcedure, {qte: 1, discount: 0, procedure: procedure});
        }

        total() {
            const t = _.reduce(this.model.procedures, (result, item) => {
                return result + item['qte'] * (item['price'] - item['discount']);
            }, 0);

            return _.isNaN(t) ? 0 : t;
        }

        // intervention related
        addIntervention() {
            this.model.interventions.push({});
        }

        removeIntervention(index) {
            this.model.interventions.splice(index, 1);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: HospitalizationReasonDialogCtrl,
        template: require("./hospitalization-reason.dialog.tpl.html"),
    };

})();
