(function () {
    'use strict';

    const angular = require('angular');

    const ActiveHospitalizationsCtrl = require('./controllers/active-hospitalizations/ActiveHospitalizationsCtrl');

    const hospitalizationService = require('./services/hospitalizationService');

    angular
        .module("medinet")

        .controller('ActiveHospitalizationsCtrl', ActiveHospitalizationsCtrl)

        .service('hospitalizationService', hospitalizationService)

})();
