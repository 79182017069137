/**
 * Created by BETALOS on 12/05/2017.
 */
(function () {

    'use strict';

    module.exports = PlanningContainerCtrl;

    PlanningContainerCtrl.$inject = ["frontDeskService", "$transition$", "$state", "$scope", "$q", "patientService", "$mdDialog", "visitService"];

    function PlanningContainerCtrl(frontDeskService, $transition$, $state, $scope, $q, patientService, $mdDialog, visitService) {
        let vm = this;

        let isVisit = _.startsWith($transition$.to()['name'], "app.visit");

        let currentPatient = _.get($transition$.params('to'), isVisit ? 'pId' : 'patient');
        let previousState = _.get($transition$.from(), 'name', null);
        let previousStateParams = $transition$.params('from');

        vm.$onInit = init;
        vm.addPlanning = addPlanning;
        vm.showPlanning = showPlanning;
        vm.removePlanning = removePlanning;

        vm.editPlanning = editPlanning;
        vm.duplicatePlanning = duplicatePlanning;

        vm.goBack = goBack;

        function init() {
            vm.lock = true;
            vm.plannings = [];
            vm.currentEditPlan = -1;

            let promises = [frontDeskService.getPlanningList(currentPatient)]
            if (!isVisit) promises.push(patientService.getMinimalPatient(currentPatient));

            vm.promise = $q.all(promises).then(success);

            function success(data) {
                vm.plannings = data[0];
                if (!isVisit) vm.patient = data[1];
            }

            let planningEditSubscription = frontDeskService.planningEditSubject.subscribe(handleEdit);
            let planningListSubscription = frontDeskService.planningListSubject.subscribe(refreshPlanningList);
            let currentPatientSubscription = visitService.currentPatientSubject.subscribe(handlePatient);

            function handlePatient(data) {
                vm.patient = data;
            }

            function handleEdit(planning) {
                vm.currentEditPlan = planning;
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                planningEditSubscription.unsubscribe();
                planningListSubscription.unsubscribe();
                currentPatientSubscription.unsubscribe();
            }
        }

        function showPlanning(planning) {
            $state.go(isVisit ? 'app.visit.planning.details' : 'app.planning.details', {planning: planning.id})
                .then($state.reload);
        }

        function removePlanning(planning) {
            vm.promise = frontDeskService.removePlanning(planning)
                .then(success);

            function success() {
                _.remove(vm.plannings, _.pick(planning, 'id'));

                if (_.eq(vm.currentEditPlan, planning.id)) {
                    $state.go(isVisit ? 'app.visit.planning' : 'app.planning', {patient: currentPatient});
                }
            }
        }

        function editPlanning(planning, ev) {
            frontDeskService.editPlanning(planning, ev)
                .then(showPlanning);
        }

        function duplicatePlanning(planning, ev) {
            frontDeskService.duplicatePlanning(planning, ev)
                .then(refreshPlanningList);
        }

        function refreshPlanningList(planning) {
            vm.promise = frontDeskService.getPlanningListItem(planning)
                .then(success)

            function success(data) {
                let index = _.findIndex(vm.plannings, _.pick(planning, 'id'))

                if (_.eq(index, -1)) vm.plannings.unshift(data)
                else vm.plannings.splice(index, 1, data);

                showPlanning(data);
            }
        }

        function addPlanning(ev) {
            $mdDialog.show(_.assign(require('frontdesk/dialogs/planning-dialog'), {
                targetEvent: ev,
                locals: {
                    patient_id: currentPatient,
                    sessions_nbr: 1,
                    currentPlanningId: null
                }
            })).then(refreshPlanningList);
        }

        function goBack() {
            if(_.isEmpty(previousState)) window.history.back();
            else $state.go(previousState, previousStateParams);
        }

    }

})();
