/**
 * Created by amine on 23/11/2020.
 */
(function () {

    'use strict';

    class SchedulingDialogCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
            this.patient = this.patient || null;
            this.item = this.item || null;
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: SchedulingDialogCtrl,
        template: require("../views/scheduling-dialog.tpl.html"),
    };

})();
