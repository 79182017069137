(function () {

    'use strict';

    const FIELDS = [
        'id',
        'title',
        'description',
        'creation_date',
        'sessions_nbr',
        'done_procedures',
        'planning_id',
        'patient_id',
        'generated_from',
        'exported',
        'financial_status',
        'created_by',
        'isNew',
    ]

    class TreatmentPlanDialogCtrl {
        constructor($mdDialog, $translate, system, dentalService, moment, $q) {
            this.$q = $q;
            this.dialog = $mdDialog;
            this.dentalService = dentalService;

            let dateFormat = system['date_format'].js;
            this.today = moment().format(dateFormat);

            this.filedLabel = $translate["instant"]('dental_treatment_plan_description');
            this.planTitle = $translate['instant']('dental_module_treatment_plan_default_title', {date: this.today});
        }

        static get $inject() {
            return ["$mdDialog", "$translate", "system", "dentalService", "moment", "$q"];
        }

        $onInit() {
            this.patient = this.patient ? this.patient : null;
            this.quotation = this.quotation ? this.quotation : null;

            this.field = {
                key: "key",
                label: this.filedLabel,
                meta_data: {dict_uid: null},
            }

            let promise = [];
            let emptyPlan = {
                sessions_nbr: 1,
                global_discount: 0,
                title: this.planTitle,
                creation_date: this.today,
                patient: {id: this.patient},
            };

            if (this.quotation) promise.push(
                this.dentalService.getQuotationDetail(this.quotation)
            );
            else if (this.plan) promise.push(this.plan);
            else promise.push(emptyPlan);

            this.promise = this.$q.all(promise)
                .then(data => {
                    if (this.quotation) this.plan = _.assign(
                        {}, emptyPlan,
                        _.pick(data[0], ['global_discount']),
                        {'teeth_procedures': _.filter(data[0]['teeth_procedures'], item => !item.is_refused)},
                        {quotation: {'id': this.quotation.id}}
                    )
                    else this.plan = data[0];
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.dentalService.savePlan(this.plan)
                .then(data => this.dialog.hide(
                    _.pick(data, FIELDS)
                ));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: TreatmentPlanDialogCtrl,
        template: require("../views/treatment-plan-dialog.tpl.html"),
    };

})();
