(function () {

    'use strict';

    class AdmissionFormDialogCtrl {
        constructor($mdDialog, patientService, moment) {
            this.moment = moment;
            this.dialog = $mdDialog;
            this.patientService = patientService;

            this.maxDate = moment().toDate();
            this.query = () => this.queryFunc();
            this.removePatient = () => this.removePatientFunc();
            this.patientCallback = (patient) => this.patientCallbackFunc(patient);
            this.handleTitleChange = (patient) => patientService.handleTitleChange(patient);
        }

        static get $inject() {
            return ["$mdDialog", "patientService", "moment"];
        }

        $onInit() {
            this.admission = {};
        }

        cancel() {
            this.dialog.cancel();
        }

        // patient search model related
        queryFunc() {
            let nEqual = {"$ne": true};

            return _.chain(this.admission.patient).pick(['first_name', 'last_name']).assign({
                is_archived: nEqual,
                is_deleted: nEqual
            }).value();
        }

        patientCallbackFunc(patient) {
            this.promise = this.patientService.getMinimalPatient(patient.id)
                .then(data => this.admission.patient = data);
        }

        removePatientFunc() {
            this.admission.patient = {};
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: AdmissionFormDialogCtrl,
        template: require("./admission-form.dialog.tpl.html"),
    };

})();
