/**
 * Created by Hp on 25/04/2017.
 */
(function () {

    'use strict';

    const {from, of} = require("rxjs");
    const {tap} = require('rxjs/operators');
    const AGENDA_PARAMS = require('parameters/json/agenda-params.json');

    class PlanningDialogCtrl {
        constructor($mdDialog, patientService, $q, system, frontDeskService, $state, $translate) {
            this.q = $q;
            this.state = $state;
            this.dialog = $mdDialog;
            this.patientService = patientService;
            this.frontDeskService = frontDeskService;

            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;

            const currentStateName = $state.$current['name'];
            this.isVisit = _.startsWith(currentStateName, "app.visit");

            this.weekDays = AGENDA_PARAMS['week-days'];
            this.field = {key: _.uniqueId('planing-dialog-comment'), label: $translate.instant('comment')};
            this.inDetail = _.startsWith(currentStateName, 'app.visit.planning') || _.startsWith(currentStateName, 'app.planning');
        }

        static get $inject() {
            return ["$mdDialog", "patientService", "$q", "system", "frontDeskService", "$state", "$translate"];
        }

        $onInit() {
            this.copy = this.copy || false;
            this.comment = this.comment || "";
            this.patient_id = this.patient_id || null;
            this.sessions_nbr = this.sessions_nbr || 1;
            this.currentPlanningId = this.currentPlanningId || null;

            let promises = [];

            if (!_.isNil(this.patient_id)) promises.push(this.patientService.getMinimalPatient(this.patient_id));
            else promises.push({});

            if (!_.isNil(this.currentPlanningId)) promises.push(this.frontDeskService.getPlanning(this.currentPlanningId));
            else promises.push({
                seance_nbr: this.sessions_nbr || 1,
                date: moment().format(this.dateFormat),
                frequency: {frequency_type: 'WEEKLY', repeat_frequency: 1},
                start_time: moment().format(this.timeFormat), comment: this.comment || "",
            });

            this.promise = this.q.all(promises)
                .then(data => {
                    this.planning = _.chain(this.planning)
                        .assign({patient: data[0]}, data[1], this.copy ? {date: moment().format(this.dateFormat)} : {})
                        .omit(this.copy ? 'id' : null)
                        .value();

                    if (_.isNil(this.planning['end_time'])) this.changeInterval();
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        handlePlanning(toDetail, event) {
            this.promise = this.frontDeskService.handlePlanning(this.planning, event)
                .then(data => {
                    this.dialog.hide(data);

                    if (toDetail) {
                        let params = _.assign({planning: data.id}, this.isVisit ? {} : {patient: _.get(data, 'patient.id')});
                        this.state.go(this.isVisit ? 'app.visit.planning.details' : 'app.planning.details', params);
                    }
                });
        }

        preSave(event) {
            if (this.planning.id) return of({}).toPromise();
            else return from(this.frontDeskService.handlePlanning(this.planning, event))
                .pipe(tap(data => this.planning.id = data.id)).toPromise();
        }

        changeInterval(reset = false) {
            if (reset || (_.isNil(this.planning.end_time) && this.planning.start_time)) {
                let duration = _.get(this.planning, 'reason.appointment_duration', 20);
                let time = moment(this.planning.start_time, this.timeFormat).add(duration, 'minutes');

                this.planning.end_time = time.isValid() ? time.format(this.timeFormat) : undefined;
            }
        }


        patientCallback(patient) {
            this.promise = this.patientService.getMinimalPatient(patient.id)
                .then(data => this.planning = _.assign({}, this.planning, {patient: data}));
        }

        query() {
            const nEqual = {"$ne": true};

            return {
                is_draft: nEqual,
                is_deleted: nEqual,
                is_archived: nEqual,
            };
        }

        removePatient() {
            this.planning.patient = {};
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: PlanningDialogCtrl,
        template: require("../views/planning-dialog.tpl.html"),
    };

})();

