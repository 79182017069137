/**
 * Created by amine on 27/10/2020.
 */
(function () {

    'use strict';

    class MedicalCareContainerCtrl {
        constructor(medicalCareService, patientService, configService, $q, $scope, $state, $transition$, $transitions, $translate) {
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.patientService = patientService;
            this.q = $q;
            this.translate = $translate;
            this.scope = $scope;
            this.state = $state;
            this.transition = $transition$;

            this.promise = null;

            this.type = _.get($transition$.params('to'), "type");
            this.currentPatient = _.get($transition$.params('to'), "pId");

            this.form = {};
            this.link = null;
            this.links = [
                {
                    name: 'plans_list',
                    label: 'medical_care.plans',
                    state: 'list',
                    opts: {type: 'plan'}
                },
                {
                    name: 'plan_form',
                    dynLabel: () => this.form.item && this.form.item.title ? this.form.item.title : this.translate.instant('medical_care.new_plan'),
                    state: 'form',
                    opts: () => ({type: 'plan', id: _.get(this.form, 'item.id'), item: _.get(this.form, 'item')}),
                    condition: () => _.get(this.form, 'type') === 'plan',
                    formTab: true
                },
                {
                    name: 'plan_scheduling',
                    label: 'medical_care.scheduling',
                    state: 'scheduling',
                    opts: () => ({type: 'plan', id: _.get(this.form, 'item.id'), item: _.get(this.form, 'item')}),
                    condition: () => _.get(this.form, 'type') === 'plan' && _.get(this.form, 'item.id'),
                    formTab: true
                },
                {
                    name: 'quotations_list',
                    label: 'medical_care.quotations',
                    state: 'list',
                    opts: {type: 'quotation'}
                },
                {
                    name: 'quotation_form',
                    dynLabel: () => this.form.item && this.form.item.title ? this.form.item.title : this.translate.instant('medical_care.new_quotation'),
                    state: 'form',
                    opts: () => ({type: 'quotation', id: _.get(this.form, 'item.id'), item: _.get(this.form, 'item')}),
                    condition: () => _.get(this.form, 'type') === 'quotation',
                    formTab: true
                },
                {
                    name: 'medical_file',
                    label: 'medical_care.medical_file',
                    state: 'medical-file'
                },
                {
                    name: 'files',
                    label: 'medical_care.files',
                    state: 'files'
                }
            ]
        }

        static get $inject() {
            return ["medicalCareService", "patientService", "configService", "$q", "$scope", "$state", "$transition$", "$transitions", "$translate"];
        }

        set containerPromise(value) {
            this.promise = value;
        }

        $onInit() {
            this.selectedState = _.last(this.state.current.name.split('.'));
            if (this.selectedState === "medical-care" || !this.type) this.goto("list", {type: 'plan'});

            this.formSubscription = this.medicalCareService.formSubject.subscribe(form => {
                this.scope.$applyAsync(() => this.form = (form || {}));
            });
            this.linkSubscription = this.medicalCareService.linkSubject.subscribe(link => {
                this.scope.$applyAsync(() => this.link = (link || null));
            });


            this.patientService.getMinimalPatient(this.currentPatient)
                .then(data => {
                    this.patient = data;
                })
        }

        $onDestroy() {
            this.linkSubscription.unsubscribe();
            this.formSubscription.unsubscribe();
        }

        goto(state, opts = {}) {
            if (_.isFunction(opts)) opts = opts(state);

            this.state.go(`app.visit.medical-care.${state}`, opts, {notify: true});
        }
    }

    module.exports = MedicalCareContainerCtrl;

})();
