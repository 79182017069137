(function () {

    'use strict';


    const {Subject, BehaviorSubject} = require("rxjs");
    const {debounceTime} = require('rxjs/operators');

    class PracticeService {
        constructor($q, mnWebSocket) {
            this.q = $q;
            this.ws = mnWebSocket;

            this.updateObservabe = new Subject().pipe(debounceTime(25));
            this.updateObservabe.subscribe(() => this.triggerUpdate());
            this.entriesSubject = new BehaviorSubject({WR: [], CV: []});
        }

        static get $inject() {
            return ["$q", "mnWebSocket"];
        }

        updateEntries() {
            let deferred = this.q.defer();

            this.updateObservabe.next([]);
            deferred.resolve(true);

            return deferred.promise;
        }

        triggerUpdate() {
            this.ws.call("frontdesk.Practice.entries")
                .then(data => this.entriesSubject.next(data));
        }

        entryAdded(entry) {
            let items = this.entriesSubject.getValue();
            let container = this.getCorrectStats(entry.stat);

            if (_.isNil(container)) return false;

            this.removeItem(items, entry.id);

            items[container].push(entry);
            this.entriesSubject.next(items);
        }

        entryUpdated(entry) {
            let items = this.entriesSubject.getValue();
            let container = this.getCorrectStats(entry.stat);

            if (this.removeItem(items, entry.id) && _.has(items, container)) {
                items[container].push(entry);
            }

            this.entriesSubject.next(items);
        }

        entryCanceled(entry) {
            let items = this.entriesSubject.getValue();
            this.removeItem(items, entry.id);
            this.entriesSubject.next(items);
        }

        removeItem(data, id) {
            let existence = this.checkExistence(data, id);

            _.remove(data["WR"], {id});
            _.remove(data["CV"], {id});

            return existence;
        }

        getCorrectStats(container) {
            if (['WR', 'EX'].includes(container)) return container;
            else if (['SV', 'EV'].includes(container)) return 'CV';
            else return null;
        }

        checkExistence(data, item) {
            let index = _.findIndex(data["WR"], {id: item});

            if (index !== -1) return true;
            else return _.findIndex(data["CV"], {id: item}) !== -1;
        }
    }

    module.exports = PracticeService;

})();
