/**
 * Created by BETALOS on 20/01/2017.
 */
(function () {

    'use strict';

    const AGENDA_PARAMS = require('parameters/json/agenda-params.json');
    const {UPDATE, BACKGROUND} = require('stand-alone/calendar/utils/consts');
    const COLOR_PICKER_PARAMS = require('parameters/json/color-picker-config.json');

    class BackgroundEventDialogCtrl {
        constructor($mdDialog, frontDeskService, physicianService, system, mnWebSocket, moment, configService, $translate) {
            this.dialog = $mdDialog;
            this.moment = moment;
            this.mnWebSocket = mnWebSocket;
            this.frontDeskService = frontDeskService;

            this.timeFormat = system['time_format'].js;
            this.dateFormat = system['date_format'].js;
            this.dateTimeFormat = system['date_format'].naive;

            this.physicians = physicianService.physicianSubject.getValue();
            this.defaultPhysician = _.get(configService.defaultValues, "physician", null);

            this.isPractice = true;
            this.weekDays = AGENDA_PARAMS['week-days'];
            this.options = _.assign({}, COLOR_PICKER_PARAMS, {label: $translate.instant('reason_dialog_color')});
        }

        static get $inject() {
            return [
                "$mdDialog", "frontDeskService", "physicianService", "system", "mnWebSocket", "moment",
                "configService", "$translate"
            ];
        }

        $onInit() {
            if (_.isEmpty(this.event)) this.event = {
                unavailable: true, is_all_day: true, is_all_week: true, physicians: this.physicians
            };

            else this.promise = this.frontDeskService.getBackgroundEvent(this.event['id'])
                .then(data => {
                    data._dow = _.invokeMap(data._dow, 'toString');
                    this.event = data;

                    this.isPractice = this.event.physicians.length === this.physicians.length;
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            let event = _.assign(this.event, this.event.is_permanent ? this.handlePermanent() : this.handleNonPermanent());

            if (!this.checkDuration()) {
                event.is_all_week = false;
                event._dow = [];
            }

            this.promise = this.frontDeskService.handleEvent(event)
                .then(data => {
                    this.mnWebSocket.pub("frontdesk.Calendar.notify", {
                        cmd: UPDATE,
                        type: BACKGROUND,
                        event: data.id
                    }, false);

                    this.dialog.hide(data);
                });
        }

        handleNonPermanent() {
            let startDate = this.moment(this.event['start_date'], this.dateFormat);
            let endDate = this.moment(this.event['end_date'], this.dateFormat);
            let startTime = this.moment(this.event['start_time'], this.timeFormat);
            let endTime = this.moment(this.event['end_time'], this.timeFormat);

            if (this.event.is_all_day || !startTime.isValid() || !endTime.isValid()) {
                startTime = moment().set({hour: 0, minute: 0});
                endTime = moment().set({hour: 23, minute: 59});
            }

            let start = startDate
                .set({hours: startTime.get('hour'), minutes: startTime.get('minute')})
                .format(this.dateTimeFormat);

            let end = endDate
                .set({hours: endTime.get('hour'), minutes: endTime.get('minute')})
                .format(this.dateTimeFormat);

            return {
                start: start,
                end: end
            }
        }

        handlePermanent() {
            return {
                start: moment(this.event.is_all_day ? "00:00" : this.event['start_time'], this.timeFormat).format(this.dateTimeFormat),
                end: moment(this.event.is_all_day ? "23:59" : this.event['end_time'], this.timeFormat).format(this.dateTimeFormat)
            }
        }


        handleStartDateChange() {
            if (_.isNil(this.event.end_date)) this.event.end_date = this.event.start_date;
            this.updateDateConstraint();
        }

        handleEndDateChange() {
            if (_.isNil(this.event.start_date)) this.event.start_date = this.event.end_date;
            this.updateDateConstraint();
        }

        updateDateConstraint() {
            const mMinDate = this.moment(this.event['start_date'], this.dateFormat);
            const mMaxDate = this.moment(this.event['end_date'], this.dateFormat);

            this.minDate = mMinDate.toDate();
            this.maxDate = mMaxDate.toDate();

            if (mMinDate.isAfter(mMaxDate)) {
                this.event.end_date = this.event.start_date;
                this.updateDateConstraint();
            }
        }

        checkDuration() {
            if (this.event.is_permanent) return true;
            if (_.isNil(this.event.start_date) && _.isNil(this.event.end_date)) return false;

            let startDate = this.moment(this.event['start_date'], this.dateFormat);
            let endDate = this.moment(this.event['end_date'], this.dateFormat);
            let diff = endDate.diff(startDate, 'days');

            return diff > 0;
        }

        handlePhysicians() {
            this.event.physicians = this.isPractice ? this.physicians : _.compact([this.defaultPhysician]);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: BackgroundEventDialogCtrl,
        template: require("../views/backgroun-event-dialog.tpl.html"),
    };

})();
