(function () {
    'use strict';

    const {BehaviorSubject, take} = require("rxjs");
    const {statesHeaders} = require("../../utils/states-headers");

    const PROCEDURES_ACTIVITY_TYPES = [
        {
            value: 0,
            label: "states_procedure_activity_prices"
        },
        {
            value: 1,
            label: "states_procedure_activity_count"
        },
        {
            value: 2,
            label: "states_procedure_activity_both"
        }
    ];

    class ActivitiesCtrl {
        constructor(configService, authService, $state, $stateParams, genericStatesService, system, $translate, $scope, moment) {
            this.configService = configService;
            this.isRoot = authService.isRootProfile();
            this.$state = $state;
            this.genericStatesService = genericStatesService;
            this.system = system;
            this.translate = $translate;
            this.scope = $scope;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;

            this.cycle = $stateParams["cycle"];
            this.hasChart = false;
            this.activityStates = genericStatesService.getSubcategory(`activities`);
            this.activity = _.get(this.activityStates, '0');
            this.procedureActivityTypes = PROCEDURES_ACTIVITY_TYPES;
            this.procedureActivityType = 0;
            this.maxDate = null;

            this.currentState = genericStatesService.getCurrentState(`${this.cycle}_activity`);

            const defaultStart = this.cycle === "monthly" ? this.moment().startOf('month') : this.moment();
            const defaultEnd = this.cycle === "monthly" ? this.moment().endOf('month') : this.moment();


            this.query = {
                start: $stateParams.start || defaultStart.format(this.dateFormat),
                end: $stateParams.end || defaultEnd.format(this.dateFormat),
                year: this.cycle === 'annual' ? (parseInt($stateParams.year) || this.moment().year()) : undefined,
                use_discount: true
            };

            this.headers = statesHeaders($translate, ['payment', 'procedure', 'encasement']);

            this.monthFormat = "MMM YYYY";
            this.monthFormater = this.genericStatesService.buildLocaleProvider("MMM-YYYY");
            this.isChart = false;
            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {}, is_exempt: {
                    type: "string",
                    caption: this.translate.instant('exempt_visit_label')
                },
                onlyData: false
            });
            this.summary = {
                total: 0,
                encasement_total: 0,
                loaded: false
            }
        }

        static get $inject() {
            return ["configService", "authService", "$state", "$stateParams", "genericStatesService", "system", "$translate", "$scope", "moment"];
        }

        $onInit() {
            if (this.cycle === "annual") this.maxDate = this.moment().year();
            else this.maxDate = this.moment().toDate();

            this.genericStatesService.load()
                .then(() => {
                    const config = this.genericStatesService.$config.getValue();
                    if (!_.isNil(config)) {
                        if (this.isRoot) {
                            this.activityStates = this.activityStates.map(item => {
                                item.deactivated = _.get(config, item.name, false);
                                return item;
                            });
                        } else {
                            this.activityStates = _.reject(this.activityStates, item => _.get(config, item.name, false));
                            this.activity = _.get(this.activityStates, '0');
                        }

                        this.getData();
                    }
                })
        }

        handleActivityBase() {
            this.scope.$applyAsync(() => {
                this.procedureActivityType = 0;
                this.query.use_discount = false;

                this.getData();
            });
        }

        getSlice() {
            return {
                expands: {
                    expandAll: true
                },
                drills: {
                    drillAll: true
                },
                rows: this.getRows(),
                columns: this.getColumns(),
                measures: this.getMeasures(),
            };
        }

        getDateLabel() {
            switch (this.cycle) {
                case 'periodic':
                    return 'from_date';
                case 'monthly':
                    return 'states_year_months';
                default:
                    return 'date'
            }
        }

        getData(onlyData = false) {
            return this.promise = this.genericStatesService.getActivities(_.assign({}, this.query, {
                cycle: this.cycle,
                data_source: this.activity.name
            })).then(data => {
                if (onlyData) {
                    this.$dataSource.next({
                        onlyData: true,
                        data: this.genericStatesService.activitiesDataModification(this.activity.type, data)
                    });
                } else {
                    this.$dataSource.next({
                        slice: this.getSlice(),
                        data: this.genericStatesService.activitiesDataModification(this.activity.type, data),
                        headers: this.headers[this.activity.type]
                    });
                }
                const activity = _.includes([
                    "procedure_activity",
                    "dental_procedure_activity",
                    "medical_care_procedure_activity"
                ], this.activity.name) ? this.activity.name : _.get(this.activityStates, '0.name', "procedure_activity");

                this.genericStatesService.getActivities(_.assign({}, this.query, {
                    cycle: this.cycle,
                    data_source: "activity_summary",
                    active_data_source: activity
                })).then(data => {
                    this.summary = {
                        loaded: true,
                        total: data.total,
                        encasement_total: data.encasement_total
                    };
                });
            });
        }

        queryChanged() {
            if (this.cycle === "monthly") {
                const start = moment(this.query.start, this.dateFormat);
                this.query.end = start.endOf('month').format(this.dateFormat);
            }

            this.$state.transitionTo(this.$state.current.name, this.query, {
                notify: false
            }).then(() => this.getData(true));
        }

        handleFileName() {
            if (this.activity) {
                let title = `${this.translate.instant(this.currentState.label)} [${this.translate.instant(this.activity.label)}] `;

                const start = moment(this.query.start, this.dateFormat);
                const end = moment(this.query.end, this.dateFormat);

                switch (this.cycle) {
                    case 'annual':
                        title += this.query.year;
                        break;
                    case 'periodic':
                        title += `${start.format(this.genericStatesService.exportDateFormat)} - ${end.format(this.genericStatesService.exportDateFormat)}`;
                        break;
                    case 'monthly':
                        title += start.format(this.monthFormat);
                        break;
                    default:
                        title += start.format(this.genericStatesService.exportDateFormat);
                        break;
                }

                return title;
            }
            return null
        }

        getRows(skip = false) {
            if (this.cycle !== "annual" || skip) {
                switch (this.activity.type) {
                    case "procedure":
                        return [
                            {"uniqueName": "physician_fullname"},
                            {"uniqueName": "patient_fullname"}
                        ];
                    case "payment":
                        return [
                            {"uniqueName": "physician_fullname"},
                            {"uniqueName": "payment_mode"},
                            {"uniqueName": "patient_fullname"},
                            {"uniqueName": "payment_label"},
                            {"uniqueName": "payment_id"}
                        ];

                    case "encasement":
                        return [
                            {"uniqueName": "physician_fullname"},
                            {"uniqueName": "payment_mode"},
                            {"uniqueName": "payee_fullname"},
                            {"uniqueName": "label"}
                        ];
                }
            } else {
                const normalRows = this.getRows(true);
                let dateField = "";
                switch (this.activity.type) {
                    case "procedure":
                        dateField = "visit_date";
                        break;
                    case "payment":
                        dateField = "payment_date";
                        break;
                    case "encasement":
                        dateField = "encasement_date";
                        break;
                }

                normalRows.unshift({"uniqueName": `${dateField}.Month`});
                return normalRows;
            }
        }

        getColumns() {
            switch (this.activity.type) {
                case "procedure":
                    return [
                        {
                            "uniqueName": "procedure_name",
                            "caption": this.translate.instant("states_procedure_name")
                        },
                        {
                            "uniqueName": "Measures"
                        }
                    ];
                case "payment":
                case "encasement":
                    return [
                        {
                            "uniqueName": "[Measures]"
                        }
                    ];
            }

        }

        getMeasures() {
            switch (this.activity.type) {
                case "procedure":
                    const quantityCell = {
                        "uniqueName": "procedure_quantity",
                        "aggregation": "sum",
                        "format": "integer",
                        "grandTotalCaption": this.translate.instant("qte")
                    };
                    const priceCell = {
                        "uniqueName": "procedure_total_price",
                        "caption": this.translate.instant("total_price"),
                        "format": "currency",
                        "aggregation": "sum"
                    };
                    switch (this.procedureActivityType) {
                        case 1:
                            return [quantityCell];
                        case 2:
                            return [quantityCell, priceCell];
                        default:
                            return [priceCell]
                    }
                case "payment":
                    return [
                        {
                            "uniqueName": "total_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        },
                        {
                            "uniqueName": "global_discount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        },
                        {
                            "uniqueName": "total_paid_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        },
                        {
                            "uniqueName": "remaining_amount",
                            "individual": true,
                            "format": "currency",
                            "aggregation": "sum"
                        },
                        {
                            "uniqueName": "exempt_amount",
                            "individual": true,
                            "format": "currency",
                            "aggregation": "sum"
                        },
                        {
                            "uniqueName": "amount",
                            "individual": false,
                            "aggregation": "sum",
                        }
                    ];
                case "encasement":
                    return [
                        {
                            "uniqueName": "total_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        },
                        {
                            "uniqueName": "consumed_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        },
                        {
                            "uniqueName": "remaining_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        }
                    ];
            }
        }

        customizeCells(cellBuilder, cellData) {
            if (cellData.type !== "header") {
                const column = _.get(cellData, "measure.uniqueName", "");
                if (cellData.isGrandTotalColumn) {
                    if (this.activity.type === "payment") {
                        if (_.includes([
                            "total_amount",
                            "total_paid_amount",
                            "remaining_amount",
                            "global_discount",
                            "exempt_amount"
                        ], column)) {
                            _.remove(cellBuilder.classes, item => item === "grand-total-column");
                            cellBuilder.addClass("wdr-details")
                        }
                    }

                    if (this.activity.type === "encasement") {
                        const column = _.get(cellData, "hierarchy.uniqueName", "");
                        const detailsColumns = [
                            "consumed_amount",
                            "remaining_amount"
                        ];

                        if (_.includes(detailsColumns, column)) {
                            _.remove(cellBuilder.classes, item => item === "grand-total-column");
                            cellBuilder.addClass("wdr-details")
                        }
                    }
                }

                if (this.activity.type === "payment" && column === "exempt_amount") {
                    if (cellData.isGrandTotalRow) cellBuilder.addClass("wdr-exempt-total");
                    else cellBuilder.addClass("wdr-exempt-detail");

                }
            }
        }

        onComplete(instance) {
            if (this.activity.type === 'payment') instance.collapseData("patient_fullname");
            if (this.activity.type === 'encasement') instance.collapseData("payee_fullname");

            if (this.cycle === "annual") instance.collapseData("physician_fullname")
        }
    }

    module.exports = ActivitiesCtrl;
})();
