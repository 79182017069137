/**
 * Created by Hp on 21/09/2017.
 */
(function () {
    'use strict';

    module.exports = PaymentListCtrl;
    PaymentListCtrl.$inject = ["$state", "$mdDialog", "$translate", "generalPaymentService", "$mdToast", "printService", "$transition$"];

    function PaymentListCtrl($state, $mdDialog, $translate, generalPaymentService, $mdToast, printService, $transition$) {
        var vm = this;
        vm.$onInit = init;
        vm.addState = $transition$.params('to')["addState"];
        vm.listState = $transition$.params('to')["listState"];
        vm.mnModel= $transition$.params('to')["mnModel"];
        vm.tableModel= $transition$.params('to')["tableModel"];
        vm.viewName= $transition$.params('to')["viewName"];
        var resource = $transition$.params('to')['resource'];


        function init() {
            vm.reset = null;
            vm.filter = {is_deleted: {$ne:true}};
            vm.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: resource,
                        action: 'update',
                        behavior: 'disable',
                        method: goToEdition
                    },
                    {
                        icon: 'mdi-printer',
                        label: 'print',
                        resource:resource,
                        action: 'print',
                        behavior: 'disable',
                        method: printObject
                    }
                ],
                multiple: [{
                    icon: 'mdi-delete',
                    label: 'delete',
                    resource: resource,
                    action: 'delete',
                    behavior: 'disable',
                    method: deleteObject
                }]
            };
        }


        function goToEdition(obj) {
            $state.go(vm.addState, {paymentId: obj.id});
        }

        function deleteObject(objs) {
            _.forEach(objs, function (obj) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('delete_warning'))
                        .ariaLabel('delete_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    !obj['is_valid'] ? generalPaymentService.deletePayment(obj.id, vm.viewName)
                            .then(_.isFunction(vm.reset) ? vm.reset : _.noop) : showWarning();
                });
            })
        }

        function showWarning() {
            var simpleToast = $mdToast.simple()
                .textContent($translate.instant('impossible_delete'))
                .position("top right")
                .hideDelay(3000);
            $mdToast.show(simpleToast);
        }

        function printObject(obj) {
            printService
                .printItem('billing-payment', obj.id, null, null);
        }
    }
})();